/* SALES TRANSLATION */

export const sales_register_translation = {
  sales_heading: {
    en: "Sales",
    az: "",
    ru: "",
  },
  sales_id: {
    en: "ID",
    az: "",
    ru: "",
  },
  sales_date: {
    en: "Sales Date",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  total_sales: {
    en: "Total Sales",
    az: "",
    ru: "",
  },
  total_orders: {
    en: "Total Orders",
    az: "",
    ru: "",
  },
  avg_ticket_price: {
    en: "Avg. Ticket Price",
    az: "",
    ru: "",
  },
  sales_dashboard: {
    en: "Sales Dashboard",
    az: "",
    ru: "",
  },
  sales_report: {
    en: "Sales Report",
    az: "",
    ru: "",
  },
};

export const sale_register_filter_translation = {
  search_by_sale: {
    en: "Search By Sales",
    az: "",
    ru: "",
  },
  sales_date: {
    en: "Sales Date",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  total_sales: {
    en: "Total Sales",
    az: "",
    ru: "",
  },
  total_orders: {
    en: "Total Orders",
    az: "",
    ru: "",
  },
  avg_ticket_price: {
    en: "Avg. Ticket Price",
    az: "",
    ru: "",
  },
  sales_date_start: {
    en: "Start Date",
    az: "",
    ru: "",
  },
  sales_date_end: {
    en: "End Date",
    az: "",
    ru: "",
  },
  store_name_search: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  total_sales_from: {
    en: "Total Sales From",
    az: "",
    ru: "",
  },
  total_sales_to: {
    en: "Total Sales To",
    az: "",
    ru: "",
  },
  total_orders_from: {
    en: "Total Orders From",
    az: "",
    ru: "",
  },
  total_orders_to: {
    en: "Total Orders To",
    az: "",
    ru: "",
  },
  avg_ticket_price_from: {
    en: "Avg. Ticket Price From",
    az: "",
    ru: "",
  },
  avg_ticket_price_to: {
    en: "Avg. Ticket Price To",
    az: "",
    ru: "",
  },
};

export const sales_register_error_translation = {
  search_criteria_for_sale: {
    en: "Please select search criteria for filtered result.",
    az: "",
    ru: "",
  },
  store_name_required: {
    en: "Please enter store name.",
    az: "",
    ru: "",
  },
  sales_start_date_greater_required: {
    en: "Start date cannot be greater than end date.",
    az: "",
    ru: "",
  },
  sales_from_operater_required: {
    en: "Please select total sales from operator.",
    az: "",
    ru: "",
  },
  sale_from_required: {
    en: "Please enter total sales from.",
    az: "",
    ru: "",
  },
  sales_to_operater_required: {
    en: "Please select total sales to operator.",
    az: "",
    ru: "",
  },
  sale_to_required: {
    en: "Please enter total sales to.",
    az: "",
    ru: "",
  },
  orders_from_operater_required: {
    en: "Please select total orders from operator.",
    az: "",
    ru: "",
  },
  order_from_required: {
    en: "Please enter total orders from.",
    az: "",
    ru: "",
  },
  orders_to_operater_required: {
    en: "Please select total orders to operator.",
    az: "",
    ru: "",
  },
  order_to_required: {
    en: "Please enter total orders to.",
    az: "",
    ru: "",
  },
  avg_ticket_from_operator_required: {
    en: "Please select avg. ticket price from operator.",
    az: "",
    ru: "",
  },
  avg_ticket_from_required: {
    en: "Please enter avg. ticket price from.",
    az: "",
    ru: "",
  },
  avg_ticket_to_operator_required: {
    en: "Please select avg. ticket price to operator.",
    az: "",
    ru: "",
  },
  avg_ticket_to_required: {
    en: "Please enter avg. ticket price to.",
    az: "",
    ru: "",
  },
};

export const sales_dashboard_translation = {
  sales_dashboard_heading: {
    en: "Sales Dashboard",
    az: "",
    ru: "",
  },
  start_date: {
    en: "Start Date",
    az: "",
    ru: "",
  },
  end_date: {
    en: "End Date",
    az: "",
    ru: "",
  },
  time_duration: {
    en: "Duration",
    az: "",
    ru: "",
  },
  time_duration_placeholder: {
    en: "Please select time duration.",
    az: "",
    ru: "",
  },
  week_number: {
    en: "Week Number",
    az: "",
    ru: "",
  },
  olo: {
    en: "OLO",
    az: "",
    ru: "",
  },
  total_orders: {
    en: "Total Orders",
    az: "",
    ru: "",
  },
  orders: {
    en: "Orders",
    az: "",
    ru: "",
  },
  olo_pulse_callcenter_and_wolt_orders: {
    en: "OLO, Pulse, Callcenter & Wolt Orders",
    az: "",
    ru: "",
  },
  total_sales: {
    en: "Total Sales",
    az: "",
    ru: "",
  },
  sales: {
    en: "Sales",
    az: "",
    ru: "",
  },
  olo_pulse_callcenter_and_wolt_sales: {
    en: "OLO, Pulse, Callcenter & Wolt Sales",
    az: "",
    ru: "",
  },
  total_atp: {
    en: "Total ATP",
    az: "",
    ru: "",
  },
  atp: {
    en: "ATP",
    az: "",
    ru: "",
  },
  olo_pulse_callcenter_and_wolt_atp: {
    en: "OLO, Pulse, Callcenter & Wolt ATP",
    az: "",
    ru: "",
  },
};

export const sale_details_translation = {
  sales_detail_heading: {
    en: "Sale Detail",
    az: "",
    ru: "",
  },
  sales_summary: {
    en: "Sales Summary",
    az: "",
    ru: "",
  },
  sales_date: {
    en: "Sales Date",
    az: "",
    ru: "",
  },
  store_code: {
    en: "Store Code",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  total_sales: {
    en: "Total Sales",
    az: "",
    ru: "",
  },
  void_orders: {
    en: "Void Orders",
    az: "",
    ru: "",
  },
  bad_orders: {
    en: "Bad Orders",
    az: "",
    ru: "",
  },
  royalty_sales: {
    en: "Royalty Sales",
    az: "",
    ru: "",
  },
  sales_tax: {
    en: "Sales Tax",
    az: "",
    ru: "",
  },
  total_orders: {
    en: "Total Orders",
    az: "",
    ru: "",
  },
  avg_ticket_price: {
    en: "Avg. Ticket",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  last_updated_at: {
    en: "Last Updated At",
    az: "",
    ru: "",
  },
  cost_summary: {
    en: "Cost Summary",
    az: "",
    ru: "",
  },
  actual_food_percentage: {
    en: "Actual Food %",
    az: "",
    ru: "",
  },
  ideal_food_percentage: {
    en: "Ideal Food %",
    az: "",
    ru: "",
  },
  actual_and_ideal_food_variance: {
    en: "Actual & Ideal Food Variance %",
    az: "",
    ru: "",
  },
  actual_food_cost: {
    en: "Actual Food Cost",
    az: "",
    ru: "",
  },
  ideal_food_cost: {
    en: "Ideal Food Cost",
    az: "",
    ru: "",
  },
  payment_summary: {
    en: "Payment Summary",
    az: "",
    ru: "",
  },
  cash_sales: {
    en: "Cash Sales",
    az: "",
    ru: "",
  },
  pos_terminal: {
    en: "POS Terminal",
    az: "",
    ru: "",
  },
  online_sales: {
    en: "Online Sales",
    az: "",
    ru: "",
  },
  order_summary: {
    en: "Order Summary",
    az: "",
    ru: "",
  },
  olo_orders: {
    en: "OLO Orders",
    az: "",
    ru: "",
  },
  callcenter_orders: {
    en: "Callcenter Orders",
    az: "",
    ru: "",
  },
  pulse_orders: {
    en: "Pulse Orders",
    az: "",
    ru: "",
  },
  total_delivery_orders: {
    en: "Total Delivery Orders",
    az: "",
    ru: "",
  },
  total_carryout_orders: {
    en: "Total Carryout Orders",
    az: "",
    ru: "",
  },
  total_dine_in_orders: {
    en: "Total Dine-in Orders",
    az: "",
    ru: "",
  },
  sales_type: {
    en: "Sales Type",
    az: "",
    ru: "",
  },
  olo_sales: {
    en: "OLO Sales",
    az: "",
    ru: "",
  },
  callcenter_sales: {
    en: "Callcenter Sales",
    az: "",
    ru: "",
  },
  pulse_sales: {
    en: "Pulse Sales",
    az: "",
    ru: "",
  },
  total_delivery_sales: {
    en: "Total Delivery Sales",
    az: "",
    ru: "",
  },
  total_carryout_sales: {
    en: "Total Carryout Sales",
    az: "",
    ru: "",
  },
  total_dine_in_sales: {
    en: "Total Dine-in Sales",
    az: "",
    ru: "",
  },
  channel_summary: {
    en: "Channel Summary",
    az: "",
    ru: "",
  },
  completed_orders: {
    en: "Completed Orders",
    az: "",
    ru: "",
  },
  completed_sales: {
    en: "Completed Sales",
    az: "",
    ru: "",
  },
  void_amount: {
    en: "Void Amount",
    az: "",
    ru: "",
  },
};

/* INVENTORY TRANSLATION */

export const inventory_register_translation = {
  inventory_heading: {
    en: "Inventory",
    az: "",
    ru: "",
  },
  inventory_date: {
    en: "Inventory Date",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  actual_food_cost: {
    en: "Actual Food Cost",
    az: "",
    ru: "",
  },
  acutal_food_percentage: {
    en: "Actual Food %",
    az: "",
    ru: "",
  },
  ideal_food_cost: {
    en: "Ideal Food Cost",
    az: "",
    ru: "",
  },
  ideal_food_percentage: {
    en: "Ideal Food %",
    az: "",
    ru: "",
  },
  actual_and_ideal_food_variance: {
    en: "Actual & Ideal Food Variance %",
    az: "",
    ru: "",
  },
  percentage_of_inventory: {
    en: "% of Inventory",
    az: "",
    ru: "",
  },
  inventory_report: {
    en: "Inventory Report",
    az: "",
    ru: "",
  },
  inventory_dashboard: {
    en: "Inventory Dashboard",
    az: "",
    ru: "",
  },
};

export const ineventory_register_error_translation = {
  search_criteria_for_inventory: {
    en: "Please select search criteria for filtered result.",
    az: "",
    ru: "",
  },
  inventory_start_date_greater_required: {
    en: "Start date cannot be greater than end date.",
    az: "",
    ru: "",
  },
  store_name_required: {
    en: "Please enter store name.",
    az: "",
    ru: "",
  },
  actual_food_cost_from_sign_required: {
    en: "Please select actual food cost from operator.",
    az: "",
    ru: "",
  },
  actual_food_cost_from_required: {
    en: "Please enter actual food cost from.",
    az: "",
    ru: "",
  },
  actual_food_cost_to_operator_required: {
    en: "Please select actual food cost to operator.",
    az: "",
    ru: "",
  },
  actual_food_cost_to_required: {
    en: "Please enter actual food cost to.",
    az: "",
    ru: "",
  },
  actual_food_from_operator_required: {
    en: "Please select actual food from operator.",
    az: "",
    ru: "",
  },
  actual_food_from_required: {
    en: "Please enter actual food from.",
    az: "",
    ru: "",
  },
  actual_food_to_operator_required: {
    en: "Please select actual food to operator.",
    az: "",
    ru: "",
  },
  actual_food_to_required: {
    en: "Please enter actual food to.",
    az: "",
    ru: "",
  },
  actual_ideal_food_var_from_sign_required: {
    en: "Please select actual & ideal food variance from operator.",
    az: "",
    ru: "",
  },
  actual_ideal_food_var_from_required: {
    en: "Please enter actual & ideal food variance from.",
    az: "",
    ru: "",
  },
  actual_ideal_food_var_to_sign_required: {
    en: "Please select actual & ideal food variance to operator.",
    az: "",
    ru: "",
  },
  actual_ideal_food_var_to_required: {
    en: "Pleas enter actual & ideal food variance to.",
    az: "",
    ru: "",
  },
  ideal_food_from_operator_required: {
    en: "Please select ideal food from operator.",
    az: "",
    ru: "",
  },
  ideal_food_from_required: {
    en: "Please enter ideal food from.",
    az: "",
    ru: "",
  },
  ideal_food_to_operator_required: {
    en: "Please select ideal food to operator.",
    az: "",
    ru: "",
  },
  ideal_food_to_required: {
    en: "Please enter ideal food to.",
    az: "",
    ru: "",
  },
  ideal_food_cost_from_operator_required: {
    en: "Please select ideal food cost from operator.",
    az: "",
    ru: "",
  },
  ideal_food_cost_from_required: {
    en: "Please enter ideal food cost from.",
    az: "",
    ru: "",
  },
  ideal_food_cost_to_operator_required: {
    en: "Please select ideal food cost to operator.",
    az: "",
    ru: "",
  },
  ideal_food_cost_to_required: {
    en: "Please enter ideal food cost to.",
    az: "",
    ru: "",
  },
};

export const inventory_register_filter_transaltion = {
  search_by_inventory: {
    en: "Search By Inventory",
    az: "",
    ru: "",
  },
  inventory_date: {
    en: "Inventory Date",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  actual_food_cost: {
    en: "Actual Food Cost",
    az: "",
    ru: "",
  },
  actual_food_percent: {
    en: "Actual Food %",
    az: "",
    ru: "",
  },
  actual_ideal_food_variance_percent: {
    en: "Actual & Ideal Food Variance %",
    az: "",
    ru: "",
  },
  ideal_food_percent: {
    en: "Ideal Food %",
    az: "",
    ru: "",
  },
  ideal_food_cost: {
    en: "Ideal Food Cost",
    az: "",
    ru: "",
  },
  store_name_search: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  inventory_date_start: {
    en: "Start Date",
    az: "",
    ru: "",
  },
  inventory_date_end: {
    en: "End Date",
    az: "",
    ru: "",
  },
  actual_food_cost_from: {
    en: "Actual Food Cost From",
    az: "",
    ru: "",
  },
  actual_food_cost_to: {
    en: "Actual Food Cost To",
    az: "",
    ru: "",
  },
  actual_food_from: {
    en: "Actual Food From",
    az: "",
    ru: "",
  },
  actual_food_to: {
    en: "Actual Food To",
    az: "",
    ru: "",
  },
  actual_ideal_food_variance_from: {
    en: "Actual & Ideal Food Var. From",
    az: "",
    ru: "",
  },
  actual_ideal_food_variance_to: {
    en: "Actual & Ideal Food Var. To",
    az: "",
    ru: "",
  },
  ideal_food_From: {
    en: "Ideal Food From",
    az: "",
    ru: "",
  },
  ideal_food_to: {
    en: "Ideal Food To",
    az: "",
    ru: "",
  },
  ideal_food_cost_from: {
    en: "Ideal Food Cost From",
    az: "",
    ru: "",
  },
  ideal_food_cost_to: {
    en: "Ideal Food Cost To",
    az: "",
    ru: "",
  },
};

export const inventory_details_translation = {
  inventory_detail_heading: {
    en: "Inventory Detail",
    az: "",
    ru: "",
  },
  inventory_date: {
    en: "Inventory Date",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  actual_food_percentage: {
    en: "Actual Food %",
    az: "",
    ru: "",
  },
  ideal_food_percentage: {
    en: "Ideal Food %",
    az: "",
    ru: "",
  },
  actual_and_ideal_food_variance_percentage: {
    en: "Actual & Ideal Food Variance %",
    az: "",
    ru: "",
  },
  actual_food_cost: {
    en: "Actual Food Cost",
    az: "",
    ru: "",
  },
  ideal_food_cost: {
    en: "Ideal Food Cost",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  last_updated_at: {
    en: "Last Updated At",
    az: "",
    ru: "",
  },
  store_code: {
    en: "Store Code",
    az: "",
    ru: "",
  },
  actual_and_ideal_food_cost_variance: {
    en: "Actual & Ideal Food Cost Variance",
    az: "",
    ru: "",
  },
};

export const inventory_report_translation = {
  item_name: {
    en: "Item Name",
    az: "",
    ru: "",
  },
  pusle_category: {
    en: "Pulse Category",
    az: "",
    ru: "",
  },
  unit_of_measurement: {
    en: "Unit of Measurement",
    az: "",
    ru: "",
  },
  unit_of_amount: {
    en: "Unit of Amount",
    az: "",
    ru: "",
  },
  opening_balance: {
    en: "Opening Balance",
    az: "",
    ru: "",
  },
  sell_buy: {
    en: "Sell / Buy",
    az: "",
    ru: "",
  },
  total_available_balance: {
    en: "Total Available Balance",
    az: "",
    ru: "",
  },
  closing_balance: {
    en: "Closing Balance",
    az: "",
    ru: "",
  },
  actual_usage: {
    en: "Actual Usage",
    az: "",
    ru: "",
  },
  ideal_usage: {
    en: "Ideal Usage",
    az: "",
    ru: "",
  },
  actual_and_ideal_usage_variance: {
    en: "Actual & Ideal Usage Variance",
    az: "",
    ru: "",
  },
  actual_usage_amount: {
    en: "Actual Usage Amount",
    az: "",
    ru: "",
  },
  actual_and_ideal_variance_amount: {
    en: "Actual & Ideal Variance Amount",
    az: "",
    ru: "",
  },
  usage_difference: {
    en: "Usage Difference",
    az: "",
    ru: "",
  },
};

export const item_group_register_translation = {
  item_group_heading: {
    en: "Item Groups",
    az: "",
    ru: "",
  },
  item_group_name: {
    en: "Item Group Name",
    az: "",
    ru: "",
  },
  item_group_parent_name: {
    en: "Item Group Parent",
    az: "",
    ru: "",
  },
  is_parent_item_group: {
    en: "Is Parent",
    az: "",
    ru: "",
  },
  item_group_description: {
    en: "Description",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  create_item_group: {
    en: "Create Item Group",
    az: "",
    ru: "",
  },
};

export const item_group_details_translation = {
  create_item_group_details_heading: {
    en: "Create Item Group",
    az: "",
    ru: "",
  },
  item_group_details_heading: {
    en: "Item Group Details",
    az: "",
    ru: "",
  },
  item_group_name_en: {
    en: "Item Group Name in En",
    az: "",
    ru: "",
  },
  item_group_name_az: {
    en: "Item Group Name in Az",
    az: "",
    ru: "",
  },
  item_group_name_ru: {
    en: "Item Group Name in Ru",
    az: "",
    ru: "",
  },
  item_group_description_en: {
    en: "Item Group Description in En",
    az: "",
    ru: "",
  },
  item_group_deacription_az: {
    en: "Item Group Description in Az",
    az: "",
    ru: "",
  },
  item_group_description_ru: {
    en: "Item Group Description in Ru",
    az: "",
    ru: "",
  },
  item_group_parent: {
    en: "Item Group Parent",
    az: "",
    ru: "",
  },
  is_item_group: {
    en: "Is Group",
    az: "",
    ru: "",
  },
  create_item_group: {
    en: "Create Item Group",
    az: "",
    ru: "",
  },
  update_item_group: {
    en: "Update Item Group",
    az: "",
    ru: "",
  },
  item_group_name_en_placeholder: {
    en: "Please enter item group name in EN.",
    az: "",
    ru: "",
  },
  item_group_name_az_placeholder: {
    en: "Please enter item group name in AZ.",
    az: "",
    ru: "",
  },
  item_group_name_ru_placeholder: {
    en: "Please enter item group name in RU.",
    az: "",
    ru: "",
  },
  item_group_description_en_placeholder: {
    en: "Please enter item group description in EN.",
    az: "",
    ru: "",
  },
  item_group_description_az_placeholder: {
    en: "Please enter item group description in AZ.",
    az: "",
    ru: "",
  },
  item_group_description_ru_placeholder: {
    en: "Please enter item group description in RU.",
    az: "",
    ru: "",
  },
  item_group_parent_placeholder: {
    en: "Please select item group parent.",
    az: "",
    ru: "",
  },
  item_group_created_success: {
    en: "Item group created succesfully.",
    az: "",
    ru: "",
  },
  item_group_updated_success: {
    en: "Item group updated succesfully.",
    az: "",
    ru: "",
  },
};

export const items_register_translation = {
  item_heading: {
    en: "Items",
    az: "",
    ru: "",
  },
  item_code: {
    en: "Item Code",
    az: "",
    ru: "",
  },
  item_name: {
    en: "Item Name",
    az: "",
    ru: "",
  },
  default_unit: {
    en: "Default Unit",
    az: "",
    ru: "",
  },
  topping_code: {
    en: "Topping Code",
    az: "",
    ru: "",
  },
  pulse_category: {
    en: "Pulse Category",
    az: "",
    ru: "",
  },
  group_level: {
    en: "Group Level",
    az: "",
    ru: "",
  },
  item_description: {
    en: "Item Description",
    az: "",
    ru: "",
  },
  item_group: {
    en: "Item Group",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  create_item: {
    en: "Create Item",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  updated_at: {
    en: "Updated At",
    az: "",
    ru: "",
  },
  updated_by: {
    en: "Updated By",
    az: "",
    ru: "",
  },
  select_search_criteria_for_search: {
    en: "Please select search criteria for filtered result.",
    az: "",
    ru: "",
  },
};

export const items_search_translation = {
  search_by_items: {
    en: "Search By Items",
    az: "",
    ru: "",
  },
  item_code: {
    en: "Item Code",
    az: "",
    ru: "",
  },
  item_name: {
    en: "Item Name",
    az: "",
    ru: "",
  },
  default_unit: {
    en: "Default Unit",
    az: "",
    ru: "",
  },
  pulse_category: {
    en: "Pulse Category",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  item_group: {
    en: "Item Group",
    az: "",
    ru: "",
  },
  item_group_level: {
    en: "Item Approval Level",
    az: "",
    ru: "",
  },
  topping_code: {
    en: "Topping Code",
    az: "",
    ru: "",
  },
  item_code_search: {
    en: "Item Code",
    az: "",
    ru: "",
  },
  item_name_search: {
    en: "Item Name",
    az: "",
    ru: "",
  },
  default_unit_search: {
    en: "Default Unit",
    az: "",
    ru: "",
  },
  pulse_category_search: {
    en: "Pulse Category",
    az: "",
    ru: "",
  },
  status_search: {
    en: "Status",
    az: "",
    ru: "",
  },
  item_group_search: {
    en: "Item Group",
    az: "",
    ru: "",
  },
  item_group_level_search: {
    en: "Item Apprvoval Level",
    az: "",
    ru: "",
  },
  topping_code_search: {
    en: "Topping Code",
    az: "",
    ru: "",
  },
  item_code_search_error: {
    en: "Please enter item code.",
    az: "",
    ru: "",
  },
  item_name_search_error: {
    en: "Please enter item name.",
    az: "",
    ru: "",
  },
  default_unit_search_error: {
    en: "Please select item default unit.",
    az: "",
    ru: "",
  },
  pulse_category_search_error: {
    en: "Please select item pulse category.",
    az: "",
    ru: "",
  },
  status_search_error: {
    en: "Please select item status.",
    az: "",
    ru: "",
  },
  item_group_search_error: {
    en: "Please select item group.",
    az: "",
    ru: "",
  },
  item_group_level_search_error: {
    en: "Please select item approval level.",
    az: "",
    ru: "",
  },
  topping_code_search_error: {
    en: "Please enter topping code.",
    az: "",
    ru: "",
  },
};

export const items_details_translation = {
  create_item_heading: {
    en: "Create Item",
    az: "",
    ru: "",
  },
  item_detail_heading: {
    en: "Item Detail",
    az: "",
    ru: "",
  },
  item_name_en: {
    en: "Item Name in En",
    az: "",
    ru: "",
  },
  item_name_az: {
    en: "Item Name in Az",
    az: "",
    ru: "",
  },
  item_name_ru: {
    en: "Item Name in Ru",
    az: "",
    ru: "",
  },
  item_code: {
    en: "Item Code",
    az: "",
    ru: "",
  },
  topping_code: {
    en: "Topping Code",
    az: "",
    ru: "",
  },
  item_group: {
    en: "Item Group",
    az: "",
    ru: "",
  },
  item_group_level: {
    en: "Item Approval Level",
    az: "",
    ru: "",
  },
  item_pulse_category: {
    en: "Item Pulse Category",
    az: "",
    ru: "",
  },
  item_default_unit: {
    en: "Item Default Unit",
    az: "",
    ru: "",
  },
  item_description_en: {
    en: "Item Description in En",
    az: "",
    ru: "",
  },
  item_description_az: {
    en: "Item Description in Az",
    az: "",
    ru: "",
  },
  item_description_ru: {
    en: "Item Description in Ru",
    az: "",
    ru: "",
  },
  item_status: {
    en: "Item Status",
    az: "",
    ru: "",
  },
  create_item: {
    en: "Create Item",
    az: "",
    ru: "",
  },
  update_item: {
    en: "Update Item",
    az: "",
    ru: "",
  },
  item_price: {
    en: "Item Price",
    az: "",
    ru: "",
  },
  item_created_success: {
    en: "Item created succesfully.",
    az: "",
    ru: "",
  },
  item_updated_success: {
    en: "Item updated succesfully.",
    az: "",
    ru: "",
  },
  item_name_en_placeholder: {
    en: "Please enter item name in EN.",
    az: "",
    ru: "",
  },
  item_name_az_placeholder: {
    en: "Please enter item name in AZ.",
    az: "",
    ru: "",
  },
  item_name_ru_placeholder: {
    en: "Please enter item name in RU.",
    az: "",
    ru: "",
  },
  item_code_placeholder: {
    en: "Please enter item code.",
    az: "",
    ru: "",
  },
  item_group_placeholder: {
    en: "Please select item group.",
    az: "",
    ru: "",
  },
  item_group_level_placeholder: {
    en: "Please select item approval level.",
    az: "",
    ru: "",
  },
  item_default_unit_placeholder: {
    en: "Please select item default unit.",
    az: "",
    ru: "",
  },
  topping_code_placeholder: {
    en: "Please enter topping code",
    az: "",
    ru: "",
  },
  item_pulse_category_placeholder: {
    en: "Please select item pulse category",
    az: "",
    ru: "",
  },
  item_description_en_placeholder: {
    en: "Please enter item description in EN.",
    az: "",
    ru: "",
  },
  item_description_az_placeholder: {
    en: "Please enter item description in AZ.",
    az: "",
    ru: "",
  },
  item_description_ru_placeholder: {
    en: "Please enter item description in RU.",
    az: "",
    ru: "",
  },
  item_status_placeholder: {
    en: "Please select item status.",
    az: "",
    ru: "",
  },
  item_price_placeholder: {
    en: "Please enter item price.",
    az: "",
    ru: "",
  },
};

export const unit_of_measurement_register_translation = {
  unit_of_measurement_heading: {
    en: "Unit of Measurements",
    az: "",
    ru: "",
  },
  unit_of_measurement_name: {
    en: "Unit of Measurement Name",
    az: "",
    ru: "",
  },
  unit_of_measurement_category: {
    en: "Unit of Measurement Category",
    az: "",
    ru: "",
  },
  unit_of_measurement_description: {
    en: "Unit of Measurement Description",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  create_unit_of_measurement: {
    en: "Create Unit of Measurement",
    az: "",
    ru: "",
  },
};

export const unit_of_measurement_details_translation = {
  create_unit_of_measurement_heading: {
    en: "Create Unit of Measurement",
    az: "",
    ru: "",
  },
  unit_of_measurement_detail_heading: {
    en: "Unit of Measurement Detail",
    az: "",
    ru: "",
  },
  unit_of_measurement_category: {
    en: "Unit of Measurement Category",
    az: "",
    ru: "",
  },
  unit_of_measurement_name_en: {
    en: "Unit of Measurement Name in En",
    az: "",
    ru: "",
  },
  unit_of_measurement_name_az: {
    en: "Unit of Measurement Name in Az",
    az: "",
    ru: "",
  },
  unit_of_measurement_name_ru: {
    en: "Unit of Measurement Name in Ru",
    az: "",
    ru: "",
  },
  unit_of_measurement_description_en: {
    en: "Unit of Measurment Description in En",
    az: "",
    ru: "",
  },
  unit_of_measurement_description_az: {
    en: "Unit of Measurment Description in Az",
    az: "",
    ru: "",
  },
  unit_of_measurement_description_ru: {
    en: "Unit of Measurment Description in Ru",
    az: "",
    ru: "",
  },
  create_unit_of_measurement: {
    en: "Create Unit of Measurement",
    az: "",
    ru: "",
  },
  update_unit_of_measurement: {
    en: "Update Unit of Measurement",
    az: "",
    ru: "",
  },
  unit_of_measurement_created_success: {
    en: "Unit of Measurement created successfully.",
    az: "",
    ru: "",
  },
  unit_of_measurement_updated_success: {
    en: "Unit of Measurement updated successfully.",
    az: "",
    ru: "",
  },
  unit_of_measurement_category_name_placeholder: {
    en: "Please select UoM Category",
    az: "",
    ru: "",
  },
  unit_of_measurement_name_en_placeholder: {
    en: "Please select UoM name in EN",
    az: "",
    ru: "",
  },
  unit_of_measurement_name_az_placeholder: {
    en: "Please select UoM name in AZ",
    az: "",
    ru: "",
  },
  unit_of_measurement_name_ru_placeholder: {
    en: "Please select UoM name in RU",
    az: "",
    ru: "",
  },
  unit_of_measurement_description_en_placeholder: {
    en: "Please select UoM description in EN",
    az: "",
    ru: "",
  },
  unit_of_measurement_description_az_placeholder: {
    en: "Please select UoM description in AZ",
    az: "",
    ru: "",
  },
  unit_of_measurement_description_ru_placeholder: {
    en: "Please select UoM description in RU",
    az: "",
    ru: "",
  },
};

export const uom_category_register_translation = {
  unit_of_measurement_category_heading: {
    en: "Unit of Measurements Category",
    az: "",
    ru: "",
  },
  unit_of_category_name: {
    en: "UoM Category Name",
    az: "",
    ru: "",
  },
  unit_of_category_description: {
    en: "UoM Category Description",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  create_uom_category: {
    en: "Create Unit of Measurement Category",
    az: "",
    ru: "",
  },
};

export const uom_category_details_translation = {
  create_uom_category_heading: {
    en: "Create Unit of Measurement Category",
    az: "",
    ru: "",
  },
  uom_category_details_heading: {
    en: "Unit of Measurement Category Details",
    az: "",
    ru: "",
  },
  uom_category_name_en: {
    en: "Unit of Measurement Category Name in En",
    az: "",
    ru: "",
  },
  uom_category_name_az: {
    en: "Unit of Measurement Category Name in Az",
    az: "",
    ru: "",
  },
  uom_category_name_ru: {
    en: "Unit of Measurement Category Name in Ru",
    az: "",
    ru: "",
  },
  uom_category_description_en: {
    en: "Unit of Measurement Category Description in En",
    az: "",
    ru: "",
  },
  uom_category_description_az: {
    en: "Unit of Measurement Category Description in Az",
    az: "",
    ru: "",
  },
  uom_category_description_ru: {
    en: "Unit of Measurement Category Description in Ru",
    az: "",
    ru: "",
  },
  create_uom_category: {
    en: "Create Unit of Measurement Category",
    az: "",
    ru: "",
  },
  update_uom_category: {
    en: "Update Unit of Measurement Category",
    az: "",
    ru: "",
  },
  uom_category_name_en_placeholder: {
    en: "Please enter unit of measurement category name in EN",
    az: "",
    ru: "",
  },
  uom_category_name_az_placeholder: {
    en: "Please enter unit of measurement category name in AZ",
    az: "",
    ru: "",
  },
  uom_category_name_ru_placeholder: {
    en: "Please enter unit of measurement category name in RU",
    az: "",
    ru: "",
  },
  uom_category_description_en_placeholder: {
    en: "Please enter unit of measurement category description in EN",
    az: "",
    ru: "",
  },
  uom_category_description_az_placeholder: {
    en: "Please enter unit of measurement category description in AZ",
    az: "",
    ru: "",
  },
  uom_category_description_ru_placeholder: {
    en: "Please enter unit of measurement category description in RU",
    az: "",
    ru: "",
  },
  uom_category_created_success: {
    en: "Unit of Measurement Category created successfully.",
    az: "",
    ru: "",
  },
  uom_category_updated_success: {
    en: "Unit of Measurement Category updated successfully.",
    az: "",
    ru: "",
  },
};

export const uom_factors_register_translation = {
  unit_of_measurement_factor_heading: {
    en: "Unit of Measurement Factors",
    az: "",
    ru: "",
  },
  uom_group: {
    en: "UoM Group",
    az: "",
    ru: "",
  },
  uom_from: {
    en: "UoM From",
    az: "",
    ru: "",
  },
  uom_to: {
    en: "UoM To",
    az: "",
    ru: "",
  },
  uom_value: {
    en: "UoM Value",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  create_uom_factor: {
    en: "Create Unit of Measurement Factor",
    az: "",
    ru: "",
  },
};

export const uom_factors_details_translation = {
  create_uom_factor_heading: {
    en: "Create Unit of Measurement Factor",
    az: "",
    ru: "",
  },
  uom_factor_detail_heading: {
    en: "Unit of Measurement Factor Detail",
    az: "",
    ru: "",
  },
  uom_from: {
    en: "Unit of Measurement From",
    az: "",
    ru: "",
  },
  uom_to: {
    en: "Unit of Measurement To",
    az: "",
    ru: "",
  },
  uom_value: {
    en: "Unit of Measurement Value",
    az: "",
    ru: "",
  },
  create_uom_factor: {
    en: "Create Unit of Measurement Factor",
    az: "",
    ru: "",
  },
  update_uom_factor: {
    en: "Update Unit of Measurement Factor",
    az: "",
    ru: "",
  },
  uom_factor_created_success: {
    en: "UoM Factor created successfully.",
    az: "",
    ru: "",
  },
  uom_factor_updated_success: {
    en: "UoM Factor updated successfully.",
    az: "",
    ru: "",
  },
  uom_factor_measurement_from_placeholder: {
    en: "Please select UoM from.",
    az: "",
    ru: "",
  },
  uom_factor_measurement_to_placeholder: {
    en: "Please select UoM to.",
    az: "",
    ru: "",
  },
  uom_factor_measurement_value_placeholder: {
    en: "Please enter unit of measurement value.",
    az: "",
    ru: "",
  },
};

export const pulse_category_register_translation = {
  pulse_category_heading: {
    en: "Inventory Pulse Category",
    az: "",
    ru: "",
  },
  pulse_category_name: {
    en: "Pulse Category Name",
    az: "",
    ru: "",
  },
  pulse_category_description: {
    en: "Pulse Category Description",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  create_pulse_category: {
    en: "Create Pulse Category",
    az: "",
    ru: "",
  },
};

export const pulse_category_details_translation = {
  create_pulse_category_heading: {
    en: "Create Inventory Pulse Category",
    az: "",
    ru: "",
  },
  pulse_category_detail_heading: {
    en: "Inventory Pulse Category Detail",
    az: "",
    ru: "",
  },
  pulse_category_name_en: {
    en: "Pulse Category Name in En",
    az: "",
    ru: "",
  },
  pulse_category_name_az: {
    en: "Pulse Category Name in Az",
    az: "",
    ru: "",
  },
  pulse_category_name_ru: {
    en: "Pulse Category Name in Ru",
    az: "",
    ru: "",
  },
  pulse_category_description_en: {
    en: "Pulse Category Description in En",
    az: "",
    ru: "",
  },
  pulse_category_description_az: {
    en: "Pulse Category Description in Az",
    az: "",
    ru: "",
  },
  pulse_category_description_ru: {
    en: "Pulse Category Description in Ru",
    az: "",
    ru: "",
  },
  create_pulse_category: {
    en: "Create Pulse Category",
    az: "",
    ru: "",
  },
  update_pulse_category: {
    en: "Update Pulse Category",
    az: "",
    ru: "",
  },
  pulse_category_name_en_placeholder: {
    en: "Please enter pulse category name in EN.",
    az: "",
    ru: "",
  },
  pulse_category_name_az_placeholder: {
    en: "Please enter pulse category name in AZ.",
    az: "",
    ru: "",
  },
  pulse_category_name_ru_placeholder: {
    en: "Please enter pulse category name in RU.",
    az: "",
    ru: "",
  },
  pulse_category_description_en_placeholder: {
    en: "Please enter pulse category description in EN.",
    az: "",
    ru: "",
  },
  pulse_category_description_az_placeholder: {
    en: "Please enter pulse category description in AZ.",
    az: "",
    ru: "",
  },
  pulse_category_description_ru_placeholder: {
    en: "Please enter pulse category description in RU.",
    az: "",
    ru: "",
  },
  pulse_category_created_success: {
    en: "Pulse Category created successfully.",
    az: "",
    ru: "",
  },
  pulse_category_updated_success: {
    en: "Pulse Category updated successfully.",
    az: "",
    ru: "",
  },
};

export const item_approval_level_translation = {
  item_group_level_heading: {
    en: "Item Approval Levels",
    az: "",
    ru: "",
  },
  item_group_level_name: {
    en: "Name",
    az: "",
    ru: "",
  },
  item_group_level_description: {
    en: "Description",
    az: "",
    ru: "",
  },
  item_group_office_level: {
    en: "Approval Level",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  create_item_group_level: {
    en: "Create Item Approval Level",
    az: "",
    ru: "",
  },
};

export const item_approval_level_details_translation = {
  create_item_group_level_heading: {
    en: "Create Item Approval Level",
    az: "",
    ru: "",
  },
  item_group_level_detail_heading: {
    en: "Item Approval Level Detail",
    az: "",
    ru: "",
  },
  item_group_level_name_en: {
    en: "Item Approval Level Name in En",
    az: "",
    ru: "",
  },
  item_group_level_name_az: {
    en: "Item Approval Level Name in Az",
    az: "",
    ru: "",
  },
  item_group_level_name_ru: {
    en: "Item Approval Level Name in Ru",
    az: "",
    ru: "",
  },
  item_group_level_description_en: {
    en: "Item Approval Level Description in En",
    az: "",
    ru: "",
  },
  item_group_level_description_az: {
    en: "Item Approval Level Description in Az",
    az: "",
    ru: "",
  },
  item_group_level_description_ru: {
    en: "Item Approval Level Description in Ru",
    az: "",
    ru: "",
  },
  aprroval_permission_level_for_office: {
    en: "Approval Permission Level",
    az: "",
    ru: "",
  },
  approval_permission_level_for_store: {
    en: "Approval Permission Level For Store",
    az: "",
    ru: "",
  },
  create_item_group_level: {
    en: "Create Item Approval Level",
    az: "",
    ru: "",
  },
  update_item_group_level: {
    en: "Update Item Approval Level",
    az: "",
    ru: "",
  },
  item_group_level_created_success: {
    en: "Item approval level created succesfully.",
    az: "",
    ru: "",
  },
  item_group_level_updated_success: {
    en: "Item approval level updated succesfully.",
    az: "",
    ru: "",
  },
  item_group_level_name_en_placeholder: {
    en: "Please enter item approval level name in EN.",
    az: "",
    ru: "",
  },
  item_group_level_name_az_placeholder: {
    en: "Please enter item approval level name in AZ.",
    az: "",
    ru: "",
  },
  item_group_level_name_ru_placeholder: {
    en: "Please enter item approval level name in RU.",
    az: "",
    ru: "",
  },
  item_group_level_description_en_placeholder: {
    en: "Please enter item approval level description in EN.",
    az: "",
    ru: "",
  },
  item_group_level_description_az_placeholder: {
    en: "Please enter item approval level description in AZ.",
    az: "",
    ru: "",
  },
  item_group_level_description_ru_placeholder: {
    en: "Please enter item approval level description in RU.",
    az: "",
    ru: "",
  },
  item_group_level_permission_level_for_office_placeholder: {
    en: "Please select approval permission level",
    az: "",
    ru: "",
  },
  item_group_level_permission_level_for_store_placeholder: {
    en: "Please select approval permission level for store",
    az: "",
    ru: "",
  },
};

/* FINANCE TRANSLATION */

export const payment_summary_translation = {
  payment_summary_heading: {
    en: "Daily Payment Summary",
    az: "",
    ru: "",
  },
  cash_sales: {
    en: "Cash Sales",
    az: "",
    ru: "",
  },
  pos_sales: {
    en: "POS Sales",
    az: "",
    ru: "",
  },
  online_sales: {
    en: "Online Sales",
    az: "",
    ru: "",
  },
  wolt_cash_sales: {
    en: "Wolt Pulse Sales",
    az: "",
    ru: "",
  },
  wolt_online_sales: {
    en: "Wolt Admin Sales",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  cash: {
    en: "Cash",
    az: "",
    ru: "",
  },
  pos: {
    en: "POS",
    az: "",
    ru: "",
  },
  online: {
    en: "Online",
    az: "",
    ru: "",
  },
  wolt_cash: {
    en: "Wolt Pulse",
    az: "",
    ru: "",
  },
  wolt_online: {
    en: "Wolt Admin",
    az: "",
    ru: "",
  },
};

export const payment_request_translation = {
  create_payment_request_heading: {
    en: "Create Payment Request",
    az: "",
    ru: "",
  },
  update_payment_request_heading: {
    en: "Payment Request Detail",
    az: "",
    ru: "",
  },
  requester_info: {
    en: "Requester's Info",
    az: "",
    ru: "",
  },
  id: {
    en: "ID",
    az: "",
    ru: "",
  },
  employee_code: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_code_placeholder: {
    en: "Please enter your employee code.",
    az: "",
    ru: "",
  },
  first_name: {
    en: "First Name",
    az: "",
    ru: "",
  },
  first_name_placeholder: {
    en: "Please enter your first name.",
    az: "",
    ru: "",
  },
  last_name: {
    en: "Last Name",
    az: "",
    ru: "",
  },
  last_name_placeholder: {
    en: "Please enter your last name.",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  department_placeholder: {
    en: "Please enter your department.",
    az: "",
    ru: "",
  },
  position: {
    en: "Position",
    az: "",
    ru: "",
  },
  position_placeholder: {
    en: "Please enter your position.",
    az: "",
    ru: "",
  },
  request_info: {
    en: "Request Info",
    az: "",
    ru: "",
  },
  request_description: {
    en: "Request Description",
    az: "",
    ru: "",
  },
  request_description_placeholder: {
    en: "Please enter request description",
    az: "",
    ru: "",
  },
  reciever_company_info: {
    en: "Reciever's Company Info",
    az: "",
    ru: "",
  },
  name_of_company: {
    en: "Name of Company",
    az: "",
    ru: "",
  },
  name_of_company_placeholder: {
    en: "Please enter company name.",
    az: "",
    ru: "",
  },
  company_email: {
    en: "E-Mail",
    az: "",
    ru: "",
  },
  company_email_placeholder: {
    en: "Please enter email.",
    az: "",
    ru: "",
  },
  company_contact_number: {
    en: "Contact Number",
    az: "",
    ru: "",
  },
  company_contact_number_placeholder: {
    en: "Please enter contact number.",
    az: "",
    ru: "",
  },
  company_contact_person_name: {
    en: "Contact Person Name",
    az: "",
    ru: "",
  },
  company_contact_person_name_placeholder: {
    en: "Please enter contact person name.",
    az: "",
    ru: "",
  },
  company_contact_person_number: {
    en: "Contact Person Number",
    az: "",
    ru: "",
  },
  company_contact_person_number_placeholder: {
    en: "Please enter contact person number.",
    az: "",
    ru: "",
  },
  company_projected_payment_date: {
    en: "Projected Payment Date",
    az: "",
    ru: "",
  },
  company_projected_payment_date_placeholder: {
    en: "Please select projected payment date.",
    az: "",
    ru: "",
  },
  company_projected_payment_recieving_date: {
    en: "Projected Payment Recieving Date",
    az: "",
    ru: "",
  },
  company_projected_payment_recieving_date_placeholder: {
    en: "Please select projected payment recieving date.",
    az: "",
    ru: "",
  },
  company_contract_number: {
    en: "Contract Number",
    az: "",
    ru: "",
  },
  company_contract_number_placeholder: {
    en: "Please enter contract number.",
    az: "",
    ru: "",
  },
  company_invoice_number: {
    en: "Invoice Number",
    az: "",
    ru: "",
  },
  company_invoice_number_placeholder: {
    en: "Please enter invoice number.",
    az: "",
    ru: "",
  },
  request_details: {
    en: "Request Details",
    az: "",
    ru: "",
  },
  payment_type: {
    en: "Payment Type",
    az: "",
    ru: "",
  },
  payment_type_placeholder: {
    en: "Please enter payment type.",
    az: "",
    ru: "",
  },
  payment_amount: {
    en: "Payment Amount",
    az: "",
    ru: "",
  },
  payment_amount_placeholder: {
    en: "Please enter payment amount.",
    az: "",
    ru: "",
  },
  total_amount: {
    en: "Total Amount",
    az: "",
    ru: "",
  },
  total_amount_placeholder: {
    en: "Please enter total amount.",
    az: "",
    ru: "",
  },
  remaining_amount: {
    en: "Remaining Amount",
    az: "",
    ru: "",
  },
  remaining_amount_placeholder: {
    en: "Please enter remaining amount.",
    az: "",
    ru: "",
  },
  create_payment_request: {
    en: "Create Request",
    az: "",
    ru: "",
  },
  update_payment_request: {
    en: "Update Request",
    az: "",
    ru: "",
  },
  projected_payment_less_than_today_error: {
    en: "Projected Payment Date cannot be less than today.",
    az: "",
    ru: "",
  },
  projected_recieving_payment_less_than_today_error: {
    en: "Projected Recieving Payment Date cannot be less than today.",
    az: "",
    ru: "",
  },
  approvals: {
    en: "Approvals",
    az: "",
    ru: "",
  },
};

export const material_request_register_translation = {
  material_request_heading: {
    en: "Material Requests",
    az: "",
    ru: "",
  },
  mr_number: {
    en: "MR No.",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  next_action: {
    en: "Next Action",
    az: "",
    ru: "",
  },
  purpose: {
    en: "Purpose",
    az: "",
    ru: "",
  },
  required_by: {
    en: "Required By",
    az: "",
    ru: "",
  },
  priority: {
    en: "Priority",
    az: "",
    ru: "",
  },
  delivered_to: {
    en: "Delivered To",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  modified_by: {
    en: "Modified By",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  create_material_request: {
    en: "Create Material Request",
    az: "",
    ru: "",
  },
};

export const material_request_details_translation = {
  create_material_request_heading: {
    en: "Create Material Request",
    az: "",
    ru: "",
  },
  update_material_request_heading: {
    en: "Material Request Detail",
    az: "",
    ru: "",
  },
  requester_info: {
    en: "Requester Info",
    az: "",
    ru: "",
  },
  employee_code: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_code_placeholder: {
    en: "Please enter your employee code.",
    az: "",
    ru: "",
  },
  first_name: {
    en: "First Name",
    az: "",
    ru: "",
  },
  first_name_placeholder: {
    en: "Please enter your first name.",
    az: "",
    ru: "",
  },
  last_name: {
    en: "Last Name",
    az: "",
    ru: "",
  },
  last_name_placeholder: {
    en: "Please enter your last name.",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  department_placeholder: {
    en: "Please enter your department.",
    az: "",
    ru: "",
  },
  position: {
    en: "Position",
    az: "",
    ru: "",
  },
  position_placeholder: {
    en: "Please enter your position.",
    az: "",
    ru: "",
  },
  mr_number: {
    en: "MR No.",
    az: "",
    ru: "",
  },
  request_info: {
    en: "Request Info",
    az: "",
    ru: "",
  },
  request_info_date: {
    en: "Requested Date",
    az: "",
    ru: "",
  },
  request_info_date_placeholder: {
    en: "Please select requested date.",
    az: "",
    ru: "",
  },
  required_delivery_date: {
    en: "Required Delivery Date",
    az: "",
    ru: "",
  },
  required_delivery_date_placeholder: {
    en: "Please select required delivery date.",
    az: "",
    ru: "",
  },
  request_priority: {
    en: "Request Priority",
    az: "",
    ru: "",
  },
  request_priority_placeholder: {
    en: "Please select request priority.",
    az: "",
    ru: "",
  },
  delivery_to: {
    en: "Delivery To",
    az: "",
    ru: "",
  },
  delivery_to_placeholder: {
    en: "Please select delivery to.",
    az: "",
    ru: "",
  },
  status_placeholder: {
    en: "Please select status.",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  items_heading: {
    en: "Items",
    az: "",
    ru: "",
  },
  serial_number: {
    en: "S. No.",
    az: "",
    ru: "",
  },
  items: {
    en: "Items",
    az: "",
    ru: "",
  },
  items_placeholder: {
    en: "Please select item name.",
    az: "",
    ru: "",
  },
  unit: {
    en: "Unit",
    az: "",
    ru: "",
  },
  unit_placeholder: {
    en: "Please select UoM.",
    az: "",
    ru: "",
  },
  quantity: {
    en: "Quantity",
    az: "",
    ru: "",
  },
  quantity_placeholder: {
    en: "Please enter quantity.",
    az: "",
    ru: "",
  },
  description: {
    en: "Description",
    az: "",
    ru: "",
  },
  description_placeholder: {
    en: "Please enter description.",
    az: "",
    ru: "",
  },
  add_row: {
    en: "Add Row",
    az: "",
    ru: "",
  },
  create_request: {
    en: "Create Request",
    az: "",
    ru: "",
  },
  update_request: {
    en: "Update Request",
    az: "",
    ru: "",
  },
  material_request_created_success: {
    en: "Material Request created successfully.",
    az: "",
    ru: "",
  },
  material_request_updated_success: {
    en: "Material Request updated successfully.",
    az: "",
    ru: "",
  },
  rejected: {
    en: "Decline",
    az: "",
    ru: "",
  },
  approved: {
    en: "Approve",
    az: "",
    ru: "",
  },
  belong_not_employee: {
    en: "This user is not belong to an employee.",
    az: "",
    ru: "",
  },
  approve: {
    en: "Approve",
    az: "",
    ru: "",
  },
  decline: {
    en: "Decline",
    az: "",
    ru: "",
  },
};

export const material_request_status_translation = {
  pending: {
    en: "Pending",
    az: "",
    ru: "",
  },
  approved: {
    en: "Approved",
    az: "",
    ru: "",
  },
  rejected: {
    en: "Rejected",
    az: "",
    ru: "",
  },
};

export const material_request_priority_translation = {
  high: {
    en: "High",
    az: "",
    ru: "",
  },
  normal: {
    en: "Normal",
    az: "",
    ru: "",
  },
  low: {
    en: "Low",
    az: "",
    ru: "",
  },
};

/* HUMAN RESOURCES TRANSLATION */

export const departments_register_translation = {
  department_heading: {
    en: "Departments",
    az: "",
    ru: "",
  },
  code: {
    en: "Code",
    az: "",
    ru: "",
  },
  department: {
    en: "Name",
    az: "",
    ru: "",
  },
  description: {
    en: "Description",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  modified_by: {
    en: "Modified By",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  create_department: {
    en: "Create Department",
    az: "",
    ru: "",
  },
  search_criteria_for_department: {
    en: "Please select search criteria for filtered result.",
    az: "",
    ru: "",
  },
};

export const department_register_search_translation = {
  search_by_department: {
    en: "Search By Departments",
    az: "",
    ru: "",
  },
  department_code: {
    en: "Department Code",
    az: "",
    ru: "",
  },
  department_name: {
    en: "Department Name",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  department_code_search: {
    en: "Department Code",
    az: "",
    ru: "",
  },
  department_name_search: {
    en: "Department Name",
    az: "",
    ru: "",
  },
  status_search: {
    en: "Status",
    az: "",
    ru: "",
  },
  department_code_search_error: {
    en: "Please enter department code.",
    az: "",
    ru: "",
  },
  department_name_search_error: {
    en: "Please enter department name.",
    az: "",
    ru: "",
  },
  status_search_error: {
    en: "Please select department status.",
    az: "",
    ru: "",
  },
};

export const department_details_translation = {
  create_department_heading: {
    en: "Create Department",
    az: "",
    ru: "",
  },
  update_department_heading: {
    en: "Department Detail",
    az: "",
    ru: "",
  },
  department_code: {
    en: "Department Code",
    az: "",
    ru: "",
  },
  department_code_placeholder: {
    en: "Please enter department code.",
    az: "",
    ru: "",
  },
  report_to: {
    en: "Report To",
    az: "",
    ru: "",
  },
  report_to_placeholder: {
    en: "Please select report to.",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  status_placeholder: {
    en: "Please select status.",
    az: "",
    ru: "",
  },
  department_name_en: {
    en: "Department Name",
    az: "",
    ru: "",
  },
  department_name_en_placeholder: {
    en: "Please enter department name.",
    az: "",
    ru: "",
  },
  department_name_az: {
    en: "Department Name in AZ",
    az: "",
    ru: "",
  },
  department_name_az_placeholder: {
    en: "Please enter department name in AZ.",
    az: "",
    ru: "",
  },
  department_name_ru: {
    en: "Department Name in RU",
    az: "",
    ru: "",
  },
  department_name_ru_placeholder: {
    en: "Please enter department name in RU.",
    az: "",
    ru: "",
  },
  department_description_en: {
    en: "Department Description",
    az: "",
    ru: "",
  },
  department_description_en_placeholder: {
    en: "Please enter department description.",
    az: "",
    ru: "",
  },
  department_description_az: {
    en: "Department Description in AZ",
    az: "",
    ru: "",
  },
  department_description_az_placeholder: {
    en: "Please enter department description in AZ.",
    az: "",
    ru: "",
  },
  department_description_ru: {
    en: "Department Description in RU",
    az: "",
    ru: "",
  },
  department_description_ru_placeholder: {
    en: "Please enter department description in RU.",
    az: "",
    ru: "",
  },
  create_department: {
    en: "Create Department",
    az: "",
    ru: "",
  },
  update_department: {
    en: "Update Department",
    az: "",
    ru: "",
  },
  create_department_success: {
    en: "Department created successfully.",
    az: "",
    ru: "",
  },
  update_department_success: {
    en: "Department updated successfully.",
    az: "",
    ru: "",
  },
};

export const designations_register_translation = {
  designation_heading: {
    en: "Designations",
    az: "",
    ru: "",
  },
  code: {
    en: "Code",
    az: "",
    ru: "",
  },
  name: {
    en: "Name",
    az: "",
    ru: "",
  },
  department_name: {
    en: "Department",
    az: "",
    ru: "",
  },
  description: {
    en: "Description",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  modified_by: {
    en: "Modified By",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  create_designation: {
    en: "Create Designation",
    az: "",
    ru: "",
  },
  search_criteria_for_designation: {
    en: "Please select search criteria for filtered result.",
    az: "",
    ru: "",
  },
};

export const designation_register_search_translation = {
  search_by_designation: {
    en: "Search By Designations",
    az: "",
    ru: "",
  },
  designation_code: {
    en: "Designation Code",
    az: "",
    ru: "",
  },
  designation_name: {
    en: "Designation Name",
    az: "",
    ru: "",
  },
  department_name: {
    en: "Department Name",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  designation_code_search: {
    en: "Desigantion Code",
    az: "",
    ru: "",
  },
  designation_name_search: {
    en: "Designation Name",
    az: "",
    ru: "",
  },
  department_name_search: {
    en: "Department Name",
    az: "",
    ru: "",
  },
  status_search: {
    en: "Status",
    az: "",
    ru: "",
  },
  designation_code_search_error: {
    en: "Please enter designation code.",
    az: "",
    ru: "",
  },
  designation_name_search_error: {
    en: "Please enter designation name.",
    az: "",
    ru: "",
  },
  department_name_search_error: {
    en: "Please select department.",
    az: "",
    ru: "",
  },
  status_search_error: {
    en: "Please select status.",
    az: "",
    ru: "",
  },
};

export const designation_details_translation = {
  create_designation_heading: {
    en: "Create Designation",
    az: "",
    ru: "",
  },
  update_designation_heading: {
    en: "Designation Detail",
    az: "",
    ru: "",
  },
  designation_code: {
    en: "Designation Code",
    az: "",
    ru: "",
  },
  designation_code_placeholder: {
    en: "Please enter designation code.",
    az: "",
    ru: "",
  },
  report_to: {
    en: "Report To",
    az: "",
    ru: "",
  },
  report_to_placeholder: {
    en: "Please select report to.",
    az: "",
    ru: "",
  },
  select_department: {
    en: "Department",
    az: "",
    ru: "",
  },
  select_department_placeholder: {
    en: "Please select department",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  status_placeholder: {
    en: "Please select status.",
    az: "",
    ru: "",
  },
  designation_name_en: {
    en: "Designation Name",
    az: "",
    ru: "",
  },
  designation_name_en_placeholder: {
    en: "Please enter designation name.",
    az: "",
    ru: "",
  },
  designation_name_az: {
    en: "Designation Name in AZ",
    az: "",
    ru: "",
  },
  designation_name_az_placeholder: {
    en: "Please enter designation name in AZ.",
    az: "",
    ru: "",
  },
  designation_name_ru: {
    en: "Designation Name in RU",
    az: "",
    ru: "",
  },
  designation_name_ru_placeholder: {
    en: "Please enter designation name in RU.",
    az: "",
    ru: "",
  },
  designation_description_en: {
    en: "Designation Description in EN",
    az: "",
    ru: "",
  },
  designation_description_en_placeholder: {
    en: "Please enter designation description in EN.",
    az: "",
    ru: "",
  },
  designation_description_az: {
    en: "Designation Description in AZ",
    az: "",
    ru: "",
  },
  designation_description_az_placeholder: {
    en: "Please enter designation description in AZ.",
    az: "",
    ru: "",
  },
  designation_description_ru: {
    en: "Designation Description in RU",
    az: "",
    ru: "",
  },
  designation_description_ru_placeholder: {
    en: "Please enter designation description in RU.",
    az: "",
    ru: "",
  },
  create_designation: {
    en: "Create Designation",
    az: "",
    ru: "",
  },
  update_designation: {
    en: "Update Designation",
    az: "",
    ru: "",
  },
  create_designation_success: {
    en: "Designation created successfully.",
    az: "",
    ru: "",
  },
  update_designation_success: {
    en: "Designation updated successfully.",
    az: "",
    ru: "",
  },
};

export const employees_register_translation = {
  employees_heading: {
    en: "Employees",
    az: "",
    ru: "",
  },
  employee_code: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_name: {
    en: "Employee Name",
    az: "",
    ru: "",
  },
  designation: {
    en: "Designation",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  modified_by: {
    en: "Modified By",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  create_employee: {
    en: "Create Employee",
    az: "",
    ru: "",
  },
  search_criteria_for_employee: {
    en: "Please select search criteria for filtered result.",
    az: "",
    ru: "",
  },
  employee_joining_date_not_greater_than_to_date: {
    en: "Employee joining date to cannot be greater than from date.",
    az: "",
    ru: "",
  },
  employee_date_birth_not_greater_than_to_date: {
    en: "Employee date of birth to cannot be greater than from date.",
    az: "",
    ru: "",
  },
};

export const employees_register_search_translation = {
  search_by_employee: {
    en: "Search By Employees",
    az: "",
    ru: "",
  },
  employee_code: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_name: {
    en: "Employee Name",
    az: "",
    ru: "",
  },
  designation_name: {
    en: "Designation",
    az: "",
    ru: "",
  },
  department_name: {
    en: "Department",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  location: {
    en: "Location",
    az: "",
    ru: "",
  },
  address: {
    en: "Address",
    az: "",
    ru: "",
  },
  email: {
    en: "Email",
    az: "",
    ru: "",
  },
  phone: {
    en: "Phone",
    az: "",
    ru: "",
  },
  gender: {
    en: "Gender",
    az: "",
    ru: "",
  },
  joining_date: {
    en: "Joining Date",
    az: "",
    ru: "",
  },
  date_of_birth: {
    en: "Date of Birth",
    az: "",
    ru: "",
  },
  employee_type: {
    en: "Employee Type",
    az: "",
    ru: "",
  },
  is_manager: {
    en: "Is Manager?",
    az: "",
    ru: "",
  },
  employee_code_search: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_name_search: {
    en: "Employee Name",
    az: "",
    ru: "",
  },
  designation_name_search: {
    en: "Designation Name",
    az: "",
    ru: "",
  },
  department_name_search: {
    en: "Department Name",
    az: "",
    ru: "",
  },
  status_search: {
    en: "Status",
    az: "",
    ru: "",
  },
  location_search: {
    en: "Location",
    az: "",
    ru: "",
  },
  address_search: {
    en: "Address",
    az: "",
    ru: "",
  },
  email_search: {
    en: "Email",
    az: "",
    ru: "",
  },
  phone_search: {
    en: "Phone",
    az: "",
    ru: "",
  },
  gender_search: {
    en: "Gender",
    az: "",
    ru: "",
  },
  joining_date_from: {
    en: "Joining Date From",
    az: "",
    ru: "",
  },
  joining_date_to: {
    en: "Joining Date To",
    az: "",
    ru: "",
  },
  date_of_birth_from: {
    en: "Date of Birth From",
    az: "",
    ru: "",
  },
  date_of_birth_to: {
    en: "Date of Birth To",
    az: "",
    ru: "",
  },
  employee_type_search: {
    en: "Employee Type",
    az: "",
    ru: "",
  },
  is_manager_search: {
    en: "Is Manager?",
    az: "",
    ru: "",
  },
  emp_exit_search: {
    en: "Exit Status",
    az: "",
    ru: "",
  },
  employee_code_search_error: {
    en: "Please enter employee code.",
    az: "",
    ru: "",
  },
  employee_name_search_error: {
    en: "Please enter employee name.",
    az: "",
    ru: "",
  },
  designation_name_search_error: {
    en: "Please select employee designation.",
    az: "",
    ru: "",
  },
  department_name_search_error: {
    en: "Please select employee department.",
    az: "",
    ru: "",
  },
  status_search_error: {
    en: "Please select employee status.",
    az: "",
    ru: "",
  },
  location_search_error: {
    en: "Please select employee location.",
    az: "",
    ru: "",
  },
  address_search_error: {
    en: "Please enter employee address.",
    az: "",
    ru: "",
  },
  email_search_error: {
    en: "Please enter employee email.",
    az: "",
    ru: "",
  },
  phone_search_error: {
    en: "Please enter employee phone no.",
    az: "",
    ru: "",
  },
  gender_search_error: {
    en: "Please select employee gender.",
    az: "",
    ru: "",
  },
  joining_date_from_error: {
    en: "Please select joining date from.",
    az: "",
    ru: "",
  },
  joining_date_to_error: {
    en: "Please select joining date to.",
    az: "",
    ru: "",
  },
  date_of_birth_from_error: {
    en: "Please select date of birth from.",
    az: "",
    ru: "",
  },
  date_of_birth_to_error: {
    en: "Please select date of birth to.",
    az: "",
    ru: "",
  },
  employee_type_search_error: {
    en: "Please select employee type.",
    az: "",
    ru: "",
  },
  is_manager_search_error: {
    en: "Please select employee manager status.",
    az: "",
    ru: "",
  },
  emp_exit_search_error: {
    en: "Please select employee exit status.",
    az: "",
    ru: "",
  },
};

export const employees_details_translation = {
  create_employee_heading: {
    en: "Create Employee",
    az: "",
    ru: "",
  },
  update_employee_heading: {
    en: "Employee Detail",
    az: "",
    ru: "",
  },
  employee_status: {
    en: "Status",
    az: "",
    ru: "",
  },
  employee_status_placeholder: {
    en: "Please select employee status.",
    az: "",
    ru: "",
  },
  employee_code: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_code_placeholder: {
    en: "Please enter employee code.",
    az: "",
    ru: "",
  },
  employee_code_length_placeholder: {
    en: "Employee code length must be equal to 6.",
    az: "",
    ru: "",
  },
  employee_pin: {
    en: "Employee Pin",
    az: "",
    ru: "",
  },
  employee_pin_placeholder: {
    en: "Please enter employee pin.",
    az: "",
    ru: "",
  },
  employee_pin_length_placeholder: {
    en: "Employee pin length must be equal to 4.",
    az: "",
    ru: "",
  },
  employee_first_name: {
    en: "First Name",
    az: "",
    ru: "",
  },
  employee_first_name_placeholder: {
    en: "Please enter first name.",
    az: "",
    ru: "",
  },
  employee_last_name: {
    en: "Last Name",
    az: "",
    ru: "",
  },
  employee_last_name_placeholder: {
    en: "Please enter last name.",
    az: "",
    ru: "",
  },
  employee_father_name: {
    en: "Father Name",
    az: "",
    ru: "",
  },
  employee_father_name_placeholder: {
    en: "Please enter employee father's name.",
    az: "",
    ru: "",
  },
  employee_department: {
    en: "Department",
    az: "",
    ru: "",
  },
  employee_department_placeholder: {
    en: "Please select department.",
    az: "",
    ru: "",
  },
  employee_designation: {
    en: "Designation",
    az: "",
    ru: "",
  },
  employee_designation_placeholder: {
    en: "Please select designation.",
    az: "",
    ru: "",
  },
  employee_type: {
    en: "Employee Type",
    az: "",
    ru: "",
  },
  employee_type_placeholder: {
    en: "Please select employee type.",
    az: "",
    ru: "",
  },
  employee_location: {
    en: "Location",
    az: "",
    ru: "",
  },
  employee_location_placeholder: {
    en: "Please select employee location.",
    az: "",
    ru: "",
  },
  employee_email: {
    en: "Email",
    az: "",
    ru: "",
  },
  employee_email_placeholder: {
    en: "Please enter employee email.",
    az: "",
    ru: "",
  },
  employee_phone: {
    en: "Phone",
    az: "",
    ru: "",
  },
  employee_phone_placeholder: {
    en: "Please enter employee phone.",
    az: "",
    ru: "",
  },
  employee_joining_date: {
    en: "Joining Date",
    az: "",
    ru: "",
  },
  employee_joining_date_placeholder: {
    en: "Please select employee joining date.",
    az: "",
    ru: "",
  },
  employee_rejoining_date: {
    en: "Re-joining Date",
    az: "",
    ru: "",
  },
  employee_rejoining_date_placeholder: {
    en: "Please select employee re-joining date.",
    az: "",
    ru: "",
  },
  employee_date_of_birth: {
    en: "Date of Birth",
    az: "",
    ru: "",
  },
  employee_date_of_birth_placeholder: {
    en: "Please enter employee date of birth.",
    az: "",
    ru: "",
  },
  employee_age: {
    en: "Age",
    az: "",
    ru: "",
  },
  employee_age_placeholder: {
    en: "Please enter employee date of birth for age.",
    az: "",
    ru: "",
  },
  employee_gender: {
    en: "Gender",
    az: "",
    ru: "",
  },
  employee_gender_placeholder: {
    en: "Please select employee gender.",
    az: "",
    ru: "",
  },
  employee_address: {
    en: "Address",
    az: "",
    ru: "",
  },
  employee_address_placeholder: {
    en: "Please enter employee address.",
    az: "",
    ru: "",
  },
  employee_is_manager: {
    en: "Is Manager",
    az: "",
    ru: "",
  },
  employee_is_manager_placeholder: {
    en: "Please select employee is manager or not.",
    az: "",
    ru: "",
  },
  employee_line_manager: {
    en: "Line Manager",
    az: "",
    ru: "",
  },
  employee_line_manager_placeholder: {
    en: "Please select employee line manager.",
    az: "",
    ru: "",
  },
  employee_is_user: {
    en: "Is User",
    az: "",
    ru: "",
  },
  employee_is_user_placeholder: {
    en: "Please select employee is user or not.",
    az: "",
    ru: "",
  },
  employee_user_email: {
    en: "User Email",
    az: "",
    ru: "",
  },
  employee_user_email_placeholder: {
    en: "Please select employee user email.",
    az: "",
    ru: "",
  },
  employee_language: {
    en: "Language",
    az: "",
    ru: "",
  },
  employee_language_placeholder: {
    en: "Please select employee language.",
    az: "",
    ru: "",
  },
  create_employee: {
    en: "Create Employee",
    az: "",
    ru: "",
  },
  update_employee: {
    en: "Update Employee",
    az: "",
    ru: "",
  },
  create_employee_success: {
    en: "Employee created successfully.",
    az: "",
    ru: "",
  },
  update_employee_success: {
    en: "Employee updated successfully.",
    az: "",
    ru: "",
  },
  select_department_as_primary: {
    en: "Please select department as primary:",
    az: "",
    ru: "",
  },
  no: {
    en: "No",
    az: "",
    ru: "",
  },
  yes_as_primary: {
    en: "Yes as Primary",
    az: "",
    ru: "",
  },
  select_department_error: {
    en: "Please select atleast 1 department as primary.",
    az: "",
    ru: "",
  },
  confirmation: {
    en: "Confirmation",
    az: "",
    ru: "",
  },
  select_designation_as_primary: {
    en: "Please select designation as primary:",
    az: "",
    ru: "",
  },
  select_designation_error: {
    en: "Please select atleast 1 designation as primary.",
    az: "",
    ru: "",
  },
  select_line_manager_as_primary: {
    en: "Please select line manager as primary:",
    az: "",
    ru: "",
  },
  select_line_manager_error: {
    en: "Please select atleast 1 line manager as primary.",
    az: "",
    ru: "",
  },
  employee_shifts: {
    en: "Employee Shifts",
    az: "",
    ru: "",
  },
  employee_shifts_placeholder: {
    en: "Please select employee shifts.",
    az: "",
    ru: "",
  },
  employee_holidays: {
    en: "Employee Holidays",
    az: "",
    ru: "",
  },
  employee_holidays_placeholder: {
    en: "Please select employee holidays.",
    az: "",
    ru: "",
  },
  overview: {
    en: "Overview",
    az: "",
    ru: "",
  },
  joining: {
    en: "Joining",
    az: "",
    ru: "",
  },
  address_contacts: {
    en: "Address & Contacts",
    az: "",
    ru: "",
  },
  attendence_leaves: {
    en: "Attendence & Leaves",
    az: "",
    ru: "",
  },
  salary: {
    en: "Salary",
    az: "",
    ru: "",
  },
  personal: {
    en: "Personal",
    az: "",
    ru: "",
  },
  profile: {
    en: "Profile",
    az: "",
    ru: "",
  },
  exit: {
    en: "Exit",
    az: "",
    ru: "",
  },
  company_details: {
    en: "Company Details",
    az: "",
    ru: "",
  },
  employee_fin_number: {
    en: "FIN",
    az: "",
    ru: "",
  },
  employee_fin_number_placeholder: {
    en: "Please enter employee FIN.",
    az: "",
    ru: "",
  },
  job_applicant: {
    en: "Job Applied For",
    az: "",
    ru: "",
  },
  job_applicant_placeholder: {
    en: "Please enter job applied for.",
    az: "",
    ru: "",
  },
  confirmation_date: {
    en: "Confirmation Date",
    az: "",
    ru: "",
  },
  confirmation_date_placeholder: {
    en: "Please enter employee confirmation date.",
    az: "",
    ru: "",
  },
  notice_days: {
    en: "Notice Days",
    az: "",
    ru: "",
  },
  notice_days_placeholder: {
    en: "Please enter employee notice days.",
    az: "",
    ru: "",
  },
  offer_date: {
    en: "Offer Date",
    az: "",
    ru: "",
  },
  offer_date_placeholder: {
    en: "Please enter employee offer date.",
    az: "",
    ru: "",
  },
  contract_end_date: {
    en: "Contract End Date",
    az: "",
    ru: "",
  },
  contract_end_date_placeholder: {
    en: "Please enter employee contract end date.",
    az: "",
    ru: "",
  },
  emergency_contact_details: {
    en: "Emergency Contact Details",
    az: "",
    ru: "",
  },
  emergency_contact_name: {
    en: "Emergency Contact Name",
    az: "",
    ru: "",
  },
  emergency_contact_name_placeholder: {
    en: "Please enter emergency contact name.",
    az: "",
    ru: "",
  },
  emergency_phone: {
    en: "Emergency Phone",
    az: "",
    ru: "",
  },
  emergency_phone_placeholder: {
    en: "Please enter emergency phone.",
    az: "",
    ru: "",
  },
  emergency_relation: {
    en: "Relation",
    az: "",
    ru: "",
  },
  emergency_relation_placeholder: {
    en: "Please enter emergency relation.",
    az: "",
    ru: "",
  },
  working_hours: {
    en: "Working Hours / Day [ Inc. Break & Lunch ] ",
    az: "",
    ru: "",
  },
  working_hours_placeholder: {
    en: "Please enter employee per day working hours.",
    az: "",
    ru: "",
  },
  gross_salary: {
    en: "Gross Salary",
    az: "",
    ru: "",
  },
  gross_salary_placeholder: {
    en: "Please enter employee gross salary.",
    az: "",
    ru: "",
  },
  official_salary: {
    en: "Official Salary",
    az: "",
    ru: "",
  },
  official_salary_placeholder: {
    en: "Please enter employee official salary.",
    az: "",
    ru: "",
  },
  non_official_salary: {
    en: "Non-Official Salary",
    az: "",
    ru: "",
  },
  non_official_salary_placeholder: {
    en: "Please enter employee non-official salary.",
    az: "",
    ru: "",
  },
  salary_before_increament: {
    en: "Last Salary Before Increament",
    az: "",
    ru: "",
  },
  salary_before_increament_placeholder: {
    en: "Please enter employee last salary before increament.",
    az: "",
    ru: "",
  },
  salary_increament_percentage: {
    en: "Salary Increament %",
    az: "",
    ru: "",
  },
  salary_increament_percentage_placeholder: {
    en: "Please enter employee salary increament %.",
    az: "",
    ru: "",
  },
  marital_status: {
    en: "Marital Status",
    az: "",
    ru: "",
  },
  marital_status_placeholder: {
    en: "Please select employee marital status.",
    az: "",
    ru: "",
  },
  disability: {
    en: "Disability",
    az: "",
    ru: "",
  },
  disability_placeholder: {
    en: "Please select if employee have any disability or not.",
    az: "",
    ru: "",
  },
  blood_group: {
    en: "Blood Group",
    az: "",
    ru: "",
  },
  blood_group_placeholder: {
    en: "Please enter employee blood group.",
    az: "",
    ru: "",
  },
  number_of_dependents: {
    en: "Number of Dependents",
    az: "",
    ru: "",
  },
  number_of_dependents_placeholder: {
    en: "Please enter number of persons dependent on employee.",
    az: "",
    ru: "",
  },
  dependents_list: {
    en: "Persons Dependent on Employee",
    az: "",
    ru: "",
  },
  name: {
    en: "Name",
    az: "",
    ru: "",
  },
  name_placeholder: {
    en: "Please enter dependent name.",
    az: "",
    ru: "",
  },
  date_of_birth: {
    en: "Date of Birth",
    az: "",
    ru: "",
  },
  date_of_birth_placeholder: {
    en: "Please enter dependent date of birth.",
    az: "",
    ru: "",
  },
  relation: {
    en: "Relation",
    az: "",
    ru: "",
  },
  relation_placeholder: {
    en: "Please enter dependent relation.",
    az: "",
    ru: "",
  },
  educational_qualification: {
    en: "Educational Qualitfication",
    az: "",
    ru: "",
  },
  number: {
    en: "No.",
    az: "",
    ru: "",
  },
  school_university: {
    en: "School / University",
    az: "",
    ru: "",
  },
  school_university_placeholder: {
    en: "Please enter employee school or university name.",
    az: "",
    ru: "",
  },
  qualification: {
    en: "Qualification",
    az: "",
    ru: "",
  },
  qualification_placeholder: {
    en: "Please enter employee qualification.",
    az: "",
    ru: "",
  },
  level: {
    en: "Level",
    az: "",
    ru: "",
  },
  level_placeholder: {
    en: "Please enter employee qualification level.",
    az: "",
    ru: "",
  },
  years_of_passing: {
    en: "Years of Passing",
    az: "",
    ru: "",
  },
  years_of_passing_placeholder: {
    en: "Please enter employee year of passing.",
    az: "",
    ru: "",
  },
  add_row: {
    en: "Add Row",
    az: "",
    ru: "",
  },
  previous_work_experience: {
    en: "Previous Work Experience",
    az: "",
    ru: "",
  },
  company: {
    en: "Company",
    az: "",
    ru: "",
  },
  company_placeholder: {
    en: "Please enter company name.",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  department_placeholder: {
    en: "Please enter department name.",
    az: "",
    ru: "",
  },
  designation: {
    en: "Designation",
    az: "",
    ru: "",
  },
  designation_placeholder: {
    en: "Please enter designation name.",
    az: "",
    ru: "",
  },
  from_date: {
    en: "From Date",
    az: "",
    ru: "",
  },
  from_date_palceholder: {
    en: "Please enter exp. from date.",
    az: "",
    ru: "",
  },
  to_date: {
    en: "To Date",
    az: "",
    ru: "",
  },
  to_date_palceholder: {
    en: "Please enter exp. to date.",
    az: "",
    ru: "",
  },
  salary_placeholder: {
    en: "Please enter employee prev. salary.",
    az: "",
    ru: "",
  },
  termination: {
    en: "Termination",
    az: "",
    ru: "",
  },
  resignation: {
    en: "Resignation",
    az: "",
    ru: "",
  },
  termination_or_resignation: {
    en: "Terminated or Resigned",
    az: "",
    ru: "",
  },
  termination_or_resignation_placeholder: {
    en: "Select employee is terminated or resigned.",
    az: "",
    ru: "",
  },
  resignation_letter_date: {
    en: "Resignation Letter Date",
    az: "",
    ru: "",
  },
  resignation_letter_date_placeholder: {
    en: "Please enter resignation letter date.",
    az: "",
    ru: "",
  },
  termination_letter_date: {
    en: "Termination Letter Date",
    az: "",
    ru: "",
  },
  termination_letter_date_placeholder: {
    en: "Please enter termination letter date.",
    az: "",
    ru: "",
  },
  exit_interview_date: {
    en: "Exit Interview Date",
    az: "",
    ru: "",
  },
  exit_interview_date_placeholder: {
    en: "Please enter exit interview date.",
    az: "",
    ru: "",
  },
  relieving_date: {
    en: "Relieving Date",
    az: "",
    ru: "",
  },
  relieving_date_placeholder: {
    en: "Please enter employee relieving date.",
    az: "",
    ru: "",
  },
  new_workplace: {
    en: "New Workplace",
    az: "",
    ru: "",
  },
  new_workplace_placeholder: {
    en: "Please enter employee new workplace name.",
    az: "",
    ru: "",
  },
  reason_for_leaving: {
    en: "Reason for Leaving",
    az: "",
    ru: "",
  },
  reason_for_leaving_placeholder: {
    en: "Please enter employee leaving reason.",
    az: "",
    ru: "",
  },
  salary_month: {
    en: "Salary Month",
    az: "",
    ru: "",
  },
  net_pay_salary: {
    en: "Net Salary",
    az: "",
    ru: "",
  },
  employee_picture: {
    en: "Picture",
    az: "",
    ru: "",
  },
  employee_picture_placeholder: {
    en: "Please upload employee picture.",
    az: "",
    ru: "",
  },
  employee_vacations: {
    en: "Vacations",
    az: "",
    ru: "",
  },
  application_code: {
    en: "Application #",
    az: "",
    ru: ""
  },
  vacations_allocation_per_year: {
    en: "Vacations Allocated / Year",
    az: "",
    ru: "",
  },
  vacations_allocation_per_year_placeholder: {
    en: "Please enter vacations allocated per year.",
    az: "",
    ru: "",
  },
  starts_from: {
    en: "Vacation Starts From",
    az: "",
    ru: "",
  },
  start_from_placeholder: {
    en: "Please select from when vacation starts.",
    az: "",
    ru: "",
  },
  balanced_vacations: {
    en: "Vacations",
    az: "",
    ru: "",
  },
  reason_for_vacation: {
    en: "Vacation Reason",
    az: "",
    ru: "",
  },
  reason_for_leave: {
    en: "Sick Leave Reason",
    az: "",
    ru: "",
  },
  vacation_start_date: {
    en: "Start Date",
    az: "",
    ru: "",
  },
  vacation_end_date: {
    en: "End Date",
    az: "",
    ru: "",
  },
  total_days: {
    en: "Days",
    az: "",
    ru: "",
  },
  approved_by: {
    en: "Approved By",
    az: "",
    ru: "",
  },
  attached_medical_certificate: {
    en: "Medical Certificate",
    az: "",
    ru: "",
  },
  employee_sick_leaves: {
    en: "Sick Leaves",
    az: "",
    ru: "",
  },
  balanced_sick_leaves: {
    en: "Sick Leaves",
    az: "",
    ru: "",
  },
  paid_or_unpaid: {
    en: "Paid / Unpaid",
    az: "",
    ru: ""
  },
  leave_status: {
    en: "Leave Status",
    az: "",
    ru: ""
  },
  updated_by: {
    en: "Updated By",
    az: "",
    ru: ""
  }
};

export const shifts_register_translation = {
  shifts_heading: {
    en: "Shifts",
    az: "",
    ru: "",
  },
  shift_code: {
    en: "Shift Code",
    az: "",
    ru: "",
  },
  name: {
    en: "Name",
    az: "",
    ru: "",
  },
  start_time: {
    en: "Start Time",
    az: "",
    ru: "",
  },
  end_time: {
    en: "End Time",
    az: "",
    ru: "",
  },
  description: {
    en: "Description",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  assigned_employees: {
    en: "Assigned Employees",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  modified_by: {
    en: "Modified By",
    az: "",
    ru: "",
  },
  create_shift: {
    en: "Create Shift",
    az: "",
    ru: "",
  },
  search_criteria_for_shift: {
    en: "Please select search criteria for filtered result.",
    az: "",
    ru: "",
  },
};

export const shifts_register_search_translation = {
  search_by_shift: {
    en: "Search By Shifts",
    az: "",
    ru: "",
  },
  shift_name: {
    en: "Shift Name",
    az: "",
    ru: "",
  },
  start_time: {
    en: "Start Time",
    az: "",
    ru: "",
  },
  end_time: {
    en: "End Time",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  shift_name_search_error: {
    en: "Please enter shift name.",
    az: "",
    ru: "",
  },
  shift_start_time_search_error: {
    en: "Please enter shift start time.",
    az: "",
    ru: "",
  },
  shift_end_time_search_error: {
    en: "Please enter shift end time.",
    az: "",
    ru: "",
  },
  status_search_error: {
    en: "Please select shift status.",
    az: "",
    ru: "",
  },
};

export const shifts_details_translation = {
  create_shift: {
    en: "Create Shift",
    az: "",
    ru: "",
  },
  update_shift: {
    en: "Shift Detail",
    az: "",
    ru: "",
  },
  shift_code: {
    en: "Shift Code",
    az: "",
    ru: "",
  },
  shift_code_placeholder: {
    en: "Shift Code",
    az: "",
    ru: "",
  },
  shift_name_en: {
    en: "Shift Name in EN",
    az: "",
    ru: "",
  },
  shift_name_az: {
    en: "Shift Name in AZ",
    az: "",
    ru: "",
  },
  shift_name_ru: {
    en: "Shift Name in RU",
    az: "",
    ru: "",
  },
  shift_name_en_placeholder: {
    en: "Please enter shift name in EN.",
    az: "",
    ru: "",
  },
  shift_name_az_placeholder: {
    en: "Please enter shift name in AZ.",
    az: "",
    ru: "",
  },
  shift_name_ru_placeholder: {
    en: "Please enter shift name in RU.",
    az: "",
    ru: "",
  },
  start_time: {
    en: "Start Time",
    az: "",
    ru: "",
  },
  start_time_placeholder: {
    en: "Please select start time.",
    az: "",
    ru: "",
  },
  end_time: {
    en: "End Time",
    az: "",
    ru: "",
  },
  end_time_placeholder: {
    en: "Please select end time.",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  status_placeholder: {
    en: "Please select status.",
    az: "",
    ru: "",
  },
  assigned_employees: {
    en: "Assigned Employees",
    az: "",
    ru: "",
  },
  shift_color: {
    en: "Shift Color",
    az: "",
    ru: "",
  },
  shift_color_placeholder: {
    en: "Please select shift color.",
    az: "",
    ru: "",
  },
  assigned_employees_placeholder: {
    en: "Assigned Employees",
    az: "",
    ru: "",
  },
  description_en: {
    en: "Description in EN",
    az: "",
    ru: "",
  },
  description_az: {
    en: "Description in AZ",
    az: "",
    ru: "",
  },
  description_ru: {
    en: "Description in RU",
    az: "",
    ru: "",
  },
  description_en_placeholder: {
    en: "Please enter description in EN.",
    az: "",
    ru: "",
  },
  description_az_placeholder: {
    en: "Please enter description in AZ.",
    az: "",
    ru: "",
  },
  description_ru_placeholder: {
    en: "Please enter description in RU.",
    az: "",
    ru: "",
  },
  shift_suucess_message: {
    en: "Shift created succesfully.",
    az: "",
    ru: "",
  },
  shift_updated_message: {
    en: "Shift updated succesfully.",
    az: "",
    ru: "",
  },
  shift_start_time_required: {
    en: "Please select shift start time.",
    az: "",
    ru: "",
  },
  shift_end_time_required: {
    en: "Please select shift end time.",
    az: "",
    ru: "",
  },
  shift_name_required: {
    en: "Please enter shift name.",
    az: "",
    ru: "",
  },
  shift_color_required: {
    en: "Please select shift color.",
    az: "",
    ru: "",
  },
  update_shift_button: {
    en: "Update Shift",
    az: "",
    ru: "",
  },
  add_row: {
    en: "Add Row",
    az: "",
    ru: "",
  },
  shift_code_required: {
    en: "Please enter shift code.",
    az: "",
    ru: "",
  },
};

export const holidays_register_translation = {
  holidays_heading: {
    en: "Holidays",
    az: "",
    ru: "",
  },
  holiday_code: {
    en: "Holiday Code",
    az: "",
    ru: "",
  },
  name: {
    en: "Name",
    az: "",
    ru: "",
  },
  from_date: {
    en: "From Date",
    az: "",
    ru: "",
  },
  to_date: {
    en: "To Date",
    az: "",
    ru: "",
  },
  description: {
    en: "Description",
    az: "",
    ru: "",
  },
  total_days: {
    en: "Total Days",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  modified_by: {
    en: "Modified By",
    az: "",
    ru: "",
  },
  create_holiday: {
    en: "Create Holiday",
    az: "",
    ru: "",
  },
  search_criteria_for_holiday: {
    en: "Please select search criteria for filtered result.",
    az: "",
    ru: "",
  },
};

export const holidays_register_search_translation = {
  search_by_holiday: {
    en: "Search By Holiday",
    az: "",
    ru: "",
  },
  holiday_code: {
    en: "Holiday Code",
    az: "",
    ru: "",
  },
  holiday_name: {
    en: "Holiday Name",
    az: "",
    ru: "",
  },
  from_date: {
    en: "From Date",
    az: "",
    ru: "",
  },
  to_date: {
    en: "To Date",
    az: "",
    ru: "",
  },
  description: {
    en: "Description",
    az: "",
    ru: "",
  },
  holiday_code_search_error: {
    en: "Please enter holiday code.",
    az: "",
    ru: ""
  },
  holiday_name_search_error: {
    en: "Please enter holiday name.",
    az: "",
    ru: ""
  },
  holiday_desc_search_error: {
    en: "Please enter holiday description.",
    az: "",
    ru: ""
  },
  from_date_search_error: {
    en: "Please select from date.",
    az: "",
    ru: ""
  },
  to_date_search_error: {
    en: "Please select to date.",
    az: "",
    ru: ""
  }
 };

export const holidays_details_translation = {
  create_holiday: {
    en: "Create Holiday",
    az: "",
    ru: "",
  },
  update_holiday_button: {
    en: "Update Holiday",
    az: "",
    ru: "",
  },
  update_holiday: {
    en: "Holiday Detail",
    az: "",
    ru: "",
  },
  holiday_code: {
    en: "Holiday Code",
    az: "",
    ru: "",
  },
  holiday_code_placeholder: {
    en: "Please enter holiday code.",
    az: "",
    ru: "",
  },
  holiday_name_en: {
    en: "Holiday Name in EN",
    az: "",
    ru: "",
  },
  Holiday_name_az: {
    en: "Holiday Name in AZ",
    az: "",
    ru: "",
  },
  holiday_name_ru: {
    en: "Holiday Name in RU",
    az: "",
    ru: "",
  },
  holiday_name_en_placeholder: {
    en: "Please enter holiday name in EN.",
    az: "",
    ru: "",
  },
  holiday_name_az_placeholder: {
    en: "Please enter holiday name in AZ.",
    az: "",
    ru: "",
  },
  holiday_name_ru_placeholder: {
    en: "Please enter holiday name in RU.",
    az: "",
    ru: "",
  },
  from_date: {
    en: "From Date",
    az: "",
    ru: "",
  },
  to_date: {
    en: "To Date",
    az: "",
    ru: "",
  },
  from_date_placeholder: {
    en: "Please select from date.",
    az: "",
    ru: "",
  },
  to_date_plcaholder: {
    en: "Please select to date.",
    az: "",
    ru: "",
  },
  weekly_off: {
    en: "Weekly Off",
    az: "",
    ru: "",
  },
  weekly_off_placeholder: {
    en: "Please select weekly off.",
    az: "",
    ru: "",
  },
  description_en: {
    en: "Description in EN",
    az: "",
    ru: "",
  },
  description_az: {
    en: "Description in AZ",
    az: "",
    ru: "",
  },
  description_ru: {
    en: "Description in RU",
    az: "",
    ru: "",
  },
  description_en_placeholder: {
    en: "Please enter description in EN.",
    az: "",
    ru: "",
  },
  description_az_placeholder: {
    en: "Please enter description in AZ.",
    az: "",
    ru: "",
  },
  description_ru_placeholder: {
    en: "Please enter description in RU.",
    az: "",
    ru: "",
  },
  create_suucess_message: {
    en: "Holiday created successfully.",
    az: "",
    ru: "",
  },
  holiday_updated_message: {
    en: "Holiday updated succesfully.",
    az: "",
    ru: "",
  },
  add_weekly_holidays: {
    en: "Add Weekly Holidays",
    az: "",
    ru: "",
  },
  holidays: {
    en: "Holidays",
    az: "",
    ru: "",
  },
  total_holidays: {
    en: "Total Holidays",
    az: "",
    ru: "",
  },
  serial_no: {
    en: "S No.",
    az: "",
    ru: "",
  },
  date: {
    en: "Date",
    az: "",
    ru: "",
  },
  description: {
    en: "Description",
    az: "",
    ru: "",
  },
  created_success_message: {
    en: "Holiday created successfully.",
    az: "",
    ru: "",
  },
  updated_success_message: {
    en: "Holiday updated successfully.",
    az: "",
    ru: "",
  },
  deleted_success_message: {
    en: "Holiday deleted successfully.",
    az: "",
    ru: "",
  },
  holiday_code_required: {
    en: "Please enter holiday code.",
    az: "",
    ru: "",
  },
  holiday_start_date_required: {
    en: "Please select holiday start date.",
    az: "",
    ru: "",
  },
  holiday_end_date_required: {
    en: "Please select holiday end date.",
    az: "",
    ru: "",
  },
  holiday_name_required: {
    en: "Please enter holiday name in EN.",
    az: "",
    ru: "",
  },
  select_weekly_off: {
    en: "Please select weekly off.",
    az: "",
    ru: "",
  },
  holiday_date_required: {
    en: "Please select holiday date.",
    az: "",
    ru: "",
  },
  holiday_description_required: {
    en: "Please enter holiday description.",
    az: "",
    ru: "",
  },
  add_to_holidays: {
    en: "Add to Holidays",
    az: "",
    ru: "",
  },
};

export const roster_register_translation = {
  roster_heading: {
    en: "Weekly Schedules",
    az: "",
    ru: "",
  },
  roster_code: {
    en: "Roster Code",
    az: "",
    ru: "",
  },
  name: {
    en: "Name",
    az: "",
    ru: "",
  },
  week: {
    en: "Week",
    az: "",
    ru: "",
  },
  location: {
    en: "Location",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  total_employees: {
    en: "Total Employees",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  modified_by: {
    en: "Modified By",
    az: "",
    ru: "",
  },
  create_schedule: {
    en: "Create Schedule",
    az: "",
    ru: "",
  },
};

export const roster_details_translation = {
  create_weekly_schedule: {
    en: "Create Weekly schedule",
    az: "",
    ru: "",
  },
  weekly_schedule: {
    en: "Weekly schedule",
    az: "",
    ru: "",
  },
  employee_location_required: {
    en: "Please select employee location.",
    az: "",
    ru: "",
  },
  employee_department_required: {
    en: "Please select employee department.",
    az: "",
    ru: "",
  },
  previous_week_not_required: {
    en: "You can not save or update previous week schedule.",
    az: "",
    ru: "",
  },
  previous_day_not_required: {
    en: "You can not save or update previous day schedule.",
    az: "",
    ru: "",
  },
  weekly_schedule_created_success: {
    en: "Weekly Schedule created succesfully.",
    az: "",
    ru: "",
  },
  weekly_schedule_updated_success: {
    en: "Weekly Schedule updated succesfully.",
    az: "",
    ru: "",
  },
  select_location: {
    en: "Select Location",
    az: "",
    ru: "",
  },
  select_department: {
    en: "Select Department",
    az: "",
    ru: "",
  },
  select_position: {
    en: "Select Position",
    az: "",
    ru: "",
  },
  select_employee: {
    en: "Select Employee",
    az: "",
    ru: "",
  },
  holiday: {
    en: "Holiday",
    az: "",
    ru: "",
  },
  leave: {
    en: "Leave",
    az: "",
    ru: "",
  },
  off: {
    en: "Off",
    az: "",
    ru: "",
  },
  export_pdf: {
    en: "Export as PDF",
    az: "",
    ru: "",
  },
  export_excel: {
    en: "Export as XLSX",
    az: "",
    ru: "",
  },
  create_schedule: {
    en: "Create Schedule",
    az: "",
    ru: "",
  },
  update_schedule: {
    en: "Update Schedule",
    az: "",
    ru: "",
  },
  select_loaction_first: {
    en: "Please select a location",
    az: "",
    ru: "",
  },
  select_department_first: {
    en: "Please select a department.",
    az: "",
    ru: "",
  },
  select_shift_first: {
    en: "Please select a shift.",
    az: "",
    ru: "",
  },
  roster_code: {
    en: "Roster Code",
    az: "",
    ru: "",
  },
  name: {
    en: "Name",
    az: "",
    ru: "",
  },
  week: {
    en: "Week",
    az: "",
    ru: "",
  },
  location: {
    en: "Location",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  total_employees: {
    en: "Total Employees",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  modified_by: {
    en: "Modified By",
    az: "",
    ru: "",
  },
  schedule: {
    en: "Schedule",
    az: "",
    ru: "",
  },
  current_schedule: {
    en: "Current Schedule",
    az: "",
    ru: "",
  },
  employee_schedule_updated_successfully: {
    en: "Employee Schedule Updated Successfully",
    az: "",
    ru: "",
  },
  start_time: {
    en: "Start Time",
    az: "",
    ru: "",
  },
  end_time: {
    en: "End Time",
    az: "",
    ru: "",
  },
  start_time_placeholder: {
    en: "Please select start time.",
    az: "",
    ru: "",
  },
  end_time_placeholder: {
    en: "Please select end time.",
    az: "",
    ru: "",
  },
};

export const employee_attendence_translation = {
  employee_timesheet_heading: {
    en: "Employee Attendence",
    az: "",
    ru: "",
  },
  employee_timesheet_id: {
    en: "ID",
    az: "",
    ru: "",
  },
  employee_timesheet_date: {
    en: "Date",
    az: "",
    ru: "",
  },
  employee_timesheet_location_code: {
    en: "Location Code",
    az: "",
    ru: "",
  },
  employee_timesheet_location_name: {
    en: "Location",
    az: "",
    ru: "",
  },
  employee_timesheet_total_employees: {
    en: "Total Employees",
    az: "",
    ru: "",
  },
  employee_timesheet_available_employees: {
    en: "Available Employees",
    az: "",
    ru: "",
  },
};

export const employee_attendence_details_translation = {
  employee_timesheet_heading: {
    en: "Employee Attendence",
    az: "",
    ru: "",
  },
  date: {
    en: "Date",
    az: "",
    ru: "",
  },
  day: {
    en: "Day",
    az: "",
    ru: "",
  },
  employee_name: {
    en: "Employee Name",
    az: "",
    ru: "",
  },
  designation: {
    en: "Designation",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  total_hours: {
    en: "Actual",
    az: "",
    ru: "",
  },
  expected_hours: {
    en: "Expected",
    az: "",
    ru: "",
  },
  nine: {
    en: "09:00",
    az: "",
    ru: "",
  },
  nine_thirty: {
    en: "09:30",
    az: "",
    ru: "",
  },
  ten: {
    en: "10:00",
    az: "",
    ru: "",
  },
  ten_thirty: {
    en: "10:30",
    az: "",
    ru: "",
  },
  eleven: {
    en: "11:00",
    az: "",
    ru: "",
  },
  eleven_thirty: {
    en: "11:30",
    az: "",
    ru: "",
  },
  twelve: {
    en: "12:00",
    az: "",
    ru: "",
  },
  twelve_thirty: {
    en: "12:30",
    az: "",
    ru: "",
  },
  thirteen: {
    en: "13:00",
    az: "",
    ru: "",
  },
  thirteen_thirty: {
    en: "13:30",
    az: "",
    ru: "",
  },
  fourteen: {
    en: "14:00",
    az: "",
    ru: "",
  },
  fourteen_thirty: {
    en: "14:30",
    az: "",
    ru: "",
  },
  fifteen: {
    en: "15:00",
    az: "",
    ru: "",
  },
  fifteen_thirty: {
    en: "15:30",
    az: "",
    ru: "",
  },
  sixteen: {
    en: "16:00",
    az: "",
    ru: "",
  },
  sixteen_thirty: {
    en: "16:30",
    az: "",
    ru: "",
  },
  seventeen: {
    en: "17:00",
    az: "",
    ru: "",
  },
  seventeen_thirty: {
    en: "17:30",
    az: "",
    ru: "",
  },
  eighteen: {
    en: "18:00",
    az: "",
    ru: "",
  },
  eighteen_thirty: {
    en: "18:30",
    az: "",
    ru: "",
  },
  nineteen: {
    en: "19:00",
    az: "",
    ru: "",
  },
  nineteen_thirty: {
    en: "19:30",
    az: "",
    ru: "",
  },
  twenty: {
    en: "20:00",
    az: "",
    ru: "",
  },
  twenty_thirty: {
    en: "20:30",
    az: "",
    ru: "",
  },
  twenty_one: {
    en: "21:00",
    az: "",
    ru: "",
  },
  twenty_one_thirty: {
    en: "21:30",
    az: "",
    ru: "",
  },
  twenty_two: {
    en: "22:00",
    az: "",
    ru: "",
  },
  twenty_two_thirty: {
    en: "22:30",
    az: "",
    ru: "",
  },
  twenty_three: {
    en: "23:00",
    az: "",
    ru: "",
  },
  twenty_three_thirty: {
    en: "23:30",
    az: "",
    ru: "",
  },
  twenty_four: {
    en: "00:00",
    az: "",
    ru: "",
  },
  twenty_four_thirty: {
    en: "00:30",
    az: "",
    ru: "",
  },
  one: {
    en: "01:00",
    az: "",
    ru: "",
  },
  one_thirty: {
    en: "01:30",
    az: "",
    ru: "",
  },
  two: {
    en: "02:00",
    az: "",
    ru: "",
  },
  two_thirty: {
    en: "02:30",
    az: "",
    ru: "",
  },
  three: {
    en: "03:00",
    az: "",
    ru: "",
  },
  three_thirty: {
    en: "03:30",
    az: "",
    ru: "",
  },
  four: {
    en: "04:00",
    az: "",
    ru: "",
  },
  four_thirty: {
    en: "04:30",
    az: "",
    ru: "",
  },
  five: {
    en: "05:00",
    az: "",
    ru: "",
  },
  five_thirty: {
    en: "05:30",
    az: "",
    ru: "",
  },
  six: {
    en: "06:00",
    az: "",
    ru: "",
  },
  six_thirty: {
    en: "06:30",
    az: "",
    ru: "",
  },
  seven: {
    en: "07:00",
    az: "",
    ru: "",
  },
  seven_thirty: {
    en: "07:30",
    az: "",
    ru: "",
  },
  eight: {
    en: "08:00",
    az: "",
    ru: "",
  },
  eight_thirty: {
    en: "08:30",
    az: "",
    ru: "",
  },
  select_placeholder: {
    en: "Please select duration.",
    az: "",
    ru: "",
  },
  cannot_update_attendence: {
    en: "You cannot modify previous day attendence.",
    az: "",
    ru: "",
  },
};

export const employee_timesheet_translation = {
  employee_timesheet_heading: {
    en: "Employee Timesheet",
    az: "",
    ru: "",
  },
  employee_code: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_name: {
    en: "Employee Name",
    az: "",
    ru: "",
  },
  day_and_date: {
    en: "Date",
    az: "",
    ru: "",
  },
  clock_in: {
    en: "Clock In",
    az: "",
    ru: "",
  },
  clock_in_difference: {
    en: "Difference",
    az: "",
    ru: "",
  },
  clock_out: {
    en: "Clock Out",
    az: "",
    ru: "",
  },
  clock_out_difference: {
    en: "Difference",
    az: "",
    ru: "",
  },
  expected_hours: {
    en: "Expected Hours",
    az: "",
    ru: "",
  },
  acutal_hours: {
    en: "Actual Hours",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  designation: {
    en: "Designation",
    az: "",
    ru: "",
  },
  location: {
    en: "Location",
    az: "",
    ru: "",
  },
  timesheet_report: {
    en: "Timesheet Report",
    az: "",
    ru: "",
  },
  overtime: {
    en: "Overtime",
    az: "",
    ru: "",
  },
};

export const employee_timesheet_filters_translation = {
  search_by_employee: {
    en: "Search By Employees",
    az: "",
    ru: "",
  },
  timesheet_date: {
    en: "Timesheet Date",
    az: "",
    ru: "",
  },
  timesheet_start_date: {
    en: "Start Date",
    az: "",
    ru: "",
  },
  timesheet_end_date: {
    en: "End Date",
    az: "",
    ru: "",
  },
  employee_name: {
    en: "Employee Name",
    az: "",
    ru: "",
  },
  employee_name_placeholder: {
    en: "Please enter employee name.",
    az: "",
    ru: "",
  },
  employee_code: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_code_placeholder: {
    en: "Please enter employee code.",
    az: "",
    ru: "",
  },
  employee_location: {
    en: "Location",
    az: "",
    ru: "",
  },
  employee_location_placeholder: {
    en: "Please select location.",
    az: "",
    ru: "",
  },
  employee_department: {
    en: "Department",
    az: "",
    ru: "",
  },
  employee_department_placeholder: {
    en: "Please select department.",
    az: "",
    ru: "",
  },
  employee_designation: {
    en: "Designation",
    az: "",
    ru: "",
  },
  employee_designation_placeholder: {
    en: "Please select designation.",
    az: "",
    ru: "",
  },
};

export const employee_timesheet_errors_translation = {
  search_criteria_not_select: {
    en: "Please select search criteria for filtered result.",
    az: "",
    ru: "",
  },
  timesheet_date_required: {
    en: "Please select timesheet date for filtered result.",
    az: "",
    ru: "",
  },
};

export const employee_time_clock_translation = {
  welcome: {
    en: "Welcome",
    az: "",
    ru: "",
  },
  employee_code_placeholder: {
    en: "Please enter personnel code.",
    az: "",
    ru: "",
  },
  employee_pin_placeholder: {
    en: "Please enter personnel pin.",
    az: "",
    ru: "",
  },
  employee_code_error: {
    en: "Please enter correct personnel code.",
    az: "",
    ru: "",
  },
  employee_pin_error: {
    en: "Please enter correct personnel pin.",
    az: "",
    ru: "",
  },
  c: {
    en: "C",
    az: "",
    ru: "",
  },
  enter: {
    en: "Enter",
    az: "",
    ru: "",
  },
  check_in_check_out: {
    en: "Clock In / Clock Out",
    az: "",
    ru: "",
  },
  clock_in_successfully: {
    en: "You are clocked in successfully.",
    az: "",
    ru: "",
  },
  clock_out_successfully: {
    en: "You are clocked out successfully.",
    az: "",
    ru: "",
  },
  not_allowed_location: {
    en: "You are not eligible for this location.",
    az: "",
    ru: "",
  },
  clear: {
    en: "Clear",
    az: "",
    ru: "",
  },
};

export const vacation_register_translation = {
  vacation_heading: {
    en: "Vacation Allocation",
    az: "",
    ru: "",
  },
  employee_code: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_name: {
    en: "Employee Name",
    az: "",
    ru: "",
  },
  allocated_leaves: {
    en: "Allocated Leaves",
    az: "",
    ru: "",
  },
  allocated_vacations: {
    en: "Allocated Vacations",
    az: "",
    ru: "",
  },
  unused_vacations: {
    en: "Unused Vacations",
    az: "",
    ru: "",
  },
  total_vacations: {
    en: "Total Vacations",
    az: "",
    ru: "",
  },
  vacation_start_date: {
    en: "Vacation Start Date",
    az: "",
    ru: "",
  },
  vacation_end_date: {
    en: "Vacation End Date",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  modified_by: {
    en: "Modified By",
    az: "",
    ru: "",
  },
  search_by_vacations: {
    en: "Search By Vacation Details",
    az: "",
    ru: "",
  },
  vacation_starts_from: {
    en: "Vacation Start Date From",
    az: "",
    ru: "",
  },
  vacation_starts_to: {
    en: "Vacation Start Date To",
    az: "",
    ru: "",
  },
  vacation_ends_from: {
    en: "Vacation End Date From",
    az: "",
    ru: "",
  },
  vacation_ends_to: {
    en: "Vacation End Date To",
    az: "",
    ru: "",
  },
  create_vacation: {
    en: "Allocate Vacation",
    az: "",
    ru: "",
  },
  search_criteria_for_vacation: {
    en: "Please select search criteria for filtered result.",
    az: "",
    ru: "",
  },
  employee_code_search_error: {
    en: "Please enter employee code.",
    az: "",
    ru: ""
  },
  employee_name_search_error: {
    en: "Please enter employee name.",
    az: "",
    ru: ""
  },
  allocated_vacation_search_error: {
    en: "Please enter allocated vacations number.",
    az: "",
    ru: ""
  },
  unused_vacation_search_error: {
    en: "Please enter unused vacations number.",
    az: "",
    ru: ""
  },
  total_vacation_search_error: {
    en: "Please enter total vacations number.",
    az: "",
    ru: ""
  },
  vacation_start_date_search_error: {
    en: "Please enter vacation start date.",
    az: "",
    ru: ""
  },
  vacation_end_date_search_error: {
    en: "Please enter vacation end date.",
    az: "",
    ru: ""
  },
};

export const vacation_details_translation = {
  create_vacation_heading: {
    en: "Allocate Vacation",
    az: "",
    ru: "",
  },
  update_vacation_heading: {
    en: "Vacation Allocation Detail",
    az: "",
    ru: "",
  },
  leave_type: {
    en: "Vacation Type",
    az: "",
    ru: "",
  },
  leave_type_placeholder: {
    en: "Please select vacation type.",
    az: "",
    ru: "",
  },
  employee: {
    en: "Employee",
    az: "",
    ru: "",
  },
  employee_placeholder: {
    en: "Please select employee.",
    az: "",
    ru: "",
  },
  from_date: {
    en: "From Date",
    az: "",
    ru: "",
  },
  from_date_placeholder: {
    en: "Please select leave from date.",
    az: "",
    ru: "",
  },
  to_date: {
    en: "To Date",
    az: "",
    ru: "",
  },
  to_date_placeholder: {
    en: "Please select leave to date.",
    az: "",
    ru: "",
  },
  allocated_leaves: {
    en: "Allocated Leaves",
    az: "",
    ru: "",
  },
  allocated_leaves_placeholder: {
    en: "Please enter allocated leaves for employee.",
    az: "",
    ru: "",
  },
  add_unused_leaves: {
    en: "Add unused leaves from previous allocations.",
    az: "",
    ru: "",
  },
  unused_leaves: {
    en: "Unused Leaves",
    az: "",
    ru: "",
  },
  unused_leaves_placeholder: {
    en: "Please enter employee unused leaves.",
    az: "",
    ru: "",
  },
  create_vacation: {
    en: "Allocate Vacation",
    az: "",
    ru: "",
  },
  create_vacation_success: {
    en: "Vacation Allocation Created Successfully",
    az: "",
    ru: "",
  },
  update_vacation: {
    en: "Update Vacation Allocation",
    az: "",
    ru: "",
  },
  update_vacation_success: {
    en: "Vacation Allocation Updated Successfully",
    az: "",
    ru: "",
  },
};

export const request_vacation_translation = {
  request_vacation_heading: {
    en: "Request Leave",
    az: "",
    ru: "",
  },
  vacation_details_heading: {
    en: "Leave Details",
    az: "",
    ru: "",
  },
  employee_info: {
    en: "Employee Details",
    az: "",
    ru: "",
  },
  application_number: {
    en: "Application #",
    az: "",
    ru: "",
  },
  employee_no: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  name: {
    en: "Name",
    az: "",
    ru: "",
  },
  email: {
    en: "Email",
    az: "",
    ru: "",
  },
  phone: {
    en: "Phone",
    az: "",
    ru: "",
  },
  location: {
    en: "Location",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  designation: {
    en: "Designation",
    az: "",
    ru: "",
  },
  line_manager: {
    en: "Line Manager",
    az: "",
    ru: "",
  },
  vacation_info: {
    en: "Leave Details",
    az: "",
    ru: "",
  },
  leave_type: {
    en: "Leave Type",
    az: "",
    ru: "",
  },
  leave_type_placeholder: {
    en: "Please select your leave type.",
    az: "",
    ru: "",
  },
  paid_or_unpaid: {
    en: "Paid or Unpaid",
    az: "",
    ru: "",
  },
  paid_or_unpaid_placeholder: {
    en: "Please select your leave is paid or unpaid.",
    az: "",
    ru: "",
  },
  reason_for_leave: {
    en: "Reason",
    az: "",
    ru: "",
  },
  reason_for_leave_placeholder: {
    en: "Please enter your leave reason.",
    az: "",
    ru: "",
  },
  total_days: {
    en: "Total Days",
    az: "",
    ru: "",
  },
  total_days_placeholder: {
    en: "Please select start and end date for total days.",
    az: "",
    ru: "",
  },
  vacation_from_date: {
    en: "Start Date",
    az: "",
    ru: "",
  },
  vacation_from_date_placeholder: {
    en: "Please enter your leave start date.",
    az: "",
    ru: "",
  },
  vacation_to_date: {
    en: "End Date",
    az: "",
    ru: "",
  },
  vacation_to_date_placeholder: {
    en: "Please enter your leave end date.",
    az: "",
    ru: "",
  },
  vacation_from_invalid_date: {
    en: "Please enter valid vacation start and end date.",
    az: "",
    ru: "",
  },
  request_leave: {
    en: "Request Leave",
    az: "",
    ru: "",
  },
  request_leave_success: {
    en: "Leave Requested Successfully",
    az: "",
    ru: "",
  },
  leave_status_update_successfully: {
    en: "Leave Status Updated Successully",
    az: "",
    ru: "",
  },
  approve: {
    en: "Approve Leave",
    az: "",
    ru: "",
  },
  reject: {
    en: "Reject Leave",
    az: "",
    ru: "",
  },
};

export const leaves_register_translation = {
  leaves_heading: {
    en: "Leaves",
    az: "",
    ru: "",
  },
  search_by_leaves: {
    en: "Search By Leave Details",
    az: "",
    ru: "",
  },
  application_number: {
    en: "Application #",
    az: "",
    ru: "",
  },
  employee_code: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_name: {
    en: "Employee Name",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  designation: {
    en: "Designation",
    az: "",
    ru: "",
  },
  leave_type: {
    en: "Leave Type",
    az: "",
    ru: "",
  },
  paid_or_unpaid: {
    en: "Paid or Unpaid",
    az: "",
    ru: "",
  },
  leave_date: {
    en: "Leave Date",
    az: "",
    ru: "",
  },
  leave_date_from: {
    en: "Leave Date From",
    az: "",
    ru: "",
  },
  leave_date_to: {
    en: "Leave Date To",
    az: "",
    ru: "",
  },
  total_days: {
    en: "Total Days",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  modified_by: {
    en: "Modified By",
    az: "",
    ru: "",
  },
};

export const payroll_register_translation = {
  payroll_heading: {
    en: "Payroll",
    az: "",
    ru: "",
  },
  search_by_payroll: {
    en: "Search By Payroll Details",
    az: "",
    ru: "",
  },
  serial_number: {
    en: "S. No.",
    az: "",
    ru: "",
  },
  payroll_month: {
    en: "Payroll Month",
    az: "",
    ru: "",
  },
  employee_code: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_name: {
    en: "Employee Name",
    az: "",
    ru: "",
  },
  fin: {
    en: "FIN",
    az: "",
    ru: "",
  },
  position: {
    en: "Position",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  salary: {
    en: "Salary",
    az: "",
    ru: "",
  },
  shift: {
    en: "Shift",
    az: "",
    ru: "",
  },
  hours: {
    en: "Hours",
    az: "",
    ru: "",
  },
  work_days: {
    en: "Work Days",
    az: "",
    ru: "",
  },
  per_hour_salary: {
    en: "Per 1 Hour Salary",
    az: "",
    ru: "",
  },
  regular_days_amount: {
    en: "Regular Days Amount",
    az: "",
    ru: "",
  },
  overtime_for_regular: {
    en: "Overtime for Regular",
    az: "",
    ru: "",
  },
  overtime_for_5x: {
    en: "Overtime for 5x",
    az: "",
    ru: "",
  },
  overtime_salary: {
    en: "Overtime Salary",
    az: "",
    ru: "",
  },
  vacation_amount: {
    en: "Vacation Amount",
    az: "",
    ru: "",
  },
  vacation_days: {
    en: "Vacation Days",
    az: "",
    ru: "",
  },
  vacation_salary: {
    en: "Vacation Salary",
    az: "",
    ru: "",
  },
  sick_leave: {
    en: "Sick Leave",
    az: "",
    ru: "",
  },
  bonus: {
    en: "Bonus",
    az: "",
    ru: "",
  },
  gross_salary: {
    en: "Gross Salary",
    az: "",
    ru: "",
  },
  deduction: {
    en: "Deduction",
    az: "",
    ru: "",
  },
  net_pay_salary: {
    en: "Net Pay Salary",
    az: "",
    ru: "",
  },
  card_salary: {
    en: "Card Salary",
    az: "",
    ru: "",
  },
  cash_salary: {
    en: "Cash Salary",
    az: "",
    ru: "",
  },
  loan: {
    en: "Loan",
    az: "",
    ru: "",
  },
  location: {
    en: "Location",
    az: "",
    ru: "",
  },
};

export const vacations_report_translation = {
  vacations_report_heading: {
    en: "Employees Vacation Report",
    az: "",
    ru: "",
  },
  search_by_vacations: {
    en: "Search By Employee & Vacation Details",
    az: "",
    ru: "",
  },
  serial_number: {
    en: "S. No.",
    az: "",
    ru: "",
  },
  employee_code: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_name: {
    en: "Employee Name",
    az: "",
    ru: "",
  },
  fin: {
    en: "FIN",
    az: "",
    ru: "",
  },
  position: {
    en: "Position",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  location: {
    en: "Location",
    az: "",
    ru: "",
  },
  birthdate: {
    en: "Birthdate",
    az: "",
    ru: "",
  },
  age: {
    en: "Employee Age",
    az: "",
    ru: "",
  },
  employee_vacation_type: {
    en: "Employee Vacation Type",
    az: "",
    ru: "",
  },
  disability: {
    en: "Disability",
    az: "",
    ru: "",
  },
  monthly_vacation_days: {
    en: "Monthly Vacation Days",
    az: "",
    ru: "",
  },
  start_date: {
    en: "Start Date",
    az: "",
    ru: "",
  },
  today: {
    en: "Today",
    az: "",
    ru: "",
  },
  work_experience: {
    en: "Work Experience",
    az: "",
    ru: "",
  },
  work_experience_month: {
    en: "Work Experience (month)",
    az: "",
    ru: "",
  },
  total_vacation_days: {
    en: "Total Vacation Days (tiil end of this year)",
    az: "",
    ru: "",
  },
  last_year_total_used_vacation_days: {
    en: "Last Years Total Used Vacation Days",
    az: "",
    ru: "",
  },
  current_year_total_used_vacation_days: {
    en: "Total Used Vacation Days",
    az: "",
    ru: "",
  },
  total_used_vacations: {
    en: "Total Used Vacation Days",
    az: "",
    ru: "",
  },
  total_remained_vacation_days: {
    en: "Total Remained Vacation Days",
    az: "",
    ru: "",
  },
};

/* REPORTING & ANALYTICS TRANSLATION */

export const consolidate_sales_translation = {
  consolidate_sales: {
    en: "Consolidate Sales Report",
    az: "",
    ru: "",
  },
  total_sales: {
    en: "Total Sales",
    az: "",
    ru: "",
  },
  total_orders: {
    en: "Total Orders",
    az: "",
    ru: "",
  },
  avg_ticket: {
    en: "Avg. Ticket",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  orders: {
    en: "Orders",
    az: "",
    ru: "",
  },
  sales: {
    en: "Sales",
    az: "",
    ru: "",
  },
};

export const channel_sales_translation = {
  channel_sales: {
    en: "Channel Sales Report",
    az: "",
    ru: "",
  },
  sales_date: {
    en: "Sales Date",
    az: "",
    ru: "",
  },
  week_day: {
    en: "Week Day",
    az: "",
    ru: "",
  },
  week: {
    en: "Week",
    az: "",
    ru: "",
  },
  total: {
    en: "Total",
    az: "",
    ru: "",
  },
  orders: {
    en: "Orders",
    az: "",
    ru: "",
  },
  sales: {
    en: "Sales",
    az: "",
    ru: "",
  },
  avg_ticket: {
    en: "Avg. Ticket Price",
    az: "",
    ru: "",
  },
  orders_percentage: {
    en: "% of Orders",
    az: "",
    ru: "",
  },
  sales_percentage: {
    en: "% of Sales",
    az: "",
    ru: "",
  },
  online: {
    en: "Online",
    az: "",
    ru: "",
  },
};

export const consolidate_pulse_inventory_translation = {
  consolidate_pulse_inventory: {
    en: "Consolidate Pulse Inventory Report",
    az: "",
    ru: "",
  },
  items: {
    en: "Items",
    az: "",
    ru: "",
  },
  items_placeholder: {
    en: "Please select items.",
    az: "",
    ru: "",
  },
};

export const consolidate_cogs_translation = {
  consolidate_cogs: {
    en: "Consolidate Cost of Goods Sold Report",
    az: "",
    ru: "",
  },
  actual_food_cost: {
    en: "Actual Food Cost",
    az: "",
    ru: "",
  },
  ideal_food_cost: {
    en: "Ideal Food Cost",
    az: "",
    ru: "",
  },
  variance: {
    en: "Variance",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  actual_food_percentage: {
    en: "Actual Food %",
    az: "",
    ru: "",
  },
  ideal_food_percentage: {
    en: "Ideal Food %",
    az: "",
    ru: "",
  },
  actual_ideal_food_variance: {
    en: "Actual & Ideal Food Variance %",
    az: "",
    ru: "",
  },
};

/* SETTINGS TRANSLATION */

export const users_register_translation = {
  all_users_heading: {
    en: "Users",
    az: "",
    ru: "",
  },
  name: {
    en: "Name",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  two_factor_auth: {
    en: "Two Factor Auth",
    az: "",
    ru: "",
  },
  location: {
    en: "Location",
    az: "",
    ru: "",
  },
  is_manager: {
    en: "Is Manager?",
    az: "",
    ru: "",
  },
  line_manager: {
    en: "Line Manager",
    az: "",
    ru: "",
  },
  role: {
    en: "Role",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  create_user: {
    en: "Create User",
    az: "",
    ru: "",
  },
  select_criteria_for_user_search: {
    en: "Please select search criteria for filtered result.",
    az: "",
    ru: "",
  },
};

export const users_register_search_translation = {
  search_by_user: {
    en: "Search By Users",
    az: "",
    ru: "",
  },
  name: {
    en: "Name",
    az: "",
    ru: "",
  },
  email: {
    en: "Email",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  user_name: {
    en: "User Name",
    az: "",
    ru: "",
  },
  phone: {
    en: "Phone",
    az: "",
    ru: "",
  },
  is_employee: {
    en: "Is Employee?",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  designation: {
    en: "Designation",
    az: "",
    ru: "",
  },
  employee_type: {
    en: "Employee Type",
    az: "",
    ru: "",
  },
  is_manager: {
    en: "Is Manager?",
    az: "",
    ru: "",
  },
  line_manager: {
    en: "Line Manager",
    az: "",
    ru: "",
  },
  location: {
    en: "Location",
    az: "",
    ru: "",
  },
  name_search: {
    en: "Name",
    az: "",
    ru: "",
  },
  email_search: {
    en: "Email",
    az: "",
    ru: "",
  },
  status_search: {
    en: "Status",
    az: "",
    ru: "",
  },
  user_name_search: {
    en: "User Name",
    az: "",
    ru: "",
  },
  phone_search: {
    en: "Phone",
    az: "",
    ru: "",
  },
  is_employee_search: {
    en: "Is Employee?",
    az: "",
    ru: "",
  },
  department_search: {
    en: "Department Name",
    az: "",
    ru: "",
  },
  designation_search: {
    en: "Designation Name",
    az: "",
    ru: "",
  },
  employee_type_search: {
    en: "Employee Type",
    az: "",
    ru: "",
  },
  is_manager_search: {
    en: "Is Manager?",
    az: "",
    ru: "",
  },
  line_manager_search: {
    en: "Line Manager",
    az: "",
    ru: "",
  },
  location_search: {
    en: "Location",
    az: "",
    ru: "",
  },
};

export const users_details_translation = {
  create_user_heading: {
    en: "Create User",
    az: "",
    ru: "",
  },
  update_user_heading: {
    en: "User Detail",
    az: "",
    ru: "",
  },
  general_information: {
    en: "General Information",
    az: "",
    ru: "",
  },
  assign_roles: {
    en: "Roles",
    az: "",
    ru: "",
  },
  assign_stores: {
    en: "Locations",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  status_placeholder: {
    en: "Please select user status.",
    az: "",
    ru: "",
  },
  first_name: {
    en: "First Name",
    az: "",
    ru: "",
  },
  first_name_palceholder: {
    en: "Please enter user first name.",
    az: "",
    ru: "",
  },
  last_name: {
    en: "Last Name",
    az: "",
    ru: "",
  },
  last_name_placeholder: {
    en: "Please enter user last name.",
    az: "",
    ru: "",
  },
  user_name: {
    en: "User Name",
    az: "",
    ru: "",
  },
  user_name_placeholder: {
    en: "Please enter username.",
    az: "",
    ru: "",
  },
  email: {
    en: "Email",
    az: "",
    ru: "",
  },
  email_placeholder: {
    en: "Please enter user email.",
    az: "",
    ru: "",
  },
  phone: {
    en: "Phone",
    az: "",
    ru: "",
  },
  phone_placeholder: {
    en: "Please enter user phone number.",
    az: "",
    ru: "",
  },
  is_employee: {
    en: "Is Employee",
    az: "",
    ru: "",
  },
  is_employee_placeholder: {
    en: "Please select user is employee or not.",
    az: "",
    ru: "",
  },
  two_factor_authentication: {
    en: "Two Factor Authentication",
    az: "",
    ru: "",
  },
  language: {
    en: "Language",
    az: "",
    ru: "",
  },
  current_sign_in: {
    en: "Current Sign in",
    az: "",
    ru: "",
  },
  lock_account: {
    en: "Lock Account",
    az: "",
    ru: "",
  },
  can_change_password: {
    en: "Can Change Password",
    az: "",
    ru: "",
  },
  can_login_from_mobile: {
    en: "Can Login From Mobile",
    az: "",
    ru: "",
  },
  create_user: {
    en: "Create User",
    az: "",
    ru: "",
  },
  update_user: {
    en: "Update User",
    az: "",
    ru: "",
  },
  employee_details: {
    en: "Employee Details",
    az: "",
    ru: "",
  },
  link_employee: {
    en: "Link Employee",
    az: "",
    ru: "",
  },
  link_employee_placeholder: {
    en: "Please select employee to linked.",
    az: "",
    ru: "",
  },
  department: {
    en: "Department",
    az: "",
    ru: "",
  },
  department_placeholder: {
    en: "Please select department.",
    az: "",
    ru: "",
  },
  designation: {
    en: "Designation",
    az: "",
    ru: "",
  },
  designation_placeholder: {
    en: "Please select designation.",
    az: "",
    ru: "",
  },
  employee_type: {
    en: "Employee Type",
    az: "",
    ru: "",
  },
  employee_type_placeholder: {
    en: "Please select employee type.",
    az: "",
    ru: "",
  },
  is_manager: {
    en: "Is Manager",
    az: "",
    ru: "",
  },
  line_manager: {
    en: "Line Manager",
    az: "",
    ru: "",
  },
  line_manager_placeholder: {
    en: "Please select line manager.",
    az: "",
    ru: "",
  },
  select_role: {
    en: "Select Role",
    az: "",
    ru: "",
  },
  select_role_placeholder: {
    en: "Please select role.",
    az: "",
    ru: "",
  },
  assign_role: {
    en: "Assign Role",
    az: "",
    ru: "",
  },
  delete_user: {
    en: "Delete User",
    az: "",
    ru: "",
  },
  validate_email: {
    en: "Please validate email.",
    az: "",
    ru: "",
  },
  user_created_success: {
    en: "User created successfully.",
    az: "",
    ru: "",
  },
  user_updated_success: {
    en: "User updated successfully.",
    az: "",
    ru: "",
  },
  user_deleted_success: {
    en: "User deleted successfully.",
    az: "",
    ru: "",
  },
  role_name: {
    en: "Role Name",
    az: "",
    ru: "",
  },
  role_description: {
    en: "Role Description",
    az: "",
    ru: "",
  },
  select_stores: {
    en: "Select Store",
    az: "",
    ru: "",
  },
  select_stores_placeholder: {
    en: "Please select store.",
    az: "",
    ru: "",
  },
  store_code: {
    en: "Store Code",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  select_warehouse: {
    en: "Select Warehouse",
    az: "",
    ru: "",
  },
  select_warehouse_placeholder: {
    en: "Please select warehouse.",
    az: "",
    ru: "",
  },
  warehouse_code: {
    en: "Warehouse Code",
    az: "",
    ru: "",
  },
  warehouse_name: {
    en: "Warehouse Name",
    az: "",
    ru: "",
  },
  password: {
    en: "Password",
    az: "",
    ru: "",
  },
  password_placeholder: {
    en: "Please enter password for this user.",
    ru: "",
    az: "",
  },
  confirm_password: {
    en: "Confirm Password",
    az: "",
    ru: "",
  },
  confirm_password_placeholder: {
    en: "Please enter password again for confirmation.",
    ru: "",
    az: "",
  },
  password_not_matched: {
    en: "Password & Confirm Password are not matched.",
    az: "",
    ru: "",
  },
  reset_password: {
    en: "Reset Password",
    az: "",
    ru: "",
  },
  reset_password_message: {
    en: "If you want to reset this user password, then you can reset it from here.",
    az: "",
    ru: "",
  },
  reset_password_successfully: {
    en: "Your password has been reset successfully.",
    az: "",
    ru: "",
  },
};

export const roles_register_translation = {
  all_roles: {
    en: "Roles",
    az: "",
    ru: "",
  },
  role_name: {
    en: "Role Name",
    az: "",
    ru: "",
  },
  role_description: {
    en: "Role Description",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  create_role: {
    en: "Create Role",
    az: "",
    ru: "",
  },
};

export const roles_register_search_translation = {
  search_by_role: {
    en: "Search By Roles",
    az: "",
    ru: "",
  },
  role_name: {
    en: "Name",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  modules: {
    en: "Modules",
    az: "",
    ru: "",
  },
  user_name: {
    en: "User Name",
    az: "",
    ru: "",
  },
  role_name_search: {
    en: "Role Name",
    az: "",
    ru: "",
  },
  role_status_search: {
    en: "Role Status",
    az: "",
    ru: "",
  },
  modules_search: {
    en: "Modules",
    az: "",
    ru: "",
  },
  user_name_search: {
    en: "User Name",
    az: "",
    ru: "",
  },
};

export const roles_details_translation = {
  create_role_heading: {
    en: "Create Role",
    az: "",
    ru: "",
  },
  update_role_heading: {
    en: "Role Detail",
    az: "",
    ru: "",
  },
  role_name: {
    en: "Role Name",
    az: "",
    ru: "",
  },
  role_name_placeholder: {
    en: "Please enter role name.",
    az: "",
    ru: "",
  },
  role_description: {
    en: "Role Description",
    az: "",
    ru: "",
  },
  role_description_placeholder: {
    en: "Please enter role description.",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  status_placeholder: {
    en: "Please select status.",
    az: "",
    ru: "",
  },
  create_role: {
    en: "Create Role",
    az: "",
    ru: "",
  },
  update_role: {
    en: "Update Role",
    az: "",
    ru: "",
  },
  delete_role: {
    en: "Delete Role",
    az: "",
    ru: "",
  },
  permissions: {
    en: "Permissions",
    az: "",
    ru: "",
  },
  assigned_users: {
    en: "Assigned Users",
    az: "",
    ru: "",
  },
  module: {
    en: "Module",
    az: "",
    ru: "",
  },
  full_access: {
    en: "Full Access",
    az: "",
    ru: "",
  },
  view: {
    en: "View",
    az: "",
    ru: "",
  },
  create: {
    en: "Create",
    az: "",
    ru: "",
  },
  edit: {
    en: "Edit",
    az: "",
    ru: "",
  },
  delete: {
    en: "Delete",
    az: "",
    ru: "",
  },
  download: {
    en: "Download",
    az: "",
    ru: "",
  },
  print: {
    en: "Print",
    az: "",
    ru: "",
  },
  create_role_success: {
    en: "Role created successfully.",
    az: "",
    ru: "",
  },
  update_role_success: {
    en: "Role updated successfully.",
    az: "",
    ru: "",
  },
  serial_number: {
    en: "S. No.",
    az: "",
    ru: "",
  },
  user_name: {
    en: "User Name",
    az: "",
    ru: "",
  },
  user_email: {
    en: "User Email",
    az: "",
    ru: "",
  },
};

export const stores_register_translation = {
  stores_heading: {
    en: "Stores",
    az: "",
    ru: "",
  },
  store_code: {
    en: "Store Code",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  ip_address: {
    en: "IP Address",
    az: "",
    ru: "",
  },
  create_store: {
    en: "Create Store",
    az: "",
    ru: "",
  },
};

export const stores_register_search_translation = {
  search_by_stores: {
    en: "Search By Stores",
    az: "",
    ru: "",
  },
  store_code: {
    en: "Store Code",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  ip_address: {
    en: "IP Address",
    az: "",
    ru: "",
  },
  store_code_search: {
    en: "Store Code",
    az: "",
    ru: "",
  },
  store_name_search: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  store_status_search: {
    en: "Status",
    az: "",
    ru: "",
  },
  ip_address_search: {
    en: "IP Address",
    az: "",
    ru: "",
  },
};

export const stores_details_translation = {
  create_store_heading: {
    en: "Create Store",
    az: "",
    ru: "",
  },
  update_store_heading: {
    en: "Store Detail",
    az: "",
    ru: "",
  },
  general_information: {
    en: "General Information",
    az: "",
    ru: "",
  },
  get_records: {
    en: "Get Records",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  status_placeholder: {
    en: "Please select status.",
    az: "",
    ru: "",
  },
  store_code: {
    en: "Store Code",
    az: "",
    ru: "",
  },
  store_code_placeholder: {
    en: "Please enter store code.",
    az: "",
    ru: "",
  },
  store_name: {
    en: "Store Name",
    az: "",
    ru: "",
  },
  store_name_placeholder: {
    en: "Please enter store name.",
    az: "",
    ru: "",
  },
  store_ip_address: {
    en: "Store IP Address",
    az: "",
    ru: "",
  },
  store_ip_address_placeholder: {
    en: "Please enter store ip address.",
    az: "",
    ru: "",
  },
  start_date: {
    en: "Start Date",
    az: "",
    ru: "",
  },
  end_date: {
    en: "End Date",
    az: "",
    ru: "",
  },
  last_searched_date: {
    en: "Last Searched Date",
    az: "",
    ru: "",
  },
  searched_updated_at: {
    en: "Searched Updated At",
    az: "",
    ru: "",
  },
  last_updated_at: {
    en: "Last Updated At",
    az: "",
    ru: "",
  },
  create_store: {
    en: "Create Store",
    az: "",
    ru: "",
  },
  update_store: {
    en: "Update Store",
    az: "",
    ru: "",
  },
  max_ten_days_filter: {
    en: "You can select maximum 10 days in date filteration",
    az: "",
    ru: "",
  },
  start_date_filter: {
    en: "Start date cannot be greater than end date.",
    az: "",
    ru: "",
  },
  end_date_filter: {
    en: "End date cannot be less than start date.",
    az: "",
    ru: "",
  },
  create_store_success: {
    en: "Store created successfully.",
    az: "",
    ru: "",
  },
  update_store_success: {
    en: "Store updated successfully.",
    az: "",
    ru: "",
  },
};

export const store_groups_register_translation = {
  store_groups_heading: {
    en: "Store Groups",
    az: "",
    ru: "",
  },
  store_group_name: {
    en: "Store Group Name",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  modified_at: {
    en: "Modified At",
    az: "",
    ru: "",
  },
  create_store_group: {
    en: "Create Store Group",
    az: "",
    ru: "",
  },
};

export const store_groups_details_translation = {
  create_store_group_heading: {
    en: "Create Store Group",
    az: "",
    ru: "",
  },
  update_store_group_heading: {
    en: "Store Group Detail",
    az: "",
    ru: "",
  },
  store_group_name: {
    en: "Store Group Name",
    az: "",
    ru: "",
  },
  store_group_name_placeholder: {
    en: "Please enter store group name.",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  status_placeholder: {
    en: "Please select status.",
    az: "",
    ru: "",
  },
  stores: {
    en: "Stores",
    az: "",
    ru: "",
  },
  stores_placeholder: {
    en: "Please select stores.",
    az: "",
    ru: "",
  },
  create_store_group: {
    en: "Create Store Group",
    az: "",
    ru: "",
  },
  update_store_group: {
    en: "Update Store Group",
    az: "",
    ru: "",
  },
  delete_store_group: {
    en: "Delete Store Group",
    az: "",
    ru: "",
  },
  create_store_group_success: {
    en: "Store Group created successfully.",
    az: "",
    ru: "",
  },
  update_store_group_success: {
    en: "Store Group updated successfully.",
    az: "",
    ru: "",
  },
};

export const workflow_register_translation = {
  workflow_heading: {
    en: "Workflows",
    az: "",
    ru: "",
  },
  workflow_name: {
    en: "Workflow Name",
    az: "",
    ru: "",
  },
  document_type: {
    en: "Document Type",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  start_date: {
    en: "Start Date",
    az: "",
    ru: "",
  },
  end_date: {
    en: "End Date",
    az: "",
    ru: "",
  },
  created_at: {
    en: "Created At",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  create_workflow: {
    en: "Create Workflow",
    az: "",
    ru: "",
  },
};

export const workflow_details_translation = {
  create_workflow_heading: {
    en: "Create Workflow",
    az: "",
    ru: "",
  },
  update_workflow_heading: {
    en: "Workflow Detail",
    az: "",
    ru: "",
  },
  name: {
    en: "Name",
    az: "",
    ru: "",
  },
  name_placeholder: {
    en: "Please enter workflow name.",
    az: "",
    ru: "",
  },
  document_type: {
    en: "Document Type",
    az: "",
    ru: "",
  },
  document_type_placeholder: {
    en: "Please select document type.",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  status_placeholder: {
    en: "Please select status.",
    az: "",
    ru: "",
  },
  start_date: {
    en: "Start Date",
    az: "",
    ru: "",
  },
  start_date_placeholder: {
    en: "Please select start date.",
    az: "",
    ru: "",
  },
  end_date: {
    en: "End Date",
    az: "",
    ru: "",
  },
  end_date_placeholder: {
    en: "Please select end date.",
    az: "",
    ru: "",
  },
  send_email_alert: {
    en: "Send Email Alert",
    az: "",
    ru: "",
  },
  send_notification_alert: {
    en: "Send Notification Alert",
    az: "",
    ru: "",
  },
  create_workflow: {
    en: "Create Workflow",
    az: "",
    ru: "",
  },
  update_workflow: {
    en: "Update Workflow",
    az: "",
    ru: "",
  },
  store_workflow: {
    en: "Store Workflow",
    az: "",
    ru: "",
  },
  office_workflow: {
    en: "Office Workflow",
    az: "",
    ru: "",
  },
  customized: {
    en: "Customized",
    az: "",
    ru: "",
  },
  approval_level: {
    en: "Approval Level",
    az: "",
    ru: "",
  },
  approval_level_placeholder: {
    en: "Please select approval level.",
    az: "",
    ru: "",
  },
  states: {
    en: "States",
    az: "",
    ru: "",
  },
  serial_number: {
    en: "S. No.",
    az: "",
    ru: "",
  },
  approval_state: {
    en: "Approval State",
    az: "",
    ru: "",
  },
  approval_state_placeholder: {
    en: "Please select approval state.",
    az: "",
    ru: "",
  },
  document_status_placeholder: {
    en: "Please select document status.",
    az: "",
    ru: "",
  },
  modify_by: {
    en: "Modify By",
    az: "",
    ru: "",
  },
  modify_by_placeholder: {
    en: "Please select modified by.",
    az: "",
    ru: "",
  },
  add_row: {
    en: "Add Row",
    az: "",
    ru: "",
  },
  transition_rules: {
    en: "Transition Rules",
    az: "",
    ru: "",
  },
  action: {
    en: "Action",
    az: "",
    ru: "",
  },
  action_placeholder: {
    en: "Please select action.",
    az: "",
    ru: "",
  },
  next_approval_state: {
    en: "Next Approval State",
    az: "",
    ru: "",
  },
  next_approval_state_placeholder: {
    en: "Please select next approval state.",
    az: "",
    ru: "",
  },
  conditions: {
    en: "Conditions",
    az: "",
    ru: "",
  },
  form_field: {
    en: "Form Field",
    az: "",
    ru: "",
  },
  form_field_placeholder: {
    en: "Please select form field.",
    az: "",
    ru: "",
  },
  conditions_placeholder: {
    en: "Please select condition.",
    az: "",
    ru: "",
  },
  skip_from: {
    en: "Skip From",
    az: "",
    ru: "",
  },
  skip_from_placeholder: {
    en: "Number from where you want to skip.",
    az: "",
    ru: "",
  },
  start_date_filter: {
    en: "Start date cannot be greater than end date.",
    az: "",
    ru: "",
  },
  end_date_filter: {
    en: "End date cannot be less than start date.",
    az: "",
    ru: "",
  },
  workflow_name_required: {
    en: "Please enter workflow name.",
    az: "",
    ru: "",
  },
  document_type_required: {
    en: "Please select document type.",
    az: "",
    ru: "",
  },
  status_required: {
    en: "Please select status.",
    az: "",
    ru: "",
  },
  start_date_required: {
    en: "Please select start date.",
    az: "",
    ru: "",
  },
  end_date_required: {
    en: "Please select end date.",
    az: "",
    ru: "",
  },
  customize_required: {
    en: "Please select workflow customized or not.",
    az: "",
    ru: "",
  },
  workflow_created_successfully: {
    en: "Workflow created successfully.",
    az: "",
    ru: "",
  },
};

export const workflow_states_register_translation = {
  workflow_states: {
    en: "Workflow States",
    az: "",
    ru: "",
  },
  state_types: {
    en: "State Type",
    az: "",
    ru: "",
  },
  created_on: {
    en: "Created On",
    az: "",
    ru: "",
  },
  updated_on: {
    en: "Updated On",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  create_workflow_state: {
    en: "Create Workflow State",
    az: "",
    ru: "",
  },
};

export const workflow_states_details_translation = {
  create_workflow_state_heading: {
    en: "Create Workflow State",
    az: "",
    ru: "",
  },
  update_workflow_state_heading: {
    en: "Workflow State Detail",
    az: "",
    ru: "",
  },
  workflow_name: {
    en: "Name",
    az: "",
    ru: "",
  },
  workflow_name_placeholder: {
    en: "Please enter workflow state name.",
    az: "",
    ru: "",
  },
  create_workflow_state: {
    en: "Create Workflow State",
    az: "",
    ru: "",
  },
  update_workflow_state: {
    en: "Update Workflow State",
    az: "",
    ru: "",
  },
  workflow_state_create_success: {
    en: "Workflow State created successfuly.",
    az: "",
    ru: "",
  },
  workflow_state_update_success: {
    en: "Workflow State updated successfuly.",
    az: "",
    ru: "",
  },
};

export const workflow_action_register_translation = {
  create_workflow_action_heading: {
    en: "Workflow Actions",
    az: "",
    ru: "",
  },
  action_name: {
    en: "Action Name",
    az: "",
    ru: "",
  },
  created_on: {
    en: "Created On",
    az: "",
    ru: "",
  },
  updated_on: {
    en: "Updated On",
    az: "",
    ru: "",
  },
  created_by: {
    en: "Created By",
    az: "",
    ru: "",
  },
  create_workflow_action: {
    en: "Create Workflow Action",
    az: "",
    ru: "",
  },
};

export const workflow_action_details_translation = {
  create_workflow_action_heading: {
    en: "Create Workflow Action",
    az: "",
    ru: "",
  },
  update_workflow_action_heading: {
    en: "Workflow Action Detail",
    az: "",
    ru: "",
  },
  name: {
    en: "Action Name",
    az: "",
    ru: "",
  },
  name_placeholder: {
    en: "Please enter workflow action name.",
    az: "",
    ru: "",
  },
  create_workflow_action: {
    en: "Create Workflow Action",
    az: "",
    ru: "",
  },
  update_workflow_action: {
    en: "Update Workflow Action",
    az: "",
    ru: "",
  },
  create_workflow_action_success: {
    en: "Workflow Action created successfully.",
    az: "",
    ru: "",
  },
  update_workflow_action_success: {
    en: "Workflow Action updated successfully.",
    az: "",
    ru: "",
  },
};

/* LOGIN & PROFILE TRANSLATION */

export const login_translation = {
  welcome: {
    en: "Welcome, Please Login Into Your Account",
    az: "",
    ru: "",
  },
  verify_otp_heading: {
    en: "Welcome, Please Verify Your Account",
    az: "",
    ru: "",
  },
  email: {
    en: "User Name",
    az: "",
    ru: "",
  },
  email_placeholder: {
    en: "Please enter your username.",
    az: "",
    ru: "",
  },
  password: {
    en: "Password",
    az: "",
    ru: "",
  },
  password_placeholder: {
    en: "Please enter your password.",
    az: "",
    ru: "",
  },
  incorrect_email_password: {
    en: "Incorrect email or password.",
    az: "",
    ru: "",
  },
  cannot_able_to_login: {
    en: "We can't able to login your user right now.",
    az: "",
    ru: "",
  },
  forgot_password: {
    en: "Forgot Password?",
    az: "",
    ru: "",
  },
  login: {
    en: "Login",
    az: "",
    ru: "",
  },
  verify_otp: {
    en: "Verify OTP",
    az: "",
    ru: "",
  },
  otp_caption: {
    en: "Enter the OTP Code sent on ",
    az: "",
    ru: "",
  },
  otp_not_exists_or_expires: {
    en: "OTP not exists or expires.",
    az: "",
    ru: "",
  },
  cannot_able_to_verify_otp: {
    en: "We can't able to verify your user right now.",
    az: "",
    ru: "",
  },
  otp_validation: {
    en: "Please enter OTP sent on your phone.",
    az: "",
    ru: "",
  },
  not_this_user: {
    en: "Not this user?",
    az: "",
    ru: "",
  },
  resend_otp: {
    en: "Resend OTP",
    az: "",
    ru: "",
  },
  resend_otp_in: {
    en: "Resend OTP in",
    az: "",
    ru: "",
  },
  cannot_able_to_resend_otp: {
    en: "We can't able to resend otp right now.",
    az: "",
    ru: "",
  },
  otp_sent_successfully: {
    en: "OTP sent successfully on your registered mobile number.",
    az: "",
    ru: "",
  },
};

export const forgot_password_translation = {
  welcome: {
    en: "Welcome, Recover Your Account",
    az: "",
    ru: "",
  },
  email: {
    en: "Email",
    az: "",
    ru: "",
  },
  email_placeholder: {
    en: "Please enter your email.",
    az: "",
    ru: "",
  },
  sign_in: {
    en: "Sign in?",
    az: "",
    ru: "",
  },
  send_request: {
    en: "Send Request",
    az: "",
    ru: "",
  },
  email_sent_successfully: {
    en: "Email has been sent successfully.",
    az: "",
    ru: "",
  },
};

export const user_profile_translation = {
  user_profile_heading: {
    en: "Profile",
    az: "",
    ru: "",
  },
  status: {
    en: "Status",
    az: "",
    ru: "",
  },
  status_placeholder: {
    en: "Please select user status.",
    az: "",
    ru: "",
  },
  first_name: {
    en: "First Name",
    az: "",
    ru: "",
  },
  first_name_placeholder: {
    en: "Please enter your first name.",
    az: "",
    ru: "",
  },
  last_name: {
    en: "Last Name",
    az: "",
    ru: "",
  },
  last_name_placeholder: {
    en: "Please enter your last name.",
    az: "",
    ru: "",
  },
  user_name: {
    en: "User Name",
    az: "",
    ru: "",
  },
  user_name_placeholder: {
    en: "Please enter your username.",
    az: "",
    ru: "",
  },
  email: {
    en: "Email",
    az: "",
    ru: "",
  },
  email_placeholder: {
    en: "Please enter your email.",
    az: "",
    ru: "",
  },
  phone: {
    en: "Phone",
    az: "",
    ru: "",
  },
  phone_placeholder: {
    en: "Please enter your phone.",
    az: "",
    ru: "",
  },
  two_factor_authentication: {
    en: "Two Factor Authentication",
    az: "",
    ru: "",
  },
  update_profile: {
    en: "Update Employee Pin",
    az: "",
    ru: "",
  },
  reset_password: {
    en: "Change Password",
    az: "",
    ru: "",
  },
  reset_password_message: {
    en: "If you want to change your password, then you can change it from here.",
    az: "",
    ru: "",
  },
  previous_password: {
    en: "Previous Password",
    az: "",
    ru: "",
  },
  previous_password_placeholder: {
    en: "Please enter your previous password.",
    az: "",
    ru: "",
  },
  password: {
    en: "New Password",
    az: "",
    ru: "",
  },
  password_placeholder: {
    en: "Please enter your new password.",
    az: "",
    ru: "",
  },
  password_length_validation: {
    en: "Your password should be atleast 8 characters.",
    az: "",
    ru: "",
  },
  password_validation: {
    en: "Your password must includes both lower and uppercase letters, one number and special character.",
    az: "",
    ru: "",
  },
  confirm_password: {
    en: "Confirm Password",
    ru: "",
    az: "",
  },
  confirm_password_placeholder: {
    en: "Please enter your password again for confirmation.",
    az: "",
    ru: "",
  },
  reset_password_successfully: {
    en: "Your password has been reset successfully.",
    az: "",
    ru: "",
  },
  employee_pin: {
    en: "Employee Pin",
    az: "",
    ru: "",
  },
  employee_pin_placeholder: {
    en: "Please enter your employee pin.",
    az: "",
    ru: "",
  },
  profile_updated: {
    en: "Your employee pin has been updated successfully.",
    az: "",
    ru: "",
  },
  employee_pin_in_number: {
    en: "Employee Pin must be contains number.",
    az: "",
    ru: "",
  },
  employee_no: {
    en: "Employee Code",
    az: "",
    ru: "",
  },
  employee_no_placeholder: {
    en: "Please enter your employee code.",
    az: "",
    ru: "",
  },
  request_vacation: {
    en: "Request Leave",
    az: "",
    ru: "",
  },
};

/* ACTIVITY LOGS & COMMENTS TRANSLATION */

export const activity_logs_translation = {
  activity_logs: {
    en: "Activity Logs",
    az: "",
    ru: "",
  },
  communication: {
    en: "Communication",
    az: "",
    ru: "",
  },
  no_logs_found: {
    en: "No Logs Found",
    az: "",
    ru: "",
  },
  no_communication_found: {
    en: "No Communication Found",
    az: "",
    ru: "",
  },
};

export const comments_translation = {
  comments: {
    en: "Comments",
    az: "",
    ru: "",
  },
  comments_placeholder: {
    en: "Please enter your comment.",
    az: "",
    ru: "",
  },
  add_comment: {
    en: "Add a comment",
    az: "",
    ru: "",
  },
};

/* ERROR PAGES TRANSLATION */

export const error_401_translation = {
  error_401_heading: {
    en: "Error 401",
    az: "",
    ru: "",
  },
  not_authorized: {
    en: "401 Not Authorized",
    az: "",
    ru: "",
  },
  no_permission_message: {
    en: "You don't have permission to access this module.",
    az: "",
    ru: "",
  },
};

export const error_404_translation = {
  error_404_heading: {
    en: "Error 404",
    az: "",
    ru: "",
  },
  page_not_found: {
    en: "404 Page Not Found",
    az: "",
    ru: "",
  },
  no_page_found_message: {
    en: "Sorry, but the requested page is not found.",
    az: "",
    ru: "",
  },
};

/* DATATABLE & SEARCH FILTERS TRANSLATION */

export const datatable_translation = {
  search: {
    en: "Search...",
    az: "",
    ru: "",
  },
  duration: {
    en: "Duration",
    az: "",
    ru: "",
  },
  total: {
    en: "Total",
    az: "",
    ru: "",
  },
  records: {
    en: "Records",
    az: "",
    ru: "",
  },
  serial_number: {
    en: "S. No.",
    az: "",
    ru: "",
  },
  no_columns_found: {
    en: "No columns found",
    az: "",
    ru: "",
  },
  no_records_found: {
    en: "No records found",
    az: "",
    ru: "",
  },
  one_per_page: {
    en: "1 / Page",
    az: "",
    ru: "",
  },
  ten_per_page: {
    en: "10 / Page",
    az: "",
    ru: "",
  },
  fourteen_per_page: {
    en: "14 / Page",
    az: "",
    ru: "",
  },
  twenty_five_per_page: {
    en: "25 / Page",
    az: "",
    ru: "",
  },
  fifty_per_page: {
    en: "50 / Page",
    az: "",
    ru: "",
  },
  hundred_per_page: {
    en: "100 / Page",
    az: "",
    ru: "",
  },
  showing: {
    en: "Showing",
    az: "",
    ru: "",
  },
  of: {
    en: "of",
    az: "",
    ru: "",
  },
};

export const search_filters_translation = {
  start_date: {
    en: "Start Date",
    az: "",
    ru: "",
  },
  end_date: {
    en: "End Date",
    az: "",
    ru: "",
  },
  duration: {
    en: "Duration",
    az: "",
    ru: "",
  },
  time_duration: {
    en: "Please select time duration.",
    az: "",
    ru: "",
  },
  period: {
    en: "Period",
    az: "",
    ru: "",
  },
  time_period: {
    en: "Please select time period.",
    az: "",
    ru: "",
  },
  store: {
    en: "Store",
    az: "",
    ru: "",
  },
  select_stores: {
    en: "Please select stores.",
    az: "",
    ru: "",
  },
  all_time_records: {
    en: "All Time Records",
    az: "",
    ru: "",
  },
  today: {
    en: "Today",
    az: "",
    ru: "",
  },
  this_week: {
    en: "This Week",
    az: "",
    ru: "",
  },
  this_week_to_date: {
    en: "This Week-to-Date",
    az: "",
    ru: "",
  },
  this_month: {
    en: "This Month",
    az: "",
    ru: "",
  },
  this_month_to_date: {
    en: "This Month-to-Date",
    az: "",
    ru: "",
  },
  this_quarter: {
    en: "This Quarter",
    az: "",
    ru: "",
  },
  this_quarter_to_date: {
    en: "This Quarter-to-Date",
    az: "",
    ru: "",
  },
  this_year: {
    en: "This Year",
    az: "",
    ru: "",
  },
  this_year_to_date: {
    en: "This Year-to-Date",
    az: "",
    ru: "",
  },
  last_7_days: {
    en: "Last 7 Days",
    az: "",
    ru: "",
  },
  yesterday: {
    en: "Yesterday",
    az: "",
    ru: "",
  },
  last_week: {
    en: "Last Week",
    az: "",
    ru: "",
  },
  last_week_to_date: {
    en: "Last Week-to-Date",
    az: "",
    ru: "",
  },
  last_month: {
    en: "Last Month",
    az: "",
    ru: "",
  },
  last_month_to_date: {
    en: "Last Month-to-Date",
    az: "",
    ru: "",
  },
  last_quarter: {
    en: "Last Quarter",
    az: "",
    ru: "",
  },
  last_quarter_to_date: {
    en: "Last Quarter-to-Date",
    az: "",
    ru: "",
  },
  last_year: {
    en: "Last Year",
    az: "",
    ru: "",
  },
  last_year_to_date: {
    en: "Last Year-to-Date",
    az: "",
    ru: "",
  },
  custom_date: {
    en: "Custom Date",
    az: "",
    ru: "",
  },
  daily: {
    en: "Daily",
    az: "",
    ru: "",
  },
  weekly: {
    en: "Weekly",
    az: "",
    ru: "",
  },
  monthly: {
    en: "Monthly",
    az: "",
    ru: "",
  },
  yearly: {
    en: "Yearly",
    az: "",
    ru: "",
  },
};

/* CHANNEL NAME TRANSLATION */

export const channel_name_translation = {
  ios: {
    en: "iOS",
    az: "",
    ru: "",
  },
  android: {
    en: "Android",
    az: "",
    ru: "",
  },
  web: {
    en: "Web",
    az: "",
    ru: "",
  },
  callcenter: {
    en: "Callcenter",
    az: "",
    ru: "",
  },
  pulse: {
    en: "Pulse",
    az: "",
    ru: "",
  },
  wolt: {
    en: "Wolt",
    az: "",
    ru: "",
  },
};

/* GENDER TRANSLATION */

export const gender_translation = {
  male: {
    en: "Male",
    az: "",
    ru: "",
  },
  female: {
    en: "Female",
    az: "",
    ru: "",
  },
};

/* LANGUAGE TRANSLATION */

export const language_translation = {
  azeri: {
    en: "Azeri",
    az: "",
    ru: "",
  },
  english: {
    en: "English",
    az: "",
    ru: "",
  },
  russian: {
    en: "Russian",
    az: "",
    ru: "",
  },
};

/* STATUS TRANSLATION */

export const status_translation = {
  all: {
    en: "All",
    az: "",
    ru: "",
  },
  active: {
    en: "Active",
    az: "",
    ru: "",
  },
  inactive: {
    en: "Inactive",
    az: "",
    ru: "",
  },
  enabled: {
    en: "Enabled",
    az: "",
    ru: "",
  },
  disabled: {
    en: "Disabled",
    az: "",
    ru: "",
  },
};

/* TWO FACTOR AUTHENTICATION TRANSLATION */

export const two_factor_atuhentication_translation = {
  enable: {
    en: "Enable",
    az: "",
    ru: "",
  },
  disable: {
    en: "Disable",
    az: "",
    ru: "",
  },
};

/* LOCKED ACCOUNT TRANSLATION */

export const locked_account_translation = {
  locked: {
    en: "Locked",
    az: "",
    ru: "",
  },
  unlocked: {
    en: "Unlocked",
    az: "",
    ru: "",
  },
};

/* ATTENDENCE TRANSLATION */

export const attendence_translation = {
  check_in: {
    en: "Clock In",
    az: "",
    ru: "",
  },
  check_out: {
    en: "Clock Out",
    az: "",
    ru: "",
  },
  present: {
    en: "Present",
    az: "",
    ru: "",
  },
  leave: {
    en: "Leave",
    az: "",
    ru: "",
  },
  absent: {
    en: "Absent",
    az: "",
    ru: "",
  },
  not_available: {
    en: "N/A",
    az: "",
    ru: "",
  },
};

/* WEEK DAYS TRANSLATION */

export const week_days_translation = {
  monday: {
    en: "Monday",
    az: "",
    ru: "",
  },
  tuesday: {
    en: "Tuesday",
    az: "",
    ru: "",
  },
  wednesday: {
    en: "Wednesday",
    az: "",
    ru: "",
  },
  thursday: {
    en: "Thursday",
    az: "",
    ru: "",
  },
  friday: {
    en: "Friday",
    az: "",
    ru: "",
  },
  saturday: {
    en: "Saturday",
    az: "",
    ru: "",
  },
  sunday: {
    en: "Sunday",
    az: "",
    ru: "",
  },
};

/* MARITAL STATUS TRANSLATION */

export const marital_status_translation = {
  single: {
    en: "Single",
    az: "",
    ru: "",
  },
  married: {
    en: "Married",
    az: "",
    ru: "",
  },
  divorced: {
    en: "Divorced",
    az: "",
    ru: "",
  },
  widowed: {
    en: "Widowed",
    az: "",
    ru: "",
  },
};

/* LEAVE TYPE TRANSLATION */

export const leave_type_translation = {
  vacation: {
    en: "Vacation",
    az: "",
    ru: "",
  },
  sick_leave: {
    en: "Sick Leave",
    az: "",
    ru: "",
  },
  paid: {
    en: "Paid",
    az: "",
    ru: "",
  },
  unpaid: {
    en: "Unpaid",
    az: "",
    ru: "",
  },
};

/* REUSABLE TRANSLATION */

export const reusable_translation = {
  export: {
    en: "Export",
    az: "",
    ru: "",
  },
  back_to_all: {
    en: "Back to All",
    az: "",
    ru: "",
  },
  get_latest_records: {
    en: "Get Latest Records",
    az: "",
    ru: "",
  },
  print: {
    en: "Print",
    az: "",
    ru: "",
  },
  edit: {
    en: "Edit",
    az: "",
    ru: "",
  },
  yes: {
    en: "Yes",
    az: "",
    ru: "",
  },
  no: {
    en: "No",
    az: "",
    ru: "",
  },
  and: {
    en: "AND",
    az: "",
    ru: "",
  },
  or: {
    en: "OR",
    az: "",
    ru: "",
  },
  start_date_filter: {
    en: "Start date cannot be greater than end date.",
    az: "",
    ru: "",
  },
  end_date_filter: {
    en: "End date cannot be less than start date.",
    az: "",
    ru: "",
  },
  search: {
    en: "Search",
    az: "",
    ru: "",
  },
  clear: {
    en: "Clear",
    az: "",
    ru: "",
  },
  data_not_found: {
    en: "Data not found.",
    az: "",
    ru: "",
  },
  export_pdf: {
    en: "Export as PDF",
    az: "",
    ru: "",
  },
  export_excel: {
    en: "Export as XLSX",
    az: "",
    ru: "",
  },
  update: {
    en: "UPDATE",
    az: "",
    ru: "",
  },
  for: {
    en: "For",
    az: "",
    ru: "",
  },
  unsaved_changes: {
    en: "You have unsaved changes. Are you sure you want to leave?",
    az: "",
    ru: "",
  },
  confirmation: {
    en: "Confirmation",
    az: "",
    ru: "",
  },
  start_time_filter: {
    en: "Start time cannot be greater than end time.",
    az: "",
    ru: "",
  },
  upload: {
    en: "Upload",
    az: "",
    ru: "",
  },
};
