import { lazy } from "react";
import Loadable from "ui-components/Loadable";

export const Login = Loadable(lazy(() => import("views/login_panel")));

export const ForgotPassword = Loadable(
  lazy(() => import("views/forgot_password"))
);

export const EmployeeTimeClock = Loadable(
  lazy(() => import("views/employee_time_clock"))
);

export const UserProfile = Loadable(lazy(() => import("views/user_profile")));

export const UsersRegister = Loadable(
  lazy(() => import("views/users_register"))
);

export const UserDetails = Loadable(lazy(() => import("views/user_details")));

export const RolesRegister = Loadable(
  lazy(() => import("views/roles_register"))
);

export const RoleDetails = Loadable(lazy(() => import("views/role_details")));

export const StoresRegister = Loadable(
  lazy(() => import("views/stores_register"))
);

export const StoreDetails = Loadable(lazy(() => import("views/store_details")));

export const AllDepartments = Loadable(
  lazy(() => import("views/departments_register"))
);

export const AllEmployees = Loadable(
  lazy(() => import("views/employee_register"))
);

export const EmployeeDetail = Loadable(
  lazy(() => import("views/employee_details"))
);

export const DepartmentDetail = Loadable(
  lazy(() => import("views/department_details"))
);

export const AllDesginations = Loadable(
  lazy(() => import("views/designations_register"))
);

export const AllShifts = Loadable(lazy(() => import("views/employee_shifts")));

export const AllHolidays = Loadable(
  lazy(() => import("views/employee_holidays"))
);

export const ShiftDetails = Loadable(
  lazy(() => import("views/employee_shifts_details"))
);

export const HolidayDetails = Loadable(
  lazy(() => import("views/employee_holidays_details"))
);

export const EmployeeRoster = Loadable(
  lazy(() => import("views/employee_roster"))
);

export const RosterRegister = Loadable(
  lazy(() => import("views/employee_roster_register"))
);

export const DesignationDetail = Loadable(
  lazy(() => import("views/designation_details"))
);

export const EmployeeTimesheetRegister = Loadable(
  lazy(() => import("views/employee_timesheet_register"))
);

export const EmployeeTimesheetDetail = Loadable(
  lazy(() => import("views/employee_timesheet_details"))
);

export const EmployeeMonthlyTimesheet = Loadable(
  lazy(() => import("views/employee_monthly_timesheet"))
);

export const RequestVacation = Loadable(
  lazy(() => import("views/request_vacation"))
);

export const VacationsRegister = Loadable(
  lazy(() => import("views/vacations_register"))
);

export const VacationDetails = Loadable(
  lazy(() => import("views/vacation_details"))
);

export const LeavesRegister = Loadable(
  lazy(() => import("views/leaves_register"))
);

export const PayrollRegister = Loadable(
  lazy(() => import("views/payroll_register"))
);

export const VacationsReport = Loadable(
  lazy(() => import("views/vacations_report"))
);

export const ErrorPage = Loadable(lazy(() => import("views/error_401_page")));

export const NotFound = Loadable(lazy(() => import("views/error_404_page")));
