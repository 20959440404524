import PropTypes from "prop-types";
import { useTheme } from "@mui/material/styles";
import { Avatar, Box, ButtonBase, Grid } from "@mui/material";
import { Menu, Person } from "@mui/icons-material";
import { LogoutOutlined } from "@mui/icons-material";
import {
  clearDataFromLocalStorage,
  getDataFromLocalStorage,
} from "utils/localStore.utils";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";
import useStyles from "routes/style-jss";

const Header = ({ handleLeftDrawerToggle, drawerOpen }: any) => {
  const classes = useStyles() as any;
  const theme = useTheme() as any;
  const navigate = useNavigate();
  const user = getDataFromLocalStorage("user");

  return (
    <>
      <Box
        sx={{
          width: 270,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
          justifyContent: drawerOpen ? "flex-end" : "flex-start",
        }}
      >
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              transition: "all .2s ease-in-out",
              background: "transparent",
              color: "white",
              "&:hover": {
                color: theme.palette.hitech.hitech_green,
              },
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <Menu
              style={{
                fontSize: 38,
              }}
            />
          </Avatar>
        </ButtonBase>
      </Box>

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      <Grid item className={"breadcrumb_time"}>
        <div
          style={{ color: "#ffffff", textTransform: "uppercase" }}
          className={classNames(classes.breadcrumb_description)}
        >
          {user?.firstname + " " + user?.lastname} &nbsp; &nbsp;
        </div>
      </Grid>

      {user?.employee_picture ? (
        <Avatar
          alt={user?.firstname}
          src={user?.employee_picture}
          style={{
            marginRight: 10,
            background: "none",
            cursor: "pointer",
            width: 30,
            height: 30,
          }}
          onClick={() => {
            navigate("/user-profile");
          }}
        />
      ) : (
        <Person
          style={{
            fontSize: 28,
            color: "white",
            marginRight: 10,
            cursor: "pointer",
          }}
          onClick={() => {
            navigate("/user-profile");
          }}
        />
      )}

      <LogoutOutlined
        style={{
          fontSize: 28,
          color: "white",
          marginRight: 20,
          cursor: "pointer",
        }}
        onClick={() => {
          clearDataFromLocalStorage("token");
          clearDataFromLocalStorage("user");
          clearDataFromLocalStorage("lastInteractionTime");
          navigate("/login");
        }}
      />
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
  drawerOpen: PropTypes.bool,
};

export default Header;
