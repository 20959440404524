import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";

export interface WorkflowState {
  all_workflow_states_data: any;
  all_workflow_states: any;
  single_workflow_state: any;
  create_workflow_state: any;
  update_workflow_state: any;
  all_workflow_actions_data: any;
  all_workflow_actions: any;
  single_workflow_action: any;
  create_workflow_action: any;
  update_workflow_action: any;

  all_workflow: any;
  all_workflow_data: any;
  get_workflow_request: any;
  create_workflow_request_data: any;
  activity: boolean;
}

// eslint-disable-next-line
interface APIParams {
  end_point: string;
  body: {};
}

const initialState: WorkflowState = {
  all_workflow_states_data: [],
  all_workflow_states: [],
  single_workflow_state: {},
  create_workflow_state: {},
  update_workflow_state: {},
  all_workflow_actions_data: {},
  all_workflow_actions: {},
  single_workflow_action: {},
  create_workflow_action: {},
  update_workflow_action: {},

  all_workflow: [],
  all_workflow_data: [],
  get_workflow_request: {},
  create_workflow_request_data: {},
  activity: false,
};

export const all_workflow_request = createAsyncThunk(
  "/setting/workflows",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "settings/workflows"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_workflow_request = createAsyncThunk(
  "/setting/workflows/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "setting/workflows"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_workflow_request = createAsyncThunk(
  "/setting/workflows/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "setting/workflows"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

////
export const all_workflow_state_types = createAsyncThunk(
  "/setting/state-types",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "settings/state-types"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_workflow_state = createAsyncThunk(
  "/settings/state-types/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "settings/state-types"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_workflow_state = createAsyncThunk(
  "/settings/state-types",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "settings/state-types"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_workflow_state = createAsyncThunk(
  "/settings/update-state-types",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "settings/state-types"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const all_workflow_actions = createAsyncThunk(
  "/setting/workflow-actions",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "settings/workflow-actions"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_workflow_action = createAsyncThunk(
  "/settings/workflow-actions/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "settings/workflow-actions"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_workflow_action = createAsyncThunk(
  "/settings/workflow-actions",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "settings/workflow-actions"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_workflow_action = createAsyncThunk(
  "/settings/update-workflow-actions",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "settings/workflow-actions"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const workflows_slicer = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    handleClearForm: (state: WorkflowState, action: any) => {
      state.get_workflow_request = {};
      state.activity = false;
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(all_workflow_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_workflow_request.fulfilled, (state, { payload }) => {
        try {
          let all_workflow = [] as any;

          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];
            all_workflow.push([
              { name: item?._id || "Not Available", hidden: true },
              item?.workflow_name || "Not Available",
              item?.document_type === "material_requests"
                ? "Material Request"
                : item?.document_type || "Not Available",
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "#bf0100",
                alignment: "center",
              },
              {
                name: moment(item?.start_date).format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              {
                name: moment(item?.end_date).format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              {
                name: moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              { name: item?.added_by ?? "Admin", alignment: "center" },
            ]);
          }

          state.all_workflow_data = payload?.data;
          state.all_workflow = all_workflow;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(single_workflow_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_workflow_request.fulfilled, (state, { payload }) => {
        try {
          state.get_workflow_request = payload.data;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(create_workflow_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_workflow_request.fulfilled, (state, { payload }) => {
        try {
          state.create_workflow_request_data = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(all_workflow_state_types.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_workflow_state_types.fulfilled, (state, { payload }) => {
        try {
          let workflow_states = [] as any;

          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];
            workflow_states.push([
              { name: item?.id || "Not Available", hidden: true },
              item?.state_type_name || "Not Available",
              moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss"),
              moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss"),
              "Not Available",
            ]);
          }

          state.all_workflow_states_data = payload?.data;
          state.all_workflow_states = workflow_states;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(single_workflow_state.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_workflow_state.fulfilled, (state, { payload }) => {
        try {
          state.single_workflow_state = payload.data;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(create_workflow_state.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_workflow_state.fulfilled, (state, { payload }) => {
        try {
          state.create_workflow_state = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_workflow_state.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_workflow_state.fulfilled, (state, { payload }) => {
        try {
          state.update_workflow_state = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(all_workflow_actions.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_workflow_actions.fulfilled, (state, { payload }) => {
        try {
          let workflow_actions = [] as any;

          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];
            workflow_actions.push([
              { name: item?.id || "Not Available", hidden: true },
              item?.action_name || "Not Available",
              moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss"),
              moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss"),
              "Not Available",
            ]);
          }

          state.all_workflow_actions_data = payload?.data;
          state.all_workflow_actions = workflow_actions;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(single_workflow_action.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_workflow_action.fulfilled, (state, { payload }) => {
        try {
          state.single_workflow_action = payload.data;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(create_workflow_action.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_workflow_action.fulfilled, (state, { payload }) => {
        try {
          state.create_workflow_action = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_workflow_action.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_workflow_action.fulfilled, (state, { payload }) => {
        try {
          state.update_workflow_action = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      });
  },
});

export const { handleClearForm } = workflows_slicer.actions;

export default workflows_slicer.reducer;
