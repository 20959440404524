import { makeStyles } from "@mui/styles";
import { default_label_colors } from "utils/constants";

const useStyles = makeStyles((theme: any) => ({
  align_center: {
    display: "flex",
    alignItems: "center",
  },

  align_end: {
    display: "flex",
    alignItems: "flex-end",
  },

  justify_center: {
    display: "flex",
    justifyContent: "center",
  },

  justify_end: {
    display: "flex",
    justifyContent: "flex-end",
  },

  direction_row: {
    flexDirection: "row",
  },

  direction_column: {
    flexDirection: "column",
  },

  display_flex_baseline: {
    display: "flex",
    alignItems: "baseline",
  },

  breadcrumb_container: {
    position: "sticky",
    top: 48,
    zIndex: 999,
    padding: "10px 40px",
    background: theme.palette.hitech.hitech_red,
    boxShadow:
      "0px 2px 4px -1px #00000033, 0px 4px 5px 0px #00000024, 0px 1px 10px 0px #0000001f",
    marginBottom: 10,
  },

  breadcrumb_head: {
    color: theme.palette.hitech.hitech_white,
    fontFamily: "BebasNeue-Bold",
    fontSize: 30,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    textTransform: "uppercase",
  },

  breadcrumb_time: {
    fontFamily: "BebasNeue-Bold",
    color: theme.palette.hitech.hitech_white,
    fontSize: 24,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },

  breadcrumb_description: {
    fontFamily: "BebasNeue-Bold",
    color: theme.palette.hitech.hitech_white,
    fontSize: 24,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
  },

  main_layout_padding: {
    padding: "20px 20px 50px 20px",
  },

  colored_input_feild: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.hitech.hitech_green,
    background: "#fff",
  },

  input_feild: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    background: "#fff",
    color: theme.palette.hitech.black,
  },

  input_feild_2: {
    fontSize: 24,
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "normal",
    background: "#fff",
    color: theme.palette.hitech.black,
  },

  info_table: {
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.primary.color_f,
  },

  background_blue: {
    background: theme.palette.hitech.hitech_green,
  },

  background_light_gray: {
    background: "#e0e0e0",
  },

  table_head: {
    fontSize: 22,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: 1,
    color: theme.palette.hitech.hitech_white,
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    background: theme.palette.hitech.hitech_green,
  },

  table_body: {
    fontSize: 18,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: 1,
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
  },

  normal_text: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.hitech.black,
  },

  simple_normal_text: {
    fontSize: 15,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.hitech.black,
  },

  pagination_per_page: {
    padding: 5,
    borderRadius: 4,
    fontSize: 14,
    color: theme.palette.hitech.hitech_green,
    background: theme.palette.hitech.hitech_white,
    marginRight: 30,
  },

  color_secondary: {
    color: `${theme.palette.hitech.hitech_green} !important`,
  },

  color_primary: {
    color: `${theme.palette.hitech.hitech_dark_green} !important`,
  },

  bg_gray: {
    backgroundColor: "#f6f6f6",
    background: "#f6f6f6",
  },

  primary_divider: {
    borderColor: theme.palette.hitech.hitech_dark_green,
    borderBottomWidth: "medium",
  },

  secondary_divider: {
    borderColor: theme.palette.hitech.hitech_green,
    borderBottomWidth: "medium",
  },

  gray_divider: {
    borderColor: theme.palette.hitech.grey1,
    borderBottomWidth: "medium",
    borderLeftWidth: "medium",
    borderRadius: 10,
  },

  pagination_container: {
    color: theme.palette.hitech.hitech_green,
    "& ul": {
      "& li": {
        "& .Mui-selected": {
          background: theme.palette.hitech.hitech_green,
          border: `1px solid ${theme.palette.hitech.hitech_green}`,
          color: theme.palette.hitech.hitech_white,
          fontFamily: "Roboto-Regular",
          fontSize: 14,
          borderRadius: 3,
          "&:hover": {
            background: theme.palette.hitech.hitech_green,
            color: theme.palette.hitech.hitech_white,
          },
        },
        "& button": {
          background: theme.palette.hitech.hitech_white,
          color: theme.palette.hitech.hitech_green,
        },
      },
    },
  },

  check_icon: {
    color: `${theme.palette.hitech.hitech_green} !important`,
    height: 24,
    width: 24,
  },

  selected_row: {
    border: `2px solid ${theme.palette.hitech.hitech_green}`,
  },

  unselected_row: {
    borderBottom: `none`,
    borderTop: `2px solid transparent`,
    borderLeft: `2px solid transparent`,
    borderRight: `2px solid transparent`,
  },

  input_label_style: {
    padding: "0px 0px 4px 10px",
    fontFamily: "BebasNeue-Book",
    fontSize: 16,
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "1px",
    color: theme.palette.hitech.black,
    textTransform: "uppercase",
  },

  typography_heading_style: {
    padding: "0px 0px 4px 10px",
    fontFamily: "BebasNeue-Bold",
    fontSize: 22,
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "1px",
    color: theme.palette.hitech.black,
    textTransform: "uppercase",
  },

  typography_otp_heading_style: {
    padding: "0px 0px 4px 10px",
    fontFamily: "BebasNeue-Bold",
    fontSize: 20,
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "1px",
    color: "#fff",
    textTransform: "uppercase",
  },

  white_input_label_style: {
    padding: "0px 0px 4px 10px",
    fontFamily: "BebasNeue-Book",
    fontSize: 16,
    fontWeight: 900,
    lineHeight: "normal",
    letterSpacing: "1px",
    color: "#fff",
    textTransform: "uppercase",
  },

  color_red: {
    color: `red !important`,
  },

  button_width: {
    minWidth: 120,
    maxHeight: 43,
    minHeight: 43,
    textTransform: "none",
    boxShadow: "none",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      minWidth: "0px",
      marginBottom: 5,
    },
  },

  green_contained_button: {
    background: "green",
    borderColor: "green",
    color: theme.palette.hitech.hitech_white,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    "&:hover": {
      background: "green",
      borderColor: "green",
      color: theme.palette.hitech.hitech_white,
      transition: "0.2s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  red_contained_button: {
    background: theme.palette.hitech.hitech_red,
    borderColor: theme.palette.hitech.hitech_red,
    color: theme.palette.hitech.hitech_white,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    "&:hover": {
      background: theme.palette.hitech.hitech_red,
      borderColor: theme.palette.hitech.hitech_red,
      color: theme.palette.hitech.hitech_white,
      transition: "0.2s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  contained_button: {
    background: theme.palette.hitech.hitech_green,
    color: theme.palette.hitech.hitech_white,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    textTransform: "uppercase",
    "&:hover": {
      background: theme.palette.hitech.hitech_green,
      color: theme.palette.hitech.hitech_white,
      transition: "0.2s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  numpad_button: {
    color: "#ffffff",
    fontSize: 25,
    background: "#00a000",
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    "&:hover": {
      color: "#ffffff",
      fontSize: 25,
      background: "#00a000",
    },
  },

  red_numpad_button: {
    color: "#ffffff",
    fontSize: 25,
    background: "#bf0100",
    borderColor: "#bf0100",
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    "&:hover": {
      color: "#ffffff",
      fontSize: 25,
      background: "#bf0100",
      borderColor: "#bf0100",
    },
  },

  green_numpad_button: {
    color: "#ffffff",
    fontSize: 25,
    background: "green",
    borderColor: "green",
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    "&:hover": {
      color: "#ffffff",
      fontSize: 25,
      background: "green",
      borderColor: "green",
    },
  },

  check_in_button: {
    color: "#ffffff",
    fontSize: 25,
    background: theme.palette.hitech.hitech_green,
    borderColor: theme.palette.hitech.hitech_green,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    "&:hover": {
      color: "#ffffff",
      fontSize: 25,
      background: theme.palette.hitech.hitech_green,
      borderColor: theme.palette.hitech.hitech_green,
    },
  },

  excel_outlined_button: {
    background: "#fff",
    color: "#1d6f42",
    borderColor: "#1d6f42",
    transition: "1s",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    letterSpacing: "1px",
    fontSize: "18px",
    textTransform: "uppercase",
    "&:hover": {
      background: "#1d6f42",
      borderColor: "#1d6f42",
      color: "#fff",
      transition: "1s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  pdf_outlined_button: {
    background: "#fff",
    color: "#CD2A29",
    borderColor: "#CD2A29",
    transition: "1s",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    letterSpacing: "1px",
    fontSize: "18px",
    textTransform: "uppercase",
    "&:hover": {
      background: "#CD2A29",
      borderColor: "#CD2A29",
      color: "#fff",
      transition: "1s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  red_outlined_button: {
    background: theme.palette.hitech.hitech_white,
    color: theme.palette.hitech.hitech_red,
    borderColor: theme.palette.hitech.hitech_red,
    transition: "1s",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    letterSpacing: "1px",
    fontSize: "18px",
    textTransform: "uppercase",
    "&:hover": {
      background: theme.palette.hitech.hitech_red,
      color: theme.palette.hitech.hitech_white,
      borderColor: theme.palette.hitech.hitech_red,
      transition: "1s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  green_outlined_button: {
    background: theme.palette.hitech.hitech_white,
    color: "#00c853",
    borderColor: "#00c853",
    transition: "1s",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    letterSpacing: "1px",
    fontSize: "18px",
    textTransform: "uppercase",
    "&:hover": {
      background: "#00c853",
      color: theme.palette.hitech.hitech_white,
      borderColor: "#00c853",
      transition: "1s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  outlined_button: {
    background: theme.palette.hitech.hitech_white,
    color: theme.palette.hitech.hitech_green,
    borderColor: theme.palette.hitech.hitech_green,
    transition: "1s",
    fontFamily: "BebasNeue-Bold",
    fontWeight: 900,
    letterSpacing: "1px",
    fontSize: "18px",
    textTransform: "uppercase",
    "&:hover": {
      background: theme.palette.hitech.hitech_green,
      color: theme.palette.hitech.hitech_white,
      transition: "1s",
      boxShadow:
        "4px 4px 3px -1px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
    },
  },

  disabled_button: {
    background: theme.palette.hitech.grey1,
    color: theme.palette.hitech.grey3,
    borderColor: theme.palette.hitech.grey1,
    transition: "0.2s",
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    textTransform: "uppercase",
  },

  modal_background: {
    backgroundColor: theme.palette.hitech.hitech_green,
    background: theme.palette.hitech.hitech_green,
    padding: "10px 20px",
    textTransform: "uppercase",
  },

  modal_title: {
    fontSize: 22,
    fontWeight: "normal",
    lineHeight: "normal",
    color: default_label_colors.color_h,
    letterSpacing: "0.015em",
    fontFamily: "BebasNeue-Bold",
  },

  card_label_style: {
    fontSize: 16,
    color: "gray",
    fontFamily: "Roboto-Medium",
  },

  card_value_style: {
    fontSize: 16,
    color: theme.palette.hitech.black,
    fontFamily: "Roboto-Medium",
  },

  profile_name: {
    fontSize: 20,
    color: theme.palette.hitech.black,
    fontFamily: "Roboto-Bold",
    letterSpacing: 1.5,
  },

  accordion_head_container: {
    backgroundColor: `${theme.palette.hitech.hitech_red} !important`,
    background: `${theme.palette.hitech.hitech_red} !important`,
    width: "100%",
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: "3px 3px 0px 0px ",
    minHeight: `38px !important`,
    height: `38px !important`,
  },

  accordion_heading: {
    fontSize: 20,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "0.015em",
    color: theme.palette.hitech.black,
    fontFamily: "Roboto-Medium",
  },

  accordion_icons: {
    fontSize: 20,
    color: theme.palette.hitech.grey3,
  },

  accordion_container: {
    border: `1px solid ${theme.palette.hitech.hitech_red}`,
  },

  accordion_detail_padding: {
    padding: "24px 32px 30px",
  },

  toggle_sections: {
    color: theme.palette.hitech.hitech_green,
    fontSize: 16,
    paddingLeft: 15,
    fontFamily: "Roboto-Regular",
    cursor: "pointer",
  },

  view_image_text: {
    display: "flex",
    alignItems: "center",
    padding: 10,
    fontFamily: "RobotoCondensed-Regular",
    color: theme.palette.hitech.hitech_green,
  },

  dialog_footer_info: {
    fontSize: 14,
    fontFamily: "RobotoCondensed-Regular",
    color: theme.palette.hitech.grey3,
    width: "max-content",
  },

  dialog_description: {
    fontSize: 16,
    fontWeight: "normal",
    lineHeight: "normal",
    letterSpacing: "normal",
    color: theme.palette.hitech.grey3,
    fontFamily: "Roboto-Medium",
  },

  dialog_description_2: {
    fontSize: 16,
    color: theme.palette.hitech.hitech_green,
    fontFamily: "RobotoCondensed-Regular",
  },

  radio_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.hitech.hitech_green,
  },

  unselect_radio_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.hitech.grey3,
  },

  form_container: {
    background: "#ffffff",
    padding: 20,
    border: `2px solid ${theme.palette.hitech.hitech_white}`,
    [theme.breakpoints.down("sm")]: {},
  },

  form_container_without_padding: {
    background: "#ffffff",
    [theme.breakpoints.down("sm")]: {},
  },

  setting_icons: {
    color: theme.palette.hitech.hitech_green,
    height: 60,
    width: "100%",
  },

  setting_container: {
    background: "#ffffff",
    margin: 30,
    padding: 20,
    border: `2px solid ${theme.palette.hitech.hitech_white}`,
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {},
  },

  setting_heading: {
    fontSize: 18,
    fontFamily: "Roboto-Medium",
  },

  setting_description: {
    fontSize: 15,
    fontFamily: "Roboto-Regular",
    color: theme.palette.hitech.grey3,
  },

  setting_hover: {
    transition: "0.5s",
    padding: 10,
    borderRadius: 10,
    "&:hover": {
      background: theme.palette.hitech.hitech_red,
      transition: "0.5s",
    },
  },

  switch_label: {
    fontSize: 16,
    fontFamily: "Roboto-Regular",
    color: theme.palette.hitech.hitech_green,
  },

  rate_dialog_label: {
    fontSize: 16,
    fontFamily: "Roboto-Medium",
    color: theme.palette.hitech.grey3,
  },

  responsive_width: {
    width: "30%",
    [theme.breakpoints.down("xl")]: {
      width: "50%",
    },
    [theme.breakpoints.down("lg")]: {
      width: "50%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },

  commission_label: {
    fontSize: 16,
    color: theme.palette.hitech.grey3,
    fontFamily: "RobotoCondensed-Medium",
  },

  dashboard_card_head: {
    fontSize: 34,
    color: theme.palette.hitech.hitech_green,
    fontFamily: "BebasNeue-Bold",
  },

  dashboard_card_title: {
    fontSize: 18,
    color: theme.palette.hitech.hitech_green,
    fontFamily: "Roboto-Medium",
  },

  dashboard_card_container: {
    padding: 10,
    border: `1px solid ${theme.palette.hitech.hitech_green}`,
    borderRadius: 5,
    position: "relative",
    boxShadow: `0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)`,
  },

  active_tab_button: {
    background: theme.palette.hitech.hitech_white,
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    letterSpacing: 1,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },

  inactive_tab_button: {
    background: theme.palette.hitech.hitech_green,
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    color: "#ffffff !important",
    letterSpacing: 1,
  },

  typography_heading: {
    fontFamily: "BebasNeue-Bold",
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 1.334,
    letterSpacing: 1,
    textTransform: "uppercase",
  },

  typography_sales_heading: {
    fontFamily: "BebasNeue-Bold",
    fontWeight: 400,
    fontSize: 28,
    lineHeight: 1.334,
    letterSpacing: 1,
    textTransform: "uppercase",
  },

  typography_blue_heading: {
    fontFamily: "BebasNeue-Bold",
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 1.334,
    letterSpacing: 1,
    textTransform: "uppercase",
    color: theme.palette.hitech.hitech_green,
  },

  typography_red_heading: {
    fontFamily: "BebasNeue-Bold",
    fontWeight: 400,
    fontSize: 20,
    lineHeight: 1.334,
    letterSpacing: 1,
    textTransform: "uppercase",
    color: theme.palette.hitech.hitech_red,
  },

  typography_login_heading: {
    fontFamily: "BebasNeue-Bold",
    fontWeight: 400,
    fontSize: 28,
    lineHeight: 1.334,
    letterSpacing: 1,
    textTransform: "uppercase",
    color: "#fff",
  },

  typography_employee_time_clock_heading: {
    fontFamily: "BebasNeue-Bold",
    fontWeight: 400,
    fontSize: 24,
    lineHeight: 1.334,
    letterSpacing: 1,
    textTransform: "uppercase",
    color: theme.palette.hitech.hitech_white,
  },

  typography_caption: {
    fontFamily: "Roboto-Condensed-Regular",
    fontWeight: 600,
    fontSize: 13,
    lineHeight: 1.334,
    letterSpacing: 1,
    marginLeft: 1,
    marginTop: 1,
    color: "#fff",
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.hitech.hitech_red,
    },
  },

  disabled_typography_caption: {
    fontFamily: "Roboto-Condensed-Regular",
    fontWeight: 600,
    fontSize: 13,
    lineHeight: 1.334,
    letterSpacing: 1,
    marginLeft: 1,
    marginTop: 1,
    color: theme.palette.hitech.hitech_white,
    cursor: "pointer",
  },

  typography_sales_subtitle: {
    fontFamily: "Roboto-Condensed-Regular",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: 1.334,
    letterSpacing: 1,
    marginLeft: 1,
    marginTop: 1,
    color: theme.palette.hitech.black,
    cursor: "pointer",
    "&:hover": {
      color: theme.palette.hitech.hitech_red,
    },
  },

  app_bar: {
    backgroundColor: theme.palette.hitech.hitech_green,
    boxShadow: "none",
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
  },

  expansion_panel_summary: {
    backgroundColor: "#00a000",
    fontFamily: "BebasNeue-Bold",
    textTransform: "uppercase",
    borderTopRightRadius: 5,
    borderTopLeftRadius: 5,
  },

  expand_more_icon: {
    color: "white",
  },

  link_error: {
    fontWeight: "bold",
    color: "#000000",
    fontSize: "40px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "BebasNeue-Book",
    textTransform: "uppercase",
  },

  red_app_bar: {
    backgroundColor: theme.palette.hitech.hitech_red,
    boxShadow: "none",
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },

  red_active_tab_button: {
    background: theme.palette.hitech.hitech_white,
    color: theme.palette.hitech.hitech_red,
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    letterSpacing: 1,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },

  red_inactive_tab_button: {
    background: theme.palette.hitech.hitech_red,
    fontFamily: "BebasNeue-Bold",
    fontSize: 18,
    color: "#ffffff !important",
    letterSpacing: 1,
  },

  sticky: {
    position: "sticky",
    left: 0,
  },
}));

export default useStyles;
