import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { utc_time_zone } from "./constants";
import _ from "lodash";

export interface UserState {
  all_users_data: any;
  all_users: any;
  single_user: any;
  create_user: any;
  update_user: any;
  delete_user: any;
  reset_user_password: any;
  line_managers: any;
  line_managers_data: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
  body: any;
}

const initialState: UserState = {
  all_users_data: [],
  all_users: [],
  single_user: {},
  create_user: {},
  update_user: {},
  delete_user: {},
  reset_user_password: {},
  line_managers: [],
  line_managers_data: [],
  activity: false,
};

export const all_users = createAsyncThunk(
  "/users/",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "users");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_users = createAsyncThunk(
  "/users/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "users");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_user = createAsyncThunk(
  "/users/create",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "users"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_user = createAsyncThunk(
  "/users/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "users"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const delete_user = createAsyncThunk(
  "/users/delete",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "users"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const assign_role_to_user = createAsyncThunk(
  "/users/assign-user",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "users"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const assign_locations_to_user = createAsyncThunk(
  "/users/assign-locations",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "users"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const line_managers = createAsyncThunk(
  "/users/line-managers",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "users"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const reset_user_password = createAsyncThunk(
  "/users/change-user-password",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "users"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const users_slicer = createSlice({
  name: "users",
  initialState,
  reducers: {
    handle_clear_form: (state: UserState, action: any) => {
      state.single_user = {};
      state.create_user = {};
      state.update_user = {};
      state.delete_user = {};
      state.reset_user_password = {};
      state.activity = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(all_users.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_users.fulfilled, (state, { payload }) => {
        try {
          let users = [] as any;

          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];

            let roles = item?.roles?.splice(0, 2)?.map((itemx: any) => {
              return itemx?.role_name;
            });

            let role = item?.roles?.map((itemx: any) => {
              return itemx?.role_name;
            });

            let sorted_locations = item?.locations?.sort((a: any, b: any) => {
              return a.location_code - b.location_code;
            });

            let locations = sorted_locations
              ?.splice(0, 2)
              ?.map((itemx: any) => {
                return itemx?.location_code;
              });

            let loc = sorted_locations?.map((itemx: any) => {
              return itemx?.location_code;
            });

            let managers = item?.managers?.map((itemx: any) => {
              return itemx?.full_name;
            });

            users.push([
              { name: item?._id || "Not Available", hidden: true },
              item?.lastname
                ? (_.capitalize(item?.firstname) || "Not Available") +
                  " " +
                  _.capitalize(item?.lastname)
                : _.capitalize(item?.firstname) || "Not Available",
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "#bf0100",
                alignment: "center",
              },
              { name: item?.is_2fa ? "Yes" : "No", alignment: "center" },
              {
                name:
                  locations?.length > 1
                    ? "More Than 1 Location"
                    : locations?.length > 0
                    ? locations?.join(", ")
                    : "Not Available",
                hover: loc?.join(", "),
                alignment: "center",
              },
              {
                name: roles?.length > 0 ? roles?.join(", ") : "Not Available",
                hover: role?.join(", "),
              },
              { name: item?.is_mgr ? "Yes" : "No", alignment: "center" },
              managers?.length > 0 ? managers?.join(", ") : "Not Available",
              {
                name: moment(item?.created_at)
                  .utcOffset(utc_time_zone)
                  .format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              {
                name:
                  item?.updated_at !== item?.created_at
                    ? moment(item?.updated_at)
                        .utcOffset(utc_time_zone)
                        .format("DD.MMM.YYYY HH:mm:ss")
                    : "Not Available",
                alignment: "center",
              },
            ]);
          }

          state.all_users_data = payload?.data;
          state.all_users = users;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(single_users.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_users.fulfilled, (state, { payload }) => {
        try {
          state.single_user = payload.data;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(create_user.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_user.fulfilled, (state, { payload }) => {
        try {
          state.create_user = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_user.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_user.fulfilled, (state, { payload }) => {
        try {
          state.update_user = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(reset_user_password.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(reset_user_password.fulfilled, (state, { payload }) => {
        try {
          state.reset_user_password = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(delete_user.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(delete_user.fulfilled, (state, { payload }) => {
        try {
          state.delete_user = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(line_managers.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(line_managers.fulfilled, (state, { payload }) => {
        try {
          let managers = [] as any;

          for (let index = 0; index < payload?.data.length; index++) {
            const item = payload?.data[index];
            managers.push({
              label: item?.firstname + " " + item?.lastname,
              value: `${item?._id}`,
            });
          }

          state.line_managers_data = payload?.data;
          state.line_managers = managers;
          state.activity = false;
        } catch (error) {}
      })

      .addCase(assign_role_to_user.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(assign_role_to_user.fulfilled, (state, { payload }) => {
        try {
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(assign_locations_to_user.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(assign_locations_to_user.fulfilled, (state, { payload }) => {
        try {
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      });
  },
});

export const { handle_clear_form } = users_slicer.actions;

export default users_slicer.reducer;
