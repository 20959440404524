import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { convert_utc_to_user_timezone } from "utils/helper";

export interface TimesheetState {
  employee_timesheet: any;
  employee_timesheet_data: any;
  export_employee_timesheet_data: any;
  daily_employee_timesheet: any;
  daily_employee_timesheet_data: any;
  monthly_employee_timesheet: any;
  monthly_employee_timesheet_data: any;
  monthly_emp_timesheet_payload: any;
  export_monthly_employee_timesheet: any;
  update_timesheet_data: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
  body: any;
}

const initialState: TimesheetState = {
  employee_timesheet: [],
  employee_timesheet_data: [],
  export_employee_timesheet_data: [],
  daily_employee_timesheet: [],
  daily_employee_timesheet_data: [],
  monthly_employee_timesheet: [],
  monthly_employee_timesheet_data: [],
  monthly_emp_timesheet_payload: [],
  export_monthly_employee_timesheet: [],
  update_timesheet_data: [],
  activity: false,
};

export const employee_timesheet_register = createAsyncThunk(
  "/attendence/get-store-wise-attendence",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_employee_timesheet_register = createAsyncThunk(
  "/attendence/export-get-store-wise-attendence",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const employee_daily_timesheet = createAsyncThunk(
  "/attendence/get-daily-attendence",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const employee_monthly_timesheet = createAsyncThunk(
  "/time-entries/get-time-sheet-dwmy",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_employee_monthly_timesheet = createAsyncThunk(
  "/time-entries/export-get-time-sheet-dwmy",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_employee_time_slot = createAsyncThunk(
  "/time-entries/update-employee-time-sheet",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const employee_slicer = createSlice({
  name: "employees",
  initialState,
  reducers: {
    handle_clear_form: (state: TimesheetState, action: any) => {
      state.export_employee_timesheet_data = [];
      state.export_monthly_employee_timesheet = [];
      state.activity = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(employee_timesheet_register.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(employee_timesheet_register.fulfilled, (state, { payload }) => {
        try {
          let employee_timesheet = [];

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            employee_timesheet.push([
              "TS" + item?.timesheet_id,
              {
                name:
                  moment(new Date(item?.attendence_date)).format(
                    "DD.MMM.YYYY"
                  ) ?? "Not Available",
                alignment: "center",
              },
              {
                name: item?.location_code ?? "Not Available",
                alignment: "center",
              },
              item?.location_name ?? "Not Available",
              {
                name:
                  Number(item?.total_employees ?? 0).toLocaleString() ?? "0",
                alignment: "center",
              },
              {
                name:
                  Number(item?.available_employees ?? 0).toLocaleString() ??
                  "0",
                alignment: "center",
              },
            ]);
          }

          state.employee_timesheet_data = payload;
          state.employee_timesheet = employee_timesheet;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(
        export_employee_timesheet_register.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        export_employee_timesheet_register.fulfilled,
        (state, { payload }) => {
          try {
            let emp_timesheet_data = [] as any;

            for (let index = 0; index < payload?.data?.length; index++) {
              const item = payload?.data?.[index];

              emp_timesheet_data.push({
                "ID": item?.timesheet_id
                  ? "TS" + item?.timesheet_id
                  : "Not Available",
                "Attendance Date":
                  moment(item?.attendence_date).format("DD.MMM.YYYY") ??
                  "Not Available",
                "Location Code": item?.location_code ?? "Not Available",
                Location: item?.location_name ?? "Not Available",
                "Total Employees": `${item.total_employees}`,
                "Available Employees": `${item.available_employees}`,
              });
            }

            state.export_employee_timesheet_data = {
              emp_timesheet_data: emp_timesheet_data,
              refresh_data: new Date(),
            };
            state.activity = false;
          } catch (error) {
            console.log(error);
          }
        }
      )

      .addCase(employee_daily_timesheet.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(employee_daily_timesheet.fulfilled, (state, { payload }) => {
        try {
          state.daily_employee_timesheet_data = payload?.data;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(update_employee_time_slot.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_employee_time_slot.fulfilled, (state, { payload }) => {
        try {
          state.update_timesheet_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(employee_monthly_timesheet.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(employee_monthly_timesheet.fulfilled, (state, { payload }) => {
        try {
          let emp_timesheet_data = [] as any;

          if (payload?.data?.length > 0) {
            for (let index = 0; index < payload?.data?.length; index++) {
              const item = payload?.data?.[index];

              emp_timesheet_data.push([
                {
                  name: item?.employee_name ?? "Not Available",
                },
                {
                  name: item?.store_name ?? "Not Available",
                },
                {
                  name: item?.designation_name ?? "Not Available",
                },
                {
                  name:
                    item?.actual_hours === null
                      ? "--:--"
                      : item?.actual_hours.substring(0, 5) ?? "Not Available",
                  alignment: "center",
                },
                {
                  name:
                    item?.expected_hours === null
                      ? "--:--"
                      : item?.expected_hours.substring(0, 5) ?? "Not Available",
                  alignment: "center",
                },
              ]);
            }
          }

          state.monthly_employee_timesheet_data = emp_timesheet_data;
          state.monthly_employee_timesheet = payload?.data;
          state.monthly_emp_timesheet_payload = payload;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(
        export_employee_monthly_timesheet.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        export_employee_monthly_timesheet.fulfilled,
        (state, { payload }) => {
          try {
            let emp_timesheet_data = [] as any;

            if (payload?.data?.length > 0) {
              for (let index = 0; index < payload?.data?.length; index++) {
                const item = payload?.data?.[index];

                for (
                  let indexx = 0;
                  indexx < item?.time_data?.length;
                  indexx++
                ) {
                  const itemx = item?.time_data?.[indexx];

                  emp_timesheet_data.push({
                    Date:
                      moment(itemx?._date).format("ddd, DD.MMMM.YYYY") ??
                      "Not Available",
                    "Employee Name": item?.employee_name ?? "Not Available",
                    Location: item?.store_name ?? "Not Available",
                    Designation: item?.designation_name ?? "Not Available",
                    "Clock In":
                      itemx?.start_time === null
                        ? "--:--"
                        : convert_utc_to_user_timezone(itemx?.start_time),
                    "Clock In Difference":
                      itemx?.start_time_difference === null ||
                      !itemx?.start_time_difference
                        ? "--:--"
                        : itemx?.start_time_difference.substring(0, 5) ??
                          "Not Available",
                    "Clock Out":
                      itemx?.end_time === null
                        ? "--:--"
                        : convert_utc_to_user_timezone(itemx?.end_time),
                    "Clock Out Difference":
                      itemx?.end_time_difference === null ||
                      !itemx?.end_time_difference
                        ? "--:--"
                        : itemx?.end_time_difference.substring(0, 5) ??
                          "Not Available",
                    "Actual Hours":
                      itemx?.duration === null || !itemx?.duration
                        ? "--:--"
                        : itemx?.duration.substring(0, 5) ?? "Not Available",
                    "Expected Hours":
                      itemx?.expected_days_off?.toLowerCase() === "leave" ||
                      itemx?.expected_days_off?.toLowerCase() === "off" ||
                      itemx?.expected_days_off?.toLowerCase() === "holiday"
                        ? itemx?.expected_days_off
                        : itemx?.expected_hours === null ||
                          !itemx?.expected_hours
                        ? "--:--"
                        : itemx?.expected_hours.substring(0, 5) ??
                          "Not Available",
                  });
                }
              }
            }

            state.export_monthly_employee_timesheet = {
              emp_timesheet_data: emp_timesheet_data,
              refresh_data: new Date(),
            };
            state.activity = false;
          } catch (error) {
            console.log(error);
          }
        }
      );
  },
});

export const { handle_clear_form } = employee_slicer.actions;

export default employee_slicer.reducer;
