import axios from "axios";
import { getDataFromLocalStorage } from "utils/localStore.utils";

// export const BASE_URL = "http://localhost:5001/api/v1/";
// export const BASE_URL = "https://dev-erpserver.hitech-prime.com/api/v1/";
export const BASE_URL = "https://hrserver.hitech-prime.com/api/v1/";
// export const BASE_URL = "https://hrserver.abacusfirst.net/api/v1/";

export const getApiRequestHeader = async () => {
  /**Will get token from reducers */
  const authtoken = getDataFromLocalStorage("token");
  return {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: authtoken ? `Bearer ${authtoken}` : "",
  };
};

let instance = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
}) as any;

export const updateHeaders = async () => {
  const header = await getApiRequestHeader();
  instance.defaults.headers = header as any;
};

export const old_request = async ({ method, url, data, headers }: any) => {
  if (headers === undefined) {
    await updateHeaders();
  }
  const promise = instance[method](url, data);
  let response;
  try {
    response = await promise;
  } catch (error: any) {
    return error.response?.data;
  }
  return response?.data;
};

export const request = async ({ method, url, data, headers }: any) => {
  if (headers === undefined) {
    await updateHeaders();
  }
  const requestConfig = {
    method,
    url,
    headers,
    data, // Set the data property for DELETE requests with a payload
  };
  const promise = instance(requestConfig);

  let response;
  try {
    response = await promise;
  } catch (error: any) {
    return error.response?.data;
  }

  return response?.data;
};

export const get = (url: string, permission_name: string, config: any) => {
  return request({
    method: "get",
    url,
    data: {},
    ...config,
  });
};

export const post = (
  url: string,
  data: any,
  permission_name: string,
  config: any
) => {
  return request({
    method: "post",
    url,
    data,
    ...config,
  });
};

export const patch = (
  url: string,
  data: any,
  permission_name: string,
  config: any
) => {
  return request({
    method: "patch",
    url,
    data,
    ...config,
  });
};

export const del = (
  url: string,
  data: any,
  permission_name: string,
  config: any
) => {
  return request({
    method: "delete",
    url,
    data,
    ...config,
  });
};
