import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import apiServices from "services/RequestHandler";
import { locations_list } from "utils/constants";
import { capitalize_string, convert_utc_to_user_timezone } from "utils/helper";

export interface EmployeeState {
  all_employees_paginate: any;
  all_employees_data: any;
  all_employees: any;
  export_employees: any;
  single_employee_data: any;
  create_employee_data: any;
  update_employee_data: any;
  delete_employee_data: any;
  line_managers: any;
  line_managers_data: any;
  get_all_employee_locations: any;
  get_all_employee_types: any;

  get_all_shifts: any;
  get_all_shifts_data: any;
  all_shifts_paginate: any;
  export_shifts: any;
  auto_selected_shift?: any;
  create_shift_data: any;
  update_shift_data: any;
  delete_shift_data: any;
  get_single_shift_data: any;

  get_all_holidays: any;
  get_all_holidays_data: any;
  get_all_holidays_list: any;
  all_holidays_paginate: any;
  export_holidays: any;
  create_holidays_data: any;
  update_holidays_data: any;
  get_single_holidays_data: any;
  delete_holidays: any;

  get_all_roster: any;
  get_all_roster_data: any;
  get_all_roster_paginate: any;
  export_roster: any;

  get_all_vacations: any;
  get_all_vacations_data: any;
  all_vacations_paginate: any;
  export_vacations: any;
  create_vacation_data: any;
  update_vacation_data: any;
  single_vacation_data: any;
  emp_unused_vacation_data: any;

  get_all_leaves: any;
  get_all_leaves_data: any;
  all_leaves_paginate: any;
  export_leaves: any;
  get_employee_leaves_data: any;
  get_employee_vacations: any;
  get_employee_sick_leaves: any;
  create_leave_data: any;
  update_leave_data: any;
  single_leave_data: any;

  activity: boolean;
}

interface APIParams {
  end_point: string;
  body: any;
}

const initialState: EmployeeState = {
  all_employees_paginate: [],
  all_employees_data: [],
  all_employees: [],
  export_employees: [],
  single_employee_data: {},
  create_employee_data: {},
  update_employee_data: {},
  delete_employee_data: {},
  line_managers: [],
  line_managers_data: [],
  get_all_employee_locations: [],
  get_all_employee_types: [],

  get_all_shifts: [],
  get_all_shifts_data: [],
  all_shifts_paginate: [],
  export_shifts: [],
  auto_selected_shift: {},
  delete_shift_data: {},
  create_shift_data: {},
  update_shift_data: {},
  get_single_shift_data: {},

  get_all_holidays: [],
  get_all_holidays_data: [],
  get_all_holidays_list: [],
  all_holidays_paginate: [],
  export_holidays: [],
  get_single_holidays_data: {},
  create_holidays_data: {},
  update_holidays_data: {},
  delete_holidays: {},

  get_all_roster: [],
  get_all_roster_data: [],
  get_all_roster_paginate: [],
  export_roster: [],

  get_all_vacations: [],
  get_all_vacations_data: [],
  all_vacations_paginate: [],
  export_vacations: [],
  create_vacation_data: {},
  update_vacation_data: {},
  single_vacation_data: {},
  emp_unused_vacation_data: {},

  get_all_leaves: [],
  get_all_leaves_data: [],
  all_leaves_paginate: [],
  export_leaves: [],
  get_employee_leaves_data: [],
  get_employee_vacations: [],
  get_employee_sick_leaves: [],
  create_leave_data: {},
  update_leave_data: {},
  single_leave_data: {},

  activity: false,
};

export const all_employees = createAsyncThunk(
  "/employees/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_employees = createAsyncThunk(
  "/employees/export",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const all_employee_types = createAsyncThunk(
  "/employee",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "employee");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_employee = createAsyncThunk(
  "/employees/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "employees");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_employee = createAsyncThunk(
  "/employees/create",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_employee = createAsyncThunk(
  "/employees/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const delete_employee = createAsyncThunk(
  "/employees/delete",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const line_managers = createAsyncThunk(
  "/employees/all-managers",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "employees");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const employee_locations = createAsyncThunk(
  "/locations",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "locations");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const all_shifts = createAsyncThunk(
  "/employee-shifts",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "shifts"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_shifts = createAsyncThunk(
  "/employee-shifts/export",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "shifts"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_shift = createAsyncThunk(
  "/shifts",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "shifts"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_shift = createAsyncThunk(
  "/shift/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "shifts"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const delete_shift = createAsyncThunk(
  "/shifts/delete",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "employees"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_shift = createAsyncThunk(
  "/shifts/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "shifts");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const all_holidays = createAsyncThunk(
  "/employee-holydays",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "holidays"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_holidays = createAsyncThunk(
  "/employee-holydays/export",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "shifts"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_holidays = createAsyncThunk(
  "/holidays",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "holidays"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_holidays = createAsyncThunk(
  "/holidays/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "holidays"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_holidays = createAsyncThunk(
  "/holydays/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "holydays");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const all_holidays_list = createAsyncThunk(
  "/employee-holydays/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "holydays"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const delete_holidays_from_api = createAsyncThunk(
  "/employee-holydays/delete",
  async (data: APIParams) => {
    try {
      let response = await apiServices.deleteFromApi(
        data.end_point,
        data.body,
        "holydays"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const all_rosters = createAsyncThunk(
  "/employee-rosters",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "roster"
      );

      return {
        ...response,
        user_locations: data.user_locations,
        user_departments: data.user_departments,
        all_departments: data.all_departments,
      };
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_rosters = createAsyncThunk(
  "/employee-rosters/export",
  async (data: any) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "roster"
      );
      return {
        ...response,
        user_locations: data.user_locations,
        user_departments: data.user_departments,
        all_departments: data.all_departments,
      };
    } catch (err) {
      console.log(err);
    }
  }
);

export const all_vacations = createAsyncThunk(
  "/employee-vacation/all",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "vacations"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_vacations = createAsyncThunk(
  "/employee-vacation/export",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "vacations"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_vacation = createAsyncThunk(
  "/employee-vacation/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "vacations");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const employee_unused_vacation = createAsyncThunk(
  "/employee-vacation/unused/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "vacations");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_vacation = createAsyncThunk(
  "/employee-vacation/create",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "vacation"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_vacation = createAsyncThunk(
  "/employee-vacation/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "vacation"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const all_leaves = createAsyncThunk(
  "/employee-leaves",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "leaves"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_leaves = createAsyncThunk(
  "/employee-leaves/export",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "leaves"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_employee_leave = createAsyncThunk(
  "/employee-leave/employee/:emp_id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "leaves");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const single_leave = createAsyncThunk(
  "/employee-leave/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "leaves");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_leave = createAsyncThunk(
  "/employee-leave/create",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "leave"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_leave = createAsyncThunk(
  "/employee-leave/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "vacation"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

const getWeekNumbersForDateRange = (startDate: any, endDate: any) => {
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);

  const weeks = [] as any;

  while (startMoment.isSameOrBefore(endMoment)) {
    const weekNumber = startMoment.isoWeek();
    if (!weeks.includes(weekNumber)) {
      weeks.push(weekNumber);
    }
    startMoment.add(1, "week");
  }

  return weeks;
};

export const employee_slicer = createSlice({
  name: "employees",
  initialState,
  reducers: {
    handle_clear_form: (state: EmployeeState, action: any) => {
      state.export_employees = [];
      state.single_employee_data = {};
      state.create_employee_data = {};
      state.update_employee_data = {};
      state.delete_employee_data = {};
      state.activity = false;
    },

    handle_clear_shift_data: (state: EmployeeState, action: any) => {
      state.export_shifts = [];
      state.get_single_shift_data = {};
      state.create_shift_data = {};
      state.update_shift_data = {};
      state.delete_shift_data = {};
      state.activity = false;
    },

    handle_clear_holidays_data: (state: EmployeeState, action: any) => {
      state.export_holidays = [];
      state.get_single_holidays_data = {};
      state.create_holidays_data = {};
      state.update_holidays_data = {};
      state.delete_holidays = {};
      state.get_all_holidays_list = [];
      state.activity = false;
    },

    handle_clear_vacations_data: (state: EmployeeState, action: any) => {
      state.get_all_vacations = [];
      state.get_all_vacations_data = [];
      state.export_vacations = [];
      state.create_vacation_data = {};
      state.update_vacation_data = {};
      state.single_vacation_data = {};
      state.activity = false;
    },

    handle_clear_leaves_data: (state: EmployeeState, action: any) => {
      state.export_leaves = [];
      state.get_all_leaves = [];
      state.get_all_leaves_data = [];
      state.all_leaves_paginate = [];
      state.create_leave_data = {};
      state.update_leave_data = {};
      state.single_leave_data = {};
      state.activity = false;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(all_employees.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_employees.fulfilled, (state, { payload }) => {
        try {
          let all_employees = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            all_employees.push([
              { name: item?._id ?? "Not Available", hidden: true },
              { name: item?.employee_no ?? "Not Available" },
              {
                name:
                  `${item?.firstname} ${item?.lastname ?? ""}` ||
                  "Not Available",
                alignment: "left",
              },
              {
                name: item?.designation_name ?? "Not Available",
                alignment: "left",
              },
              {
                name: item?.department_name ?? "Not Available",
                alignment: "left",
              },
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "#eb6a19",
                alignment: "center",
              },
              {
                name: item?.created_at
                  ? moment(item?.created_at).format("DD.MMM.YYYY")
                  : "Not Available",
                alignment: "center",
              },
              {
                name: item.added_by ?? "Not Available",
                alignment: "left",
              },
              {
                name:
                  item?.created_at === item?.updated_at
                    ? "Not Available"
                    : moment(item?.updated_at || "Not Available").format(
                        "DD.MMM.YYYY HH:mm:ss"
                      ),
                alignment: "center",
              },
              {
                name:
                  item?.modified_by === "administrator administrator"
                    ? "Admin"
                    : item?.modified_by || "Not Available",
                alignment: "left",
              },
            ]);
          }

          state.all_employees_paginate = payload;
          state.all_employees_data = payload?.data;
          state.all_employees = all_employees;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(export_employees.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(export_employees.fulfilled, (state, { payload }) => {
        try {
          let employees = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data?.[index];

            employees.push({
              "Employee No.": item?.employee_no ?? "Not Available",
              "Employee Name":
                `${item?.firstname} ${item?.lastname ?? ""}` || "Not Available",
              Designation: item?.designation_name ?? "Not Available",
              Department: item?.department_name ?? "Not Available",
              Status: item?.is_active ? "Active" : "Inactive",
              "Created At": moment(item?.created_at || "Not Available").format(
                "DD.MMM.YYYY HH:mm:ss"
              ),
              "Created By": item?.added_by || "Admin",
              "Modified At":
                item?.created_at === item?.updated_at
                  ? "Not Available"
                  : moment(item?.updated_at || "Not Available").format(
                      "DD.MMM.YYYY HH:mm:ss"
                    ),
              "Modified By":
                item?.modified_by === "administrator administrator"
                  ? "Admin"
                  : item?.modified_by || "Not Available",
            });
          }

          state.export_employees = {
            data: employees,
            callback_time: new Date(),
          };
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(single_employee.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_employee.fulfilled, (state, { payload }) => {
        try {
          state.single_employee_data = {
            ...payload.data,
            call_time: new Date(),
          };
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(create_employee.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_employee.fulfilled, (state, { payload }) => {
        try {
          state.create_employee_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_employee.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_employee.fulfilled, (state, { payload }) => {
        try {
          state.update_employee_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(delete_employee.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(delete_employee.fulfilled, (state, { payload }) => {
        try {
          state.delete_employee_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(line_managers.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(line_managers.fulfilled, (state, { payload }) => {
        try {
          state.line_managers_data = payload?.data;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(all_employee_types.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_employee_types.fulfilled, (state, { payload }) => {
        try {
          state.get_all_employee_types = payload?.data;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(employee_locations.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(employee_locations.fulfilled, (state, { payload }) => {
        try {
          let _locations = payload?.data?.sort((a: any, b: any) =>
            a.location_code > b.location_code ? 1 : -1
          );

          state.get_all_employee_locations = _locations;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(all_shifts.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_shifts.fulfilled, (state, { payload }) => {
        try {
          let get_all_shifts = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            get_all_shifts.push([
              { name: item?.shift_code ?? "Not Available", hidden: true },
              {
                name: item?.shift_name?.en ?? "Not Available",
                alignment: "left",
              },
              {
                name:
                  convert_utc_to_user_timezone(item?.shift_start_time) ??
                  "Not Available",
                alignment: "center",
              },
              {
                name:
                  convert_utc_to_user_timezone(item?.shift_end_time) ??
                  "Not Available",
                alignment: "center",
              },
              {
                name: item?.shift_desc?.en
                  ? item?.shift_desc?.en ?? "Not Available"
                  : "Not Available",
                alignment: "left",
              },
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                color: item?.is_active ? "#6EC531" : "#eb6a19",
                alignment: "center",
              },
              {
                name:
                  item?.employees?.length > 0 ? item?.employees?.length : "0",
                alignment: "center",
              },
              {
                name: moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              {
                name: item?.added_by ?? "Not Available",
                alignment: "left",
              },
              {
                name: item?.modified_by
                  ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                  : "Not Available",
                alignment: "center",
              },
              {
                name: item?.modified_by ?? "Not Available",
                alignment: "left",
              },
            ]);
          }

          const sortedShifts = payload?.data?.sort((a: any, b: any) =>
            a?.shift_start_time < b?.shift_start_time ? -1 : 1
          );

          state.get_all_shifts_data = payload?.data;
          state.get_all_shifts = get_all_shifts;
          state.all_shifts_paginate = payload;
          state.auto_selected_shift = sortedShifts?.[0];
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(export_shifts.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(export_shifts.fulfilled, (state, { payload }) => {
        try {
          let export_shifts_data = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            export_shifts_data.push({
              "Shift Code": item?.shift_code ?? "Not Available",
              "Shift Name": item?.shift_name?.en ?? "Not Available",
              "Start Time":
                convert_utc_to_user_timezone(item?.shift_start_time) ??
                "Not Available",
              "End Time":
                convert_utc_to_user_timezone(item?.shift_end_time) ??
                "Not Available",
              Description: item?.shift_desc?.en
                ? item?.shift_desc?.en ?? "Not Available"
                : "Not Available",
              Status: item?.is_active ? "Active" : "Inactive",
              "Assigned Employees":
                item?.employees?.length > 0
                  ? `${item?.employees?.length}`
                  : "0",
              "Created At": moment(item?.created_at).format(
                "DD.MMM.YYYY HH:mm:ss"
              ),
              "Created By": item?.added_by ?? "Not Available",
              "Updated At": item?.modified_by
                ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                : "Not Available",
              "Updated By": item?.modified_by ?? "Not Available",
            });
          }

          state.export_shifts = export_shifts_data;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(create_shift.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_shift.fulfilled, (state, { payload }) => {
        try {
          state.create_shift_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_shift.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_shift.fulfilled, (state, { payload }) => {
        try {
          state.update_shift_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(delete_shift.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(delete_shift.fulfilled, (state, { payload }) => {
        try {
          state.delete_shift_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(single_shift.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_shift.fulfilled, (state, { payload }) => {
        try {
          state.get_single_shift_data = {
            ...payload.data,
            call_time: new Date(),
          };
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(all_holidays.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_holidays.fulfilled, (state, { payload }) => {
        try {
          let get_all_holidays = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            get_all_holidays.push([
              {
                name: item?.holyday_code ?? "Not Available",
                alignment: "left",
              },
              {
                name: item?.holyday_name?.en ?? "Not Available",
                alignment: "left",
              },
              {
                name:
                  moment(item?.holyday_start_date).format("DD.MMM.YYYY") ??
                  "Not Available",
                alignment: "center",
              },
              {
                name:
                  moment(item?.holyday_end_date).format("DD.MMM.YYYY") ??
                  "Not Available",
                alignment: "center",
              },
              {
                name: item?.holyday_desc?.en
                  ? item?.holyday_desc?.en ?? "Not Available"
                  : "Not Available",
                alignment: "left",
              },
              {
                name: item?.holyday_lists?.length ?? "0",
                alignment: "center",
              },
              {
                name: moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              {
                name: item?.added_by ?? "Not Available",
                alignment: "left",
              },
              {
                name: item?.modified_by
                  ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                  : "Not Available",
                alignment: "center",
              },
              {
                name: item?.modified_by ?? "Not Available",
                alignment: "left",
              },
            ]);
          }

          state.get_all_holidays_data = payload?.data;
          state.get_all_holidays = get_all_holidays;
          state.all_holidays_paginate = payload;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(export_holidays.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(export_holidays.fulfilled, (state, { payload }) => {
        try {
          let get_export_holidays = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            get_export_holidays.push({
              "Holiday Code": item?.holyday_code ?? "Not Available",
              "Holiday Name": item?.holyday_name?.en ?? "Not Available",
              "From Date":
                moment(item?.holyday_start_date).format("DD.MMM.YYYY") ??
                "Not Available",
              "To Date":
                moment(item?.holyday_end_date).format("DD.MMM.YYYY") ??
                "Not Available",
              Description: item?.holyday_desc?.en
                ? item?.holyday_desc?.en ?? "Not Available"
                : "Not Available",
              "Total Days": item?.holyday_lists?.length ?? "0",
              "Created At": moment(item?.created_at).format(
                "DD.MMM.YYYY HH:mm:ss"
              ),
              "Created By": item?.added_by ?? "Not Available",
              "Updated At": item?.modified_by
                ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                : "Not Available",
              "Updated By": item?.modified_by ?? "Not Available",
            });
          }

          state.export_holidays = get_export_holidays;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(create_holidays.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_holidays.fulfilled, (state, { payload }) => {
        try {
          state.create_holidays_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_holidays.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_holidays.fulfilled, (state, { payload }) => {
        try {
          state.update_holidays_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(single_holidays.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_holidays.fulfilled, (state, { payload }) => {
        try {
          state.get_single_holidays_data = {
            ...payload.data,
            call_time: new Date(),
          };
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(all_holidays_list.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_holidays_list.fulfilled, (state, { payload }) => {
        try {
          state.get_all_holidays_list = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(delete_holidays_from_api.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(delete_holidays_from_api.fulfilled, (state, { payload }) => {
        try {
          state.delete_holidays = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(all_rosters.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_rosters.fulfilled, (state, { payload }) => {
        try {
          let get_all_roster = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            let location_name = locations_list?.filter(
              (x: any) => x?.code === item?.location_code
            )?.[0]?.label;

            let default_locations = [] as any;
            let user_locations = payload?.user_locations;
            let location_codes = user_locations?.map(
              (j: any) => j?.location_code
            );

            if (location_codes?.length > 0) {
              default_locations = locations_list?.filter((_location: any) =>
                location_codes?.includes(_location?.code)
              );
            }

            const allowed_locations = default_locations?.some(
              (x: any) => Number(x?.code) === Number(item?.location_code)
            );

            let default_departments = [] as any;
            let user_departments = payload?.user_departments;
            let department_names = user_departments?.map((j: any) =>
              j?.en_department_name?.toLowerCase()
            );

            if (department_names?.length > 0) {
              default_departments = payload?.all_departments?.filter(
                (department: any) =>
                  department_names?.includes(
                    department?.department_name?.toLowerCase()
                  )
              );
            }

            const allowed_departments = default_departments?.some(
              (x: any) =>
                x?.department_name?.toLowerCase() ===
                item?.department_name?.toLowerCase()
            );

            if (allowed_locations && allowed_departments) {
              get_all_roster.push([
                { name: item?.id, hidden: true },
                {
                  name:
                    `${moment(item?.roster_start_date).format(
                      "DD.MMM.YYYY"
                    )} - ${moment(item?.roster_end_date).format(
                      "DD.MMM.YYYY"
                    )}` || "Not Available",
                  alignment: "left",
                },
                {
                  name: `${"Week"} ${getWeekNumbersForDateRange(
                    item?.roster_start_date,
                    item?.roster_end_date
                  )}`,
                  alignment: "center",
                },
                {
                  name: location_name ?? "Not Available",
                  alignment: "left",
                },
                {
                  name: item?.department_name ?? "Not Available",
                  alignment: "left",
                },
                {
                  name:
                    item?.total_employees === 0 ? "0" : item?.total_employees,
                  alignment: "center",
                },
                {
                  name: moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss"),
                  alignment: "center",
                },
                {
                  name: item?.added_by ?? "Not Available",
                  alignment: "left",
                },
                {
                  name: item?.modified_by
                    ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                    : "Not Available",
                  alignment: "center",
                },
                {
                  name: item?.modified_by ?? "Not Available",
                  alignment: "left",
                },
              ]);
            }
          }

          state.get_all_roster_data = payload?.data;
          state.get_all_roster = get_all_roster;
          state.get_all_roster_paginate = payload;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(export_rosters.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(export_rosters.fulfilled, (state, { payload }) => {
        try {
          let export_roster = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];
            let location_name = locations_list?.filter(
              (x: any) => x?.code === item?.location_code
            )?.[0]?.label;

            let default_locations = [] as any;
            let user_locations = payload?.user_locations;
            let location_codes = user_locations?.map(
              (j: any) => j?.location_code
            );

            if (location_codes?.length > 0) {
              default_locations = locations_list?.filter((_location: any) =>
                location_codes?.includes(_location?.code)
              );
            }

            const allowed_locations = default_locations?.some(
              (x: any) => Number(x?.code) === Number(item?.location_code)
            );

            let default_departments = [] as any;
            let user_departments = payload?.user_departments;
            let department_names = user_departments?.map((j: any) =>
              j?.en_department_name?.toLowerCase()
            );

            if (department_names?.length > 0) {
              default_departments = payload?.all_departments?.filter(
                (department: any) =>
                  department_names?.includes(
                    department?.department_name?.toLowerCase()
                  )
              );
            }

            const allowed_departments = default_departments?.some(
              (x: any) =>
                x?.department_name?.toLowerCase() ===
                item?.department_name?.toLowerCase()
            );

            if (allowed_locations && allowed_departments) {
              export_roster.push({
                Name:
                  `${moment(item?.roster_start_date).format(
                    "DD.MMM.YYYY"
                  )} - ${moment(item?.roster_end_date).format(
                    "DD.MMM.YYYY"
                  )}` || "Not Available",
                Week: `${"Week"} ${getWeekNumbersForDateRange(
                  item?.roster_start_date,
                  item?.roster_end_date
                )}`,
                Location: location_name ?? "Not Available",
                Department: item?.department_name ?? "Not Available",
                "Total Employees":
                  item?.total_employees === 0 ? "0" : item?.total_employees,
                "Created At": moment(item?.created_at).format(
                  "DD.MMM.YYYY HH:mm:ss"
                ),
                "Created By": item?.added_by ?? "Not Available",
                "Modified At": item?.modified_by
                  ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                  : "Not Available",
                "Modified By": item?.modified_by ?? "Not Available",
              });
            }
          }

          state.export_roster = export_roster;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(all_vacations.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_vacations.fulfilled, (state, { payload }) => {
        try {
          let get_all_vacations = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            get_all_vacations.push([
              { name: item?._id ?? "Not Available", hidden: true },
              {
                name: item?.employee_no ?? "Not Available",
                alignment: "left",
              },
              {
                name:
                  item?.firstname && item?.lastname
                    ? _.capitalize(item?.firstname) +
                      " " +
                      _.capitalize(item?.lastname)
                    : item?.firstname
                    ? _.capitalize(item?.firstname)
                    : "Not Available",
                alignment: "left",
              },
              {
                name: item?.allocated_leaves ?? "Not Available",
                alignment: "center",
              },
              {
                name: item?.unused_leaves ?? "Not Available",
                alignment: "center",
              },
              {
                name: item?.total_leaves ?? "Not Available",
                alignment: "center",
              },
              {
                name:
                  moment(item?.from_date).format("DD.MMM.YYYY") ??
                  "Not Available",
                alignment: "center",
              },
              {
                name:
                  moment(item?.to_date).format("DD.MMM.YYYY") ??
                  "Not Available",
                alignment: "center",
              },
              {
                name: moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              {
                name: item?.added_by ?? "Not Available",
                alignment: "left",
              },
              {
                name: item?.modified_by
                  ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                  : "Not Available",
                alignment: "center",
              },
              {
                name: item?.modified_by ?? "Not Available",
                alignment: "left",
              },
            ]);
          }

          state.get_all_vacations_data = payload?.data;
          state.get_all_vacations = get_all_vacations;
          state.all_vacations_paginate = payload;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(export_vacations.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(export_vacations.fulfilled, (state, { payload }) => {
        try {
          let export_vacations = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            export_vacations.push({
              "Employee No.": item?.employee_no ?? "Not Available",
              "Employee Name":
                item?.firstname && item?.lastname
                  ? _.capitalize(item?.firstname) +
                    " " +
                    _.capitalize(item?.lastname)
                  : item?.firstname
                  ? _.capitalize(item?.firstname)
                  : "Not Available",
              "Allocated Vacations": item?.allocated_leaves ?? "Not Available",
              "Unused Vacations": item?.unused_leaves ?? "Not Available",
              "Total Vacations": item?.total_leaves ?? "Not Available",
              "Vacation Start Date":
                moment(item?.from_date).format("DD.MMM.YYYY") ??
                "Not Available",
              "Vacation End Date":
                moment(item?.to_date).format("DD.MMM.YYYY") ?? "Not Available",
              "Created At": moment(item?.created_at).format(
                "DD.MMM.YYYY HH:mm:ss"
              ),
              "Created By": item?.added_by ?? "Not Available",
              "Modified At": item?.modified_by
                ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                : "Not Available",
              "Modified By": item?.modified_by ?? "Not Available",
            });
          }

          state.export_vacations = export_vacations;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(single_vacation.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_vacation.fulfilled, (state, { payload }) => {
        try {
          state.single_vacation_data = {
            ...payload.data,
            call_time: new Date(),
          };
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(employee_unused_vacation.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(employee_unused_vacation.fulfilled, (state, { payload }) => {
        try {
          state.emp_unused_vacation_data = {
            ...payload.data,
            call_time: new Date(),
          };
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(create_vacation.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_vacation.fulfilled, (state, { payload }) => {
        try {
          state.create_vacation_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_vacation.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_vacation.fulfilled, (state, { payload }) => {
        try {
          state.update_vacation_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(all_leaves.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_leaves.fulfilled, (state, { payload }) => {
        try {
          let get_all_leaves = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            get_all_leaves.push([
              { name: item?._id ?? "Not Available", hidden: true },
              {
                name: item?.application_code ?? "Not Available",
                alignment: "left",
              },
              {
                name: item?.employee_no ?? "Not Available",
                alignment: "left",
              },
              {
                name:
                  item?.firstname && item?.lastname
                    ? _.capitalize(item?.firstname) +
                      " " +
                      _.capitalize(item?.lastname)
                    : item?.firstname
                    ? _.capitalize(item?.firstname)
                    : "Not Available",
                alignment: "left",
              },
              {
                name: item?.department_name ?? "Not Available",
                alignment: "left",
              },
              {
                name: capitalize_string(item?.leave_type) ?? "Not Available",
                alignment: "left",
              },
              {
                name: capitalize_string(item?.paid_unpaid) ?? "Not Available",
                alignment: "left",
              },
              {
                name:
                  moment(item?.start_date).format("DD.MMM.YYYY") ??
                  "Not Available",
                alignment: "center",
              },
              {
                name:
                  moment(item?.end_date).format("DD.MMM.YYYY") ??
                  "Not Available",
                alignment: "center",
              },
              {
                name:
                  moment(item?.start_date).format("DD.MMM.YYYY") ===
                  moment(item?.end_date).format("DD.MMM.YYYY")
                    ? "01"
                    : Number(item?.total_days) <= 9
                    ? "0" + Number(item?.total_days) || "Not Available"
                    : Number(item?.total_days) ?? "Not Available",
                alignment: "center",
              },
              {
                name: capitalize_string(item?.leave_status) ?? "Not Available",
                alignment: "center",
                status: true,
              },
              {
                name: moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              {
                name: item?.added_by ?? "Not Available",
                alignment: "left",
              },
              {
                name: item?.modified_by
                  ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                  : "Not Available",
                alignment: "center",
              },
              {
                name: item?.modified_by ?? "Not Available",
                alignment: "left",
              },
            ]);
          }

          state.get_all_leaves_data = payload?.data;
          state.get_all_leaves = get_all_leaves;
          state.all_leaves_paginate = payload;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(export_leaves.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(export_leaves.fulfilled, (state, { payload }) => {
        try {
          let export_leaves = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            export_leaves.push({
              "Application #": item?.application_code ?? "Not Available",
              "Employee Code": item?.employee_no ?? "Not Available",
              "Employee Name":
                item?.firstname && item?.lastname
                  ? _.capitalize(item?.firstname) +
                    " " +
                    _.capitalize(item?.lastname)
                  : item?.firstname
                  ? _.capitalize(item?.firstname)
                  : "Not Available",
              Department: item?.department_name ?? "Not Available",
              "Leave Type":
                capitalize_string(item?.leave_type) ?? "Not Available",
              "Paid or Unpaid":
                capitalize_string(item?.paid_unpaid) ?? "Not Available",
              "Leave Date From":
                moment(item?.start_date).format("DD.MMM.YYYY") ??
                "Not Available",
              "Leave Date To":
                moment(item?.end_date).format("DD.MMM.YYYY") ?? "Not Available",
              "Total Days":
                moment(item?.start_date).format("DD.MMM.YYYY") ===
                moment(item?.end_date).format("DD.MMM.YYYY")
                  ? "01"
                  : Number(item?.total_days) <= 9
                  ? "0" + Number(item?.total_days) || "Not Available"
                  : Number(item?.total_days) ?? "Not Available",
              "Leave Status":
                capitalize_string(item?.leave_status) ?? "Pending",
              "Created At": moment(item?.created_at).format(
                "DD.MMM.YYYY HH:mm:ss"
              ),
              "Created By": item?.added_by ?? "Not Available",
              "Modified At": item?.modified_by
                ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                : "Not Available",
              "Modified By": item?.modified_by ?? "Not Available",
            });
          }

          state.export_leaves = export_leaves;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(single_employee_leave.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_employee_leave.fulfilled, (state, { payload }) => {
        try {
          let get_employee_sick_leaves = [] as any;
          let get_employee_vacations = [] as any;

          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data[index];

            if (item?.leave_type === "sick leave") {
              get_employee_sick_leaves.push([
                { name: item?._id ?? "Not Available", hidden: true },
                {
                  name: item?.application_code ?? "Not Available",
                  alignment: "left",
                },
                {
                  name:
                    moment(item?.start_date).format("DD.MMM.YYYY") ??
                    "Not Available",
                  alignment: "center",
                },
                {
                  name:
                    moment(item?.end_date).format("DD.MMM.YYYY") ??
                    "Not Available",
                  alignment: "center",
                },
                {
                  name:
                    moment(item?.start_date).format("DD.MMM.YYYY") ===
                    moment(item?.end_date).format("DD.MMM.YYYY")
                      ? "01"
                      : Number(item?.total_days) <= 9
                      ? "0" + Number(item?.total_days) || "Not Available"
                      : Number(item?.total_days) ?? "Not Available",
                  alignment: "center",
                },
                {
                  name: capitalize_string(item?.reason) ?? "Not Available",
                  alignment: "left",
                },
                {
                  name: capitalize_string(item?.paid_unpaid) ?? "Not Available",
                  alignment: "left",
                },
                {
                  name:
                    capitalize_string(item?.leave_status) ?? "Not Available",
                  alignment: "center",
                  status: true,
                },
                {
                  name: capitalize_string(item?.modified_by) ?? "Not Available",
                  alignment: "left",
                },
              ]);
            } else {
              get_employee_vacations.push([
                { name: item?._id ?? "Not Available", hidden: true },
                {
                  name: item?.application_code ?? "Not Available",
                  alignment: "left",
                },
                {
                  name:
                    moment(item?.start_date).format("DD.MMM.YYYY") ??
                    "Not Available",
                  alignment: "center",
                },
                {
                  name:
                    moment(item?.end_date).format("DD.MMM.YYYY") ??
                    "Not Available",
                  alignment: "center",
                },
                {
                  name:
                    moment(item?.start_date).format("DD.MMM.YYYY") ===
                    moment(item?.end_date).format("DD.MMM.YYYY")
                      ? "01"
                      : Number(item?.total_days) <= 9
                      ? "0" + Number(item?.total_days) || "Not Available"
                      : Number(item?.total_days) ?? "Not Available",
                  alignment: "center",
                },
                {
                  name: capitalize_string(item?.reason) ?? "Not Available",
                  alignment: "left",
                },
                {
                  name: capitalize_string(item?.paid_unpaid) ?? "Not Available",
                  alignment: "left",
                },
                {
                  name:
                    capitalize_string(item?.leave_status) ?? "Not Available",
                  alignment: "center",
                  status: true,
                },
                {
                  name: capitalize_string(item?.modified_by) ?? "Not Available",
                  alignment: "left",
                },
              ]);
            }
          }

          state.get_employee_leaves_data = payload?.data;
          state.get_employee_sick_leaves = get_employee_sick_leaves;
          state.get_employee_vacations = get_employee_vacations;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(single_leave.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(single_leave.fulfilled, (state, { payload }) => {
        try {
          state.single_leave_data = {
            ...payload.data,
            call_time: new Date(),
          };
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(create_leave.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_leave.fulfilled, (state, { payload }) => {
        try {
          state.create_leave_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_leave.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_leave.fulfilled, (state, { payload }) => {
        try {
          state.update_leave_data = { ...payload, call_time: new Date() };
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      });
  },
});

export const {
  handle_clear_form,
  handle_clear_shift_data,
  handle_clear_holidays_data,
  handle_clear_vacations_data,
  handle_clear_leaves_data,
} = employee_slicer.actions;

export default employee_slicer.reducer;
