import {
  language_translation,
  locked_account_translation,
  reusable_translation,
  search_filters_translation,
  status_translation,
  two_factor_atuhentication_translation,
  material_request_priority_translation,
  attendence_translation,
  week_days_translation,
  marital_status_translation,
  employees_details_translation,
  leave_type_translation,
} from "translation";
import { set_localize_content } from "./helper";

export const export_label = "EXPORT";
export const print_label = "PRINT";
export const download_label = "DOWNLOAD";

export const default_label_colors = {
  color_e: "#000000",
  color_f: "#666666",
  color_g: "#eeeeee",
  color_h: "#f8f8f8",
};

export const date_range_filters = [
  {
    label: set_localize_content(search_filters_translation?.today, "en"),
    value: "today",
  },
  {
    label: set_localize_content(search_filters_translation?.this_week, "en"),
    value: "this_week",
  },
  {
    label: set_localize_content(search_filters_translation?.this_week_to_date, "en"),
    value: "this_week_to_date",
  },
  {
    label: set_localize_content(search_filters_translation?.this_month, "en"),
    value: "this_month",
  },
  {
    label: set_localize_content(search_filters_translation?.this_month_to_date, "en"),
    value: "this_month_to_date",
  },
  {
    label: set_localize_content(search_filters_translation?.this_quarter, "en"),
    value: "this_quarter",
  },
  {
    label: set_localize_content(search_filters_translation?.this_quarter_to_date, "en"),
    value: "this_quarter_to_date",
  },
  {
    label: set_localize_content(search_filters_translation?.this_year, "en"),
    value: "this_year",
  },
  {
    label: set_localize_content(search_filters_translation?.this_year_to_date, "en"),
    value: "this_year_to_date",
  },
  {
    label: set_localize_content(
      search_filters_translation?.last_7_days,
      "en"
    ),
    value: "last_seven_days",
  },
  {
    label: set_localize_content(search_filters_translation?.yesterday, "en"),
    value: "yesterday",
  },
  {
    label: set_localize_content(
      search_filters_translation?.last_week,
      "en"
    ),
    value: "last_week",
  },
  {
    label: set_localize_content(
      search_filters_translation?.last_week_to_date,
      "en"
    ),
    value: "last_week_to_date",
  },
  {
    label: set_localize_content(
      search_filters_translation?.last_month,
      "en"
    ),
    value: "last_month",
  },
  {
    label: set_localize_content(
      search_filters_translation?.last_month_to_date,
      "en"
    ),
    value: "last_month_to_date",
  },
  {
    label: set_localize_content(
      search_filters_translation?.last_quarter,
      "en"
    ),
    value: "last_quarter",
  },
  {
    label: set_localize_content(
      search_filters_translation?.last_quarter_to_date,
      "en"
    ),
    value: "last_quarter_to_date",
  },
  {
    label: set_localize_content(
      search_filters_translation?.last_year,
      "en"
    ),
    value: "last_year",
  },
  {
    label: set_localize_content(
      search_filters_translation?.last_year_to_date,
      "en"
    ),
    value: "last_year_to_date",
  },
  {
    label: set_localize_content(search_filters_translation?.custom_date, "en"),
    value: "custom date",
  },
];

export const stores_list = [
  { label: "Location 1", value: "Location 1", code: 10001 },
  { label: "Location 2", value: "Location 2", code: 10002 },
];

export const locations_list = [
  { label: "Location 1", value: "Location 1", code: 10001 },
  { label: "Location 2", value: "Location 2", code: 10002 },
];

export const time_duration = [
  { label: set_localize_content(search_filters_translation?.daily, "en"), value: "day" },
  { label: set_localize_content(search_filters_translation?.weekly, "en"), value: "week" },
  { label: set_localize_content(search_filters_translation?.monthly, "en"), value: "month" },
  { label: set_localize_content(search_filters_translation?.yearly, "en"), value: "year" },
];

export const months = [
  "January",
  "Febraury",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const conditions = [
  { label: set_localize_content(reusable_translation?.yes, "en"), value: true },
  { label: set_localize_content(reusable_translation?.no, "en"), value: false },
];

export const languages = [
  {
    label: set_localize_content(language_translation?.azeri, "en"),
    value: "az",
  },
  {
    label: set_localize_content(language_translation?.english, "en"),
    value: "en",
  },
  {
    label: set_localize_content(language_translation?.russian, "en"),
    value: "ru",
  },
];

export const statuses = [
  {
    label: set_localize_content(status_translation?.active, "en"),
    value: true,
  },
  {
    label: set_localize_content(status_translation?.inactive, "en"),
    value: false,
  },
];

export const two_factor_auths = [
  {
    label: set_localize_content(
      two_factor_atuhentication_translation?.enable,
      "en"
    ),
    value: true,
  },
  {
    label: set_localize_content(
      two_factor_atuhentication_translation?.disable,
      "en"
    ),
    value: false,
  },
];

export const locked_account = [
  {
    label: set_localize_content(locked_account_translation?.locked, "en"),
    value: true,
  },
  {
    label: set_localize_content(locked_account_translation?.unlocked, "en"),
    value: false,
  },
];

export const and_or_conditions = [
  {
    label: set_localize_content(reusable_translation?.and, "en"),
    value: "AND",
  },
  { label: set_localize_content(reusable_translation?.or, "en"), value: "OR" },
];

export const material_request_priority = [
  {
    label: set_localize_content(
      material_request_priority_translation?.high,
      "en"
    ),
    value: "High",
  },
  {
    label: set_localize_content(
      material_request_priority_translation?.normal,
      "en"
    ),
    value: "Normal",
  },
  {
    label: set_localize_content(
      material_request_priority_translation?.low,
      "en"
    ),
    value: "Low",
  },
];

export const employee_attendence = [
  { label: set_localize_content(attendence_translation?.present, "en"), value: "present" },
  { label: set_localize_content(attendence_translation?.leave, "en"), value: "leave" },
  { label: set_localize_content(attendence_translation?.absent, "en"), value: "absent" },
  { label: set_localize_content(attendence_translation?.not_available, "en"), value: null },
]

export const arithmetic_operations = [
  { label: ">=", value: ">=" },
  { label: ">", value: ">" },
  { label: "=", value: "=" },
  { label: "<=", value: "<=" },
  { label: "<", value: "<" },
];

export const week_days = [
  { label: set_localize_content(week_days_translation?.monday, "en"), value: "Monday" },
  { label: set_localize_content(week_days_translation?.tuesday, "en"), value: "Tuesday" },
  { label: set_localize_content(week_days_translation?.wednesday, "en"), value: "Wednesday" },
  { label: set_localize_content(week_days_translation?.thursday, "en"), value: "Thursday" },
  { label: set_localize_content(week_days_translation?.friday, "en"), value: "Friday" },
  { label: set_localize_content(week_days_translation?.saturday, "en"), value: "Saturday" },
  { label: set_localize_content(week_days_translation?.sunday, "en"), value: "Sunday" },
]

export const colors = [
  { label: "Brown", value: "SaddleBrown" },
  { label: "Cyan", value: "DarkCyan" },
  { label: "Green", value: "DarkGreen" },
  { label: "Indigo", value: "Indigo" },
  { label: "Navy", value: "Navy" },
  { label: "Olive", value: "Olive" },
  { label: "Orange", value: "DarkOrange" },
  { label: "Orange Red", value: "OrangeRed" },
  { label: "Pink", value: "DeepPink" },
  { label: "Purple", value: "Purple" },
  { label: "Red", value: "DarkRed" },
  { label: "Slate Gray", value: "DarkSlateGray" },
];

export const location_static_ips = {
  "85.132.36.4": "97150", //Call-Center (Azintex),
  "185.52.131.74": "97150", // Bakcell WiMax
  "85.132.110.115": "97405", // Narimanov Monument
  "95.86.133.138": "97406", // Sahil Center
  "5.134.48.173": "97407", // Ganjlik
  "37.26.2.54": "97408", // Hazi Aslanov
  "78.109.53.252": "97409", // Gara Garayev
  "37.26.3.85": "97410", // Microdistrict
  "185.222.94.18": "97411", // Khirdalan
  "31.171.80.75": "97412", // Sumqayit
  "85.132.110.92": "97413", // Mardakan
  "185.26.184.220": "97414", // Ganja
  "192.140.145.96": "97150", // Hitech Prime
} as any;

export const marital_status = [
  { label: set_localize_content(marital_status_translation?.single, "en"), value: "single" },
  { label: set_localize_content(marital_status_translation?.married, "en"), value: "married" },
  { label: set_localize_content(marital_status_translation?.divorced, "en"), value: "divorced" },
  { label: set_localize_content(marital_status_translation?.widowed, "en"), value: "widowed" },
]

export const blood_group = [
  { label: "A+", value: "A+" },
  { label: "A-", value: "A-" },
  { label: "B+", value: "B+" },
  { label: "B-", value: "B-" },
  { label: "AB+", value: "AB+" },
  { label: "AB-", value: "AB-" },
  { label: "O+", value: "O+" },
  { label: "O-", value: "O-" },
]

export const termination_or_resignation = [
  { label: set_localize_content(employees_details_translation?.termination, "en"), value: "termination" },
  { label: set_localize_content(employees_details_translation?.resignation, "en"), value: "resignation" },
]

export const leave_types = [
  { label: set_localize_content(leave_type_translation?.vacation, "en"), value: "vacation" },
  { label: set_localize_content(leave_type_translation?.sick_leave, "en"), value: "sick leave" },
]

export const paid_or_unpaid = [
  { label: set_localize_content(leave_type_translation?.paid, "en"), value: "paid" },
  { label: set_localize_content(leave_type_translation?.unpaid, "en"), value: "unpaid" },
]