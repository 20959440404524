import moment from "moment";
import { getDataFromLocalStorage } from "./localStore.utils";
import axios from "axios";

export const _secretKey = "d0m!no$-P!zz@";
export const _secretData = "@zerBa!j@n";

export const current_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const number_format_without_currency = (value: number) =>
  Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);

export const currency_format = (value: number) =>
  new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "AZN",
  }).format(value);

const num_to_word = {
  0: "Zero",
  1: "One",
  2: "Two",
  3: "Three",
  4: "Four",
  5: "Five",
  6: "Six",
  7: "Seven",
  8: "Eight",
  9: "Nine",
  10: "Ten",
  11: "Eleven",
  12: "Twelve",
  13: "Thirteen",
  14: "Fourteen",
  15: "Fifteen",
  16: "Sixteen",
  17: "Seventeen",
  18: "Eighteen",
  19: "Nineteen",
  20: "Twenty",
  30: "Thirty",
  40: "Forty",
  50: "Fifty",
  60: "Sixty",
  70: "Seventy",
  80: "Eighty",
  90: "Ninety",
} as any;

const placement = {
  100: " Hundred",
  1000: " Thousand",
  1000000: " Million",
  1000000000000: " Trillion",
};

export const convert_num_to_word = (num: any): any => {
  const limiter = (val: any) => (num < val) as any;
  const limiterIndex = Object.keys(placement).findIndex(limiter) as any;
  const limiterKey = Object.keys(placement)[limiterIndex] as any;
  const limiterVal = Object.values(placement)[limiterIndex - 1] as any;
  const limiterMod = Object.keys(placement)[limiterIndex - 1] as any;

  if (num_to_word[num]) {
    return num_to_word[num];
  }

  if (num < 100) {
    const whole = Math.floor(num / 10) * 10;
    const part = num % 10;
    return num_to_word[whole] + " " + num_to_word[part];
  }

  if (num < limiterKey) {
    const whole = Math.floor(num / limiterMod);
    const part = num % limiterMod;
    if (part === 0) {
      return convert_num_to_word(whole) + limiterVal;
    }
    return (
      convert_num_to_word(whole) +
      limiterVal +
      " and " +
      convert_num_to_word(part)
    );
  }
};

export const capitalize_string = (word: string): string => {
  return word?.charAt(0)?.toUpperCase() + word?.slice(1);
};

export const time_ago = (date: any) => {
  var seconds = Math.floor((Number(new Date()) - Number(date)) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years ago";
  }

  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months ago";
  }

  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days ago";
  }

  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours ago";
  }

  interval = seconds / 60;

  if (interval > 1) {
    return Math.floor(interval) + " minutes ago";
  }

  return Math.floor(seconds) + " seconds ago";
};

export const can_perform_action = (module_name: any) => {
  try {
    if (!module_name) {
      return false;
    }

    const user = getDataFromLocalStorage("user");
    const permissions = user?.permissions;
    let perm = permissions?.filter(
      (i: any) => i?.module_name === module_name
    )[0];

    if (
      perm?.read ||
      perm?.write ||
      perm?.update ||
      perm?.delete ||
      perm?.print ||
      perm?.download
    ) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};

export const set_localize_content = (obj: any, language: string) => {
  return obj[language];
};

export const remove_null_keys = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === "" || obj[key] === undefined) {
      delete obj[key];
    }
  });
};

export const is_time_in_range = (time: any, range: any) => {
  try {
    const startTime = moment(`${time.ts_start}`, "HH:mm");
    const endTime = moment(`${time.ts_end}`, "HH:mm");
    const startSlot = moment(range.start_slot, "HH:mm");
    const endSlot = moment(range.end_slot, "HH:mm");

    let openingHourFaultTime = new Date(
      startTime.format("YYYY-MM-DDTHH:mm:ss")
    ).getTime();

    let closingHourFaultTime = new Date(
      endTime.format("YYYY-MM-DDTHH:mm:ss")
    ).getTime();

    let systemDate = new Date(
      startSlot.format("YYYY-MM-DDTHH:mm:ss")
    ).getTime();

    let systemEndDate = new Date(
      endSlot.format("YYYY-MM-DDTHH:mm:ss")
    ).getTime();

    if (
      openingHourFaultTime < systemEndDate &&
      closingHourFaultTime > systemDate
    ) {
      return true;
    }

    if (
      openingHourFaultTime < systemEndDate &&
      closingHourFaultTime >= systemDate
    ) {
      return true;
    }

    if (startTime.isBetween(startSlot, endSlot)) {
      return true;
    }

    if (endTime.isBetween(startSlot, endSlot)) {
      return true;
    }

    if (startSlot.isBetween(startTime, endTime)) {
      return true;
    }

    if (
      openingHourFaultTime > closingHourFaultTime &&
      (systemDate >= openingHourFaultTime || systemDate <= closingHourFaultTime)
    ) {
      return true;
    }

    return false;
  } catch (error) {
    console.log(error);
  }
};

export const generate_hours_interval = (
  startHour: any,
  endHour: any,
  interval: any
) => {
  let x = {
    slotInterval: interval,
    openTime: startHour,
    closeTime: endHour,
  };
  let startTime = moment(x.openTime, "HH:mm");
  let endTime = moment(x.closeTime, "HH:mm").add(1, "days");

  let allTimes = [];

  while (startTime < endTime) {
    //Push times
    allTimes.push(startTime.format("HH:mm"));
    //Add interval of 30 minutes
    startTime.add(x.slotInterval, "minutes");
  }

  return allTimes;
};

export const change_time_in_minutes = (timex: any) => {
  if (timex !== null) {
    var time = timex.split(":");
    return Number(time[0]) * 60 + Number(time[1]);
  }
};

export const change_minutes_to_hours = (minute: any) => {
  const minutes = minute % 60;
  const hours = Math.floor(minute / 60);

  return `${time_format(hours)}:${time_format(minutes)}`;
};

export const time_format = (num: any) => {
  return num.toString().padStart(2, "0");
};

export const get_ip = async () => {
  const res = await axios.get("https://api.ipify.org?format=json");

  return res.data.ip;
};

export const get_first_monday_of_year = async () => {
  let _year = moment().startOf("year").format("YYYY-MM-DD");
  const firstDayOfYear = moment(_year);
  const dayOfWeek = firstDayOfYear.day();
  const daysToAdd = dayOfWeek === 1 ? 0 : 8 - dayOfWeek;
  const firstMondayOfYear = firstDayOfYear.add(daysToAdd, "days");

  return firstMondayOfYear;
};

export const get_week_number = (date: any) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  const yearStart = new Date(d.getFullYear(), 0, 1);
  const weekNumber = Math.ceil(
    ((Number(d) - Number(yearStart)) / 86400000 + 1) / 7
  );
  return weekNumber;
};

export const get_day_number = (dayName: any) => {
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];
  const index = daysOfWeek.indexOf(dayName);

  if (index !== -1) {
    return index;
  } else {
    return -1;
  }
};

export const convert_utc_to_user_timezone = (utcTime: string) => {
  // UTC time fetched from the database
  let start_time = utcTime;

  // Create a moment object with the UTC time
  let utcMoment = moment.utc(`2024-01-01T${start_time}`);

  // Convert to the user's local time
  let localMoment = utcMoment.local();

  // Format the local time
  let formattedTime = localMoment.format("HH:mm");

  return formattedTime;
};

export const get_ip_address = async () => {
  const res = await axios.get("https://api.ipify.org/?format=json");
  return res.data?.ip;
};

export const get_days_between_dates = async (
  start_date: any,
  end_date: any
) => {
  if (start_date && end_date) {
    let start__date = new Date(start_date);
    let end__date = new Date(end_date);

    let diiference_in_time = end__date.getTime() - start__date.getTime();

    let difference_in_days = Math.round(
      diiference_in_time / (1000 * 3600 * 24)
    );

    return difference_in_days;
  }

  return 0;
};

export const get_age = async (date_of_birth: any) => {
  if (date_of_birth) {
    let now = new Date();
    let year_now = now.getFullYear();
    let month_now = now.getMonth();
    let date_now = now.getDate();

    let dob = new Date(date_of_birth);

    let year_dob = dob.getFullYear();
    let month_dob = dob.getMonth();
    let date_dob = dob.getDate();

    let age = {
      years: 0,
      months: 0,
      days: 0,
    };

    let age_string = "",
      year_string = "",
      month_string = "",
      day_string = "",
      month_age = 0,
      date_age = 0;

    let year_age = year_now - year_dob;

    if (month_now >= month_dob) {
      month_age = month_now - month_dob;
    } else {
      year_age--;
      month_age = 12 + month_now - month_dob;
    }

    if (date_now >= date_dob) {
      date_age = date_now - date_dob;
    } else {
      month_age--;
      date_age = 31 + date_now - date_dob;

      if (month_age < 0) {
        month_age = 11;
        year_age--;
      }
    }

    age = {
      years: year_age,
      months: month_age,
      days: date_age,
    };

    if (age.years > 1) {
      year_string = " years";
    } else {
      year_string = " year";
    }

    if (age.months > 1) {
      month_string = " months";
    } else {
      month_string = " month";
    }

    if (age.days > 1) {
      day_string = " days";
    } else {
      day_string = " day";
    }

    if (age.years > 0 && age.months > 0 && age.days > 0) {
      age_string =
        age.years +
        year_string +
        ", " +
        age.months +
        month_string +
        ", and " +
        age.days +
        day_string +
        " old.";
    } else if (age.years === 0 && age.months === 0 && age.days > 0) {
      age_string = "Only " + age.days + day_string + " old!";
    } else if (age.years > 0 && age.months === 0 && age.days === 0) {
      age_string = age.years + year_string + " old.";
    } else if (age.years > 0 && age.months > 0 && age.days === 0) {
      age_string =
        age.years + year_string + " and " + age.months + month_string + " old.";
    } else if (age.years === 0 && age.months > 0 && age.days > 0) {
      age_string =
        age.months + month_string + " and " + age.days + day_string + " old.";
    } else if (age.years > 0 && age.months === 0 && age.days > 0) {
      age_string =
        age.years + year_string + " and " + age.days + day_string + " old.";
    } else if (age.years === 0 && age.months > 0 && age.days === 0) {
      age_string = age.months + month_string + " old.";
    } else {
      age_string = "Oops! Could not calculate age!";
    }

    return age_string;
  }
};

export const validate_date = (array: any): any => {
  try {
    for (let i = 0; i < array.length; i++) {
      const { from_date, to_date } = array[i];

      if (new Date(from_date) > new Date(to_date)) {
        return {
          isValid: false,
          invalidIndex: i,
          message: `From Date is greater than To Date in Previous Work Experience at row no. ${
            i + 1
          }`,
        };
      }
    }

    return { isValid: true };
  } catch (e) {
    console.log(e);
  }
};
