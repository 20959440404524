import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import apiServices from "services/RequestHandler";

export interface ItemsState {
  // Items Groups //
  all_items_groups: any;
  all_items_groups_data: any;
  create_item_group: any;
  update_item_group: any;
  get_item_group_by_id: any;
  export_all_items: any;

  // Items Unit of Measurement //
  all_items_uom: any;
  all_items_uom_data: any;
  create_item_uom: any;
  update_item_uom: any;
  get_item_uom_by_id: any;

  // Items Unit of Measurement Category //
  all_items_uom_category: any;
  all_items_uom_category_data: any;
  create_item_uom_category: any;
  update_item_uom_category: any;
  get_item_uom_category_by_id: any;

  // Items Unit of Measurement Factors //
  all_items_uom_factor: any;
  all_items_uom_factor_data: any;
  create_item_uom_factor: any;
  update_item_uom_factor: any;
  get_item_uom_factor: any;

  // Inventory Pulse Category
  all_inventory_pulse_category: any;
  all_inventory_pulse_category_data: any;
  create_inventory_pulse_category: any;
  update_inventory_pulse_category: any;
  get_inventory_pulse_category: any;

  // Items Group Level //
  all_item_group_level: any;
  all_item_group_level_data: any;
  create_item_group_level: any;
  update_item_group_level: any;
  get_single_item_group_level: any;

  // Items //
  all_inventory_items: any;
  all_inventory_items_data: any;
  create_inventory_items: any;
  update_inventory_items: any;
  get_inventory_items: any;

  activity: boolean;
}

interface APIParams {
  end_point: string;
  body: any;
}

const initialState: ItemsState = {
  // Items Groups //
  all_items_groups: [],
  all_items_groups_data: [],
  create_item_group: {},
  update_item_group: {},
  get_item_group_by_id: {},
  export_all_items: [],

  // Items Unit of Measurement //
  all_items_uom: [],
  all_items_uom_data: [],
  create_item_uom: {},
  update_item_uom: {},
  get_item_uom_by_id: {},

  // Items Unit of Measurement Category //
  all_items_uom_category: {},
  all_items_uom_category_data: {},
  create_item_uom_category: {},
  update_item_uom_category: {},
  get_item_uom_category_by_id: {},

  // Items Unit of Measurement Factors //
  all_items_uom_factor: [],
  all_items_uom_factor_data: [],
  create_item_uom_factor: {},
  update_item_uom_factor: {},
  get_item_uom_factor: {},

  // Inventory Pulse Category
  all_inventory_pulse_category: [],
  all_inventory_pulse_category_data: [],
  create_inventory_pulse_category: {},
  update_inventory_pulse_category: {},
  get_inventory_pulse_category: {},

  // Item Group Level //
  all_item_group_level: [],
  all_item_group_level_data: [],
  create_item_group_level: {},
  update_item_group_level: {},
  get_single_item_group_level: {},

  // Items //
  all_inventory_items: [],
  all_inventory_items_data: [],
  create_inventory_items: {},
  update_inventory_items: {},
  get_inventory_items: {},

  activity: false,
};

export const allItemsGroups = createAsyncThunk(
  "/items-groups",
  async (data: any) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        data.permission_name
      );
      return {
        ...response,
      };
    } catch (err) {
      console.log(err);
    }
  }
);

export const all_items_uom_request = createAsyncThunk(
  "/inventory-items-uom",
  async (data: any) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        data.permission_name
      );
      // return response;
      return {
        ...response,
      };
    } catch (err) {
      console.log(err);
    }
  }
);

export const allUnitOfMeasurementFactor = createAsyncThunk(
  "/inventory-uom-factor",
  async (data: any) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        data.permission_name
      );
      // return response;
      return {
        ...response,
      };
    } catch (err) {
      console.log(err);
    }
  }
);

export const allPulseCategory = createAsyncThunk(
  "/inventory-pulse-category/",
  async (data: any) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        data.permission_name
      );
      return {
        ...response,
      };
    } catch (err) {
      console.log(err);
    }
  }
);

export const get_item_group = createAsyncThunk(
  `/inventory-items-group/:id`,
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "inventory-items-group"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const all_unit_of_measurement_category = createAsyncThunk(
  "/inventory-items-uom-category",
  async (data: any) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        data.permission_name
      );
      // return response;
      return {
        ...response,
      };
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_item_group = createAsyncThunk(
  "/inventory-items-group/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "inventory-items-group/add"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_item_group = createAsyncThunk(
  "/inventory-items-group/id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "inventory-items-group"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_item_unit_of_measurement = createAsyncThunk(
  "/inventory-items-uom/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "inventory-items-uom/add"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_item_unit_of_measurement = createAsyncThunk(
  "/inventory-items-uom/",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "inventory-items-uom/"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_item_unit_of_measurement_category = createAsyncThunk(
  "/inventory-items-uom-category/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "inventory-items-uom-category/add"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_item_unit_of_measurement_category = createAsyncThunk(
  "/inventory-items-uom-category/",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "inventory-items-uom-category/"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const get_item_unit_of_measurement_category = createAsyncThunk(
  "inventory-items-uom-category/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "inventory-items-uom-category"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_item_uom_factor = createAsyncThunk(
  "/inventory-uom-factor/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "inventory-uom-factor/add"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_item_uom_factor = createAsyncThunk(
  "/inventory-uom-factor/id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "inventory-uom-factor"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const get_item_unit_of_measurement_factor = createAsyncThunk(
  "inventory-uom-factor/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "inventory-uom-factor"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const get_item_unit_of_measurement = createAsyncThunk(
  `/inventory-items-uom/:id`,
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "inventory-items-uom"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

// Inventory Pulse Category //

export const create_pulse_category = createAsyncThunk(
  "/inventory-pulse-category/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "inventory-pulse-category/add"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_pulse_category = createAsyncThunk(
  "/inventory-pulse-category/id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "inventory-pulse-category"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const get_pulse_category = createAsyncThunk(
  `/inventory-pulse-category/:id`,
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "inventory-pulse-category"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

// Item Group Level //

export const allItemsGroupLevel = createAsyncThunk(
  "/inventory-item-group-level",
  async (data: any) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        data.permission_name
      );
      // return response;
      return {
        ...response,
      };
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_item_group_level = createAsyncThunk(
  "/inventory-item-group-level/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "inventory-item-group-level/add"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_item_group_level = createAsyncThunk(
  "/inventory-item-group-level/id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "inventory-item-group-level"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const get_item_group_level = createAsyncThunk(
  `/inventory-item-group-level/:id`,
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "inventory-item-group-level"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

// Items //

export const all_items_request = createAsyncThunk(
  "/inventory-items",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "inventory-items"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_inventory_item_request = createAsyncThunk(
  "/inventory-items/add",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "inventory-items/add"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_inventory_item_request = createAsyncThunk(
  "/inventory-items/id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "inventory-items"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const get_inventory_item_request = createAsyncThunk(
  `/inventory-items/:id`,
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        "inventory-items"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const export_items_request = createAsyncThunk(
  "/inventory-itemss",
  async (data: any) => {
    try {
      let response = await apiServices.getFromApi(
        data.end_point,
        data.permission_name
      );
      // return response;
      return {
        ...response,
      };
    } catch (err) {
      console.log(err);
    }
  }
);

export const items_group_slicer = createSlice({
  name: "items",
  initialState,
  reducers: {
    handle_clear_form: (state: ItemsState, action: any) => {
      state.get_item_group_by_id = {};
      state.create_item_group = {};
      state.update_item_group = {};

      state.get_single_item_group_level = {};
      state.create_item_group_level = {};
      state.update_item_group_level = {};

      state.get_inventory_items = {};
      state.create_inventory_items = {};
      state.update_inventory_items = {};
      state.export_all_items = {};

      state.get_item_uom_by_id = {};
      state.create_item_uom = {};
      state.update_item_uom = {};

      state.get_item_uom_category_by_id = {};
      state.create_item_uom_category = {};
      state.update_item_uom_category = {};

      state.get_item_uom_factor = {};
      state.create_item_uom_factor = {};
      state.update_item_uom_factor = {};

      state.get_inventory_pulse_category = {};
      state.create_inventory_pulse_category = {};
      state.update_inventory_pulse_category = {};

      state.activity = false;
    },
  },
  extraReducers: (builder) => {
    builder

      // Items Groups //
      .addCase(allItemsGroups.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(allItemsGroups.fulfilled, (state, { payload }) => {
        let all_items_groups = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data?.[index];

          all_items_groups.push([
            { name: item?._id, hidden: true },
            item?.item_group_name || "Not Available",
            item?.item_group_parent_name || "Not Available",
            {
              name: item?.item_group_parent === false ? "No" : "Yes",
              alignment: "center",
            },
            item?.description || "Not Available",
            {
              name: item?.created_at
                ? moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss")
                : "Not Available",
              alignment: "center",
            },
            { name: item?.added_by || "Admin", alignment: "left" },
          ]);
        }

        state.all_items_groups = all_items_groups;
        state.all_items_groups_data = payload?.data;
        state.activity = false;
      })

      .addCase(create_item_group.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_item_group.fulfilled, (state, { payload }) => {
        try {
          state.create_item_group = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_item_group.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_item_group.fulfilled, (state, { payload }) => {
        try {
          state.update_item_group = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(get_item_group.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(get_item_group.fulfilled, (state, { payload }) => {
        try {
          state.get_item_group_by_id = payload.data as any;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      // Items Unit of Measurement //
      .addCase(all_items_uom_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_items_uom_request.fulfilled, (state, { payload }) => {
        let all_items_uom = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data?.[index];

          all_items_uom.push([
            { name: item?._id, hidden: true },

            { name: item?.uom_name || "Not Available", alignment: "left" },
            {
              name: item?.category_name || "Not Available",
              alignment: "left",
            },
            {
              name: item?.uom_description || "Not Available",
              alignment: "left",
            },
            {
              name: item?.created_at
                ? moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss")
                : "Not Available",
              alignment: "center",
            },
            { name: item?.added_by || "Not Available", alignment: "left" },
          ]);
        }

        state.all_items_uom = all_items_uom;
        state.all_items_uom_data = payload?.data;

        state.activity = false;
      })

      .addCase(
        create_item_unit_of_measurement.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        create_item_unit_of_measurement.fulfilled,
        (state, { payload }) => {
          try {
            state.create_item_uom = payload;
            state.activity = false;
          } catch (error) {
            state.activity = false;
          }
        }
      )

      .addCase(
        update_item_unit_of_measurement.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        update_item_unit_of_measurement.fulfilled,
        (state, { payload }) => {
          try {
            state.update_item_uom = payload;
            state.activity = false;
          } catch (error) {
            state.activity = false;
          }
        }
      )

      .addCase(get_item_unit_of_measurement.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(get_item_unit_of_measurement.fulfilled, (state, { payload }) => {
        try {
          state.get_item_uom_by_id = payload.data as any;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      // Items Unit of Measurement Category //
      .addCase(
        all_unit_of_measurement_category.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        all_unit_of_measurement_category.fulfilled,
        (state, { payload }) => {
          let all_items_uom_category = [] as any;
          for (let index = 0; index < payload?.data?.length; index++) {
            const item = payload?.data?.[index];

            all_items_uom_category.push([
              { name: item?._id, hidden: true },
              {
                name: item?.uom_category_name,
                alignment: "left",
              },
              {
                name: item?.description || "Not Available",
                alignment: "left",
              },
              {
                name: item?.created_at
                  ? moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss")
                  : "Not Available",
                alignment: "center",
              },
              {
                name: item?.added_by || "Not Available",
                alignment: "left",
              },
            ]);
          }

          state.all_items_uom_category = all_items_uom_category;
          state.all_items_uom_category_data = payload?.data;

          state.activity = false;
        }
      )

      .addCase(
        create_item_unit_of_measurement_category.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        create_item_unit_of_measurement_category.fulfilled,
        (state, { payload }) => {
          try {
            state.create_item_uom_category = payload;
            state.activity = false;
          } catch (error) {
            state.activity = false;
          }
        }
      )

      .addCase(
        update_item_unit_of_measurement_category.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        update_item_unit_of_measurement_category.fulfilled,
        (state, { payload }) => {
          try {
            state.update_item_uom_category = payload;
            state.activity = false;
          } catch (error) {
            state.activity = false;
          }
        }
      )

      .addCase(
        get_item_unit_of_measurement_category.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        get_item_unit_of_measurement_category.fulfilled,
        (state, { payload }) => {
          try {
            state.get_item_uom_category_by_id = payload.data as any;
            state.activity = false;
          } catch (error) {
            console.log(error);
          }
        }
      )

      // Unit of Measurement Factors //
      .addCase(allUnitOfMeasurementFactor.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(allUnitOfMeasurementFactor.fulfilled, (state, { payload }) => {
        let all_items_uom_factor = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data?.[index];

          all_items_uom_factor.push([
            { name: item?._id, hidden: true },
            {
              name: item?.category_name || "Not Available",
              alignment: "left",
            },
            {
              name: item?.uom_from_name || "Not Available",
              alignment: "left",
            },
            {
              name: item?.uom_to_name || "Not Available",
              alignment: "left",
            },
            { name: item?.uom_value || "Not Available", alignment: "center" },
            {
              name: item?.created_at
                ? moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss")
                : "Not Available",
              alignment: "center",
            },
            { name: item?.added_by || "Not Available", alignment: "left" },
          ]);
        }

        state.all_items_uom_factor = all_items_uom_factor;
        state.all_items_uom_factor_data = payload?.data;

        state.activity = false;
      })

      .addCase(create_item_uom_factor.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_item_uom_factor.fulfilled, (state, { payload }) => {
        try {
          state.create_item_uom_factor = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_item_uom_factor.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_item_uom_factor.fulfilled, (state, { payload }) => {
        try {
          state.update_item_uom_factor = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(
        get_item_unit_of_measurement_factor.pending,
        (state, { payload }) => {
          state.activity = true;
        }
      )

      .addCase(
        get_item_unit_of_measurement_factor.fulfilled,
        (state, { payload }) => {
          try {
            state.get_item_uom_factor = payload.data as any;
            state.activity = false;
          } catch (error) {
            console.log(error);
          }
        }
      )

      // Inventory Pulse Category

      .addCase(allPulseCategory.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(allPulseCategory.fulfilled, (state, { payload }) => {
        let all_inventory_pulse_category = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data?.[index];

          all_inventory_pulse_category.push([
            { name: item?._id || "Not Available", hidden: true },
            {
              name: item?.pulse_cat_name || "Not Available",
              alignment: "left",
            },
            {
              name: item?.pulse_cat_description || "Not Available",
              alignment: "left",
            },
            {
              name: item?.created_at
                ? moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss")
                : "Not Available",
              alignment: "center",
            },

            { name: item?.added_by || "Not Available", alignment: "left" },
          ]);
        }

        state.all_inventory_pulse_category = all_inventory_pulse_category;
        state.all_inventory_pulse_category_data = payload?.data;

        state.activity = false;
      })

      .addCase(create_pulse_category.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_pulse_category.fulfilled, (state, { payload }) => {
        try {
          state.create_inventory_pulse_category = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_pulse_category.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_pulse_category.fulfilled, (state, { payload }) => {
        try {
          state.update_inventory_pulse_category = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(get_pulse_category.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(get_pulse_category.fulfilled, (state, { payload }) => {
        try {
          state.get_inventory_pulse_category = payload.data as any;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      // Item Group Level //

      .addCase(allItemsGroupLevel.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(allItemsGroupLevel.fulfilled, (state, { payload }) => {
        let all_item_group_level = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data?.[index];

          all_item_group_level.push([
            { name: item?._id, hidden: true },
            {
              name: item?.item_group_level_name || "Not Available",
              alignment: "left",
            },
            {
              name: item?.item_group_level_description || "Not Available",
              alignment: "left",
            },
            {
              name: `${item?.approval_level}` || "Not Available",
              alignment: "center",
            },
            {
              name: item?.created_at
                ? moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss")
                : "Not Available",
              alignment: "center",
            },
            { name: item?.added_by || "Not Available", alignment: "left" },
          ]);
        }

        state.all_item_group_level = all_item_group_level;
        state.all_item_group_level_data = payload?.data;

        state.activity = false;
      })

      .addCase(create_item_group_level.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_item_group_level.fulfilled, (state, { payload }) => {
        try {
          state.create_item_group_level = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_item_group_level.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_item_group_level.fulfilled, (state, { payload }) => {
        try {
          state.update_item_group_level = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(get_item_group_level.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(get_item_group_level.fulfilled, (state, { payload }) => {
        try {
          state.get_single_item_group_level = payload.data as any;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      // Items //

      .addCase(all_items_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_items_request.fulfilled, (state, { payload }) => {
        let all_inventory_items = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data?.[index];
          if (item?.is_active) {
            all_inventory_items.push([
              { name: item?._id, hidden: true },
              { name: item?.item_code || "Not Available", alignment: "left" },
              {
                name: item?.item_name || "Not Available",
                alignment: "left",
              },
              {
                name: item?.uom_name || "Not Available",
                alignment: "center",
                value: item?.default_unit,
              },
              {
                name: item?.pulse_cat_name || "Not Available",
                alignment: "left",
              },
              {
                value: item?.is_active,
                name: item?.is_active ? "Active" : "Inactive",
                is_bool: true,
                alignment: "center",
                color: item?.is_active ? "#6EC531" : "#bf0100",
              },
              {
                name: item?.item_group_name || "Not Available",
                alignment: "left",
              },
              {
                name: item?.item_group_level_name || "Not Available",
                alignment: "center",
              },
              {
                name: item?.topping_extract_code || "Not Available",
                alignment: "center",
              },
              {
                name: item?.description || "Not Available",
                alignment: "left",
              },
              {
                name: item?.created_at
                  ? moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss")
                  : "Not Available",
                alignment: "center",
              },
              { name: item?.added_by || "Admin", alignment: "left" },
              {
                name:
                  item?.created_at === item?.updated_at
                    ? "Not Available"
                    : item?.updated_at
                    ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                    : "Not Available",
                alignment: "center",
              },
              { name: item?.modified_by || "Admin", alignment: "left" },
            ]);
          }
        }

        state.all_inventory_items = all_inventory_items;
        state.all_inventory_items_data = payload;
        state.activity = false;
      })

      .addCase(create_inventory_item_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(
        create_inventory_item_request.fulfilled,
        (state, { payload }) => {
          try {
            state.create_inventory_items = payload;
            state.activity = false;
          } catch (error) {
            state.activity = false;
          }
        }
      )

      .addCase(update_inventory_item_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(
        update_inventory_item_request.fulfilled,
        (state, { payload }) => {
          try {
            state.update_inventory_items = payload;
            state.activity = false;
          } catch (error) {
            state.activity = false;
          }
        }
      )

      .addCase(get_inventory_item_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(get_inventory_item_request.fulfilled, (state, { payload }) => {
        try {
          state.get_inventory_items = payload.data as any;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(export_items_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(export_items_request.fulfilled, (state, { payload }) => {
        let export_all_items = [] as any;
        for (let index = 0; index < payload?.data?.length; index++) {
          const item = payload?.data?.[index];
          if (item?.is_active) {
            export_all_items.push([
              item?.item_code || "Not Available",
              item?.item_name || "Not Available",
              item?.uom_name || "Not Available",
              item?.pulse_cat_name || "Not Available",
              item?.is_active ? "Active" : "Inactive",
              item?.item_group_name || "Not Available",
              item?.item_group_level_name || "Not Available",
              item?.topping_extract_code || "Not Available",
              item?.description || "Not Available",
              moment(item?.created_at || "Not Available").format(
                "DD.MMM.YYYY HH:mm:ss"
              ),
              item?.added_by || "Admin",
              item?.created_at === item?.updated_at
                ? "Not Available"
                : moment(item?.updated_at || "Not Available").format(
                    "DD.MMM.YYYY HH:mm:ss"
                  ),
              item?.modified_by || "Admin",
            ]);
          }
        }

        state.export_all_items = export_all_items;
        state.activity = false;
      });
  },
});

export const { handle_clear_form } = items_group_slicer.actions;

export default items_group_slicer.reducer;
