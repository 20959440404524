import MainLayout from "layout/MainLayout";
import {
  UsersRegister,
  UserDetails,
  RolesRegister,
  RoleDetails,
  StoresRegister,
  StoreDetails,
  ErrorPage,
  NotFound,
  AllDesginations,
  AllDepartments,
  DesignationDetail,
  DepartmentDetail,
  AllEmployees,
  EmployeeDetail,
  UserProfile,
  EmployeeTimesheetDetail,
  EmployeeTimesheetRegister,
  AllShifts,
  ShiftDetails,
  AllHolidays,
  HolidayDetails,
  EmployeeRoster,
  RosterRegister,
  EmployeeTimeClock,
  RequestVacation,
  EmployeeMonthlyTimesheet,
  VacationsRegister,
  VacationDetails,
  LeavesRegister,
  PayrollRegister,
  VacationsReport,
} from "./PageAsync";
import PropTypes from "prop-types";
import { can_perform_action } from "utils/helper";

export function MainAppRoutes({ classes }: any) {
  const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: (
          <PrivateRoute
            WrappedComponent={AllEmployees}
            classes={classes}
            module_name="Employee"
          />
        ),
      },
      {
        path: "/settings/users",
        element: (
          <PrivateRoute
            WrappedComponent={UsersRegister}
            classes={classes}
            module_name="Users"
          />
        ),
      },
      {
        path: "/settings/users/details",
        element: (
          <PrivateRoute
            WrappedComponent={UserDetails}
            classes={classes}
            module_name="Users"
          />
        ),
      },
      {
        path: "/settings/users/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={UserDetails}
            classes={classes}
            module_name="Users"
          />
        ),
      },
      {
        path: "/settings/roles",
        element: (
          <PrivateRoute
            WrappedComponent={RolesRegister}
            classes={classes}
            module_name="Roles"
          />
        ),
      },
      {
        path: "/settings/roles/details",
        element: (
          <PrivateRoute
            WrappedComponent={RoleDetails}
            classes={classes}
            module_name="Roles"
          />
        ),
      },
      {
        path: "/settings/roles/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={RoleDetails}
            classes={classes}
            module_name="Roles"
          />
        ),
      },
      {
        path: "/settings/stores",
        element: (
          <PrivateRoute
            WrappedComponent={StoresRegister}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/settings/stores/details",
        element: (
          <PrivateRoute
            WrappedComponent={StoreDetails}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/settings/stores/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={StoreDetails}
            classes={classes}
            module_name="Stores"
          />
        ),
      },
      {
        path: "/human-resources/employee/",
        element: (
          <PrivateRoute
            WrappedComponent={AllEmployees}
            classes={classes}
            module_name="Employee"
          />
        ),
      },
      {
        path: "/human-resources/employee/details",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeDetail}
            classes={classes}
            module_name="Employee"
          />
        ),
      },
      {
        path: "/human-resources/employee/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeDetail}
            classes={classes}
            module_name="Employee"
          />
        ),
      },
      {
        path: "/human-resources/departments/",
        element: (
          <PrivateRoute
            WrappedComponent={AllDepartments}
            classes={classes}
            module_name="Departments"
          />
        ),
      },
      {
        path: "/human-resources/departments/details",
        element: (
          <PrivateRoute
            WrappedComponent={DepartmentDetail}
            classes={classes}
            module_name="Departments"
          />
        ),
      },
      {
        path: "/human-resources/departments/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={DepartmentDetail}
            classes={classes}
            module_name="Departments"
          />
        ),
      },
      {
        path: "/human-resources/designations/",
        element: (
          <PrivateRoute
            WrappedComponent={AllDesginations}
            classes={classes}
            module_name="Designations"
          />
        ),
      },
      {
        path: "/human-resources/designations/details",
        element: (
          <PrivateRoute
            WrappedComponent={DesignationDetail}
            classes={classes}
            module_name="Designations"
          />
        ),
      },
      {
        path: "/human-resources/designations/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={DesignationDetail}
            classes={classes}
            module_name="Designations"
          />
        ),
      },
      {
        path: "/human-resources/attendance",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeTimesheetRegister}
            classes={classes}
            module_name="Attendance"
          />
        ),
      },
      {
        path: "/human-resources/shifts",
        element: (
          <PrivateRoute
            WrappedComponent={AllShifts}
            classes={classes}
            module_name="Shifts"
          />
        ),
      },
      {
        path: "/human-resources/holidays",
        element: (
          <PrivateRoute
            WrappedComponent={AllHolidays}
            classes={classes}
            module_name="Holidays"
          />
        ),
      },
      {
        path: "/human-resources/shifts/details",
        element: (
          <PrivateRoute
            WrappedComponent={ShiftDetails}
            classes={classes}
            module_name="Shifts"
          />
        ),
      },
      {
        path: "/human-resources/shifts/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={ShiftDetails}
            classes={classes}
            module_name="Shifts"
          />
        ),
      },
      {
        path: "/human-resources/holidays/details",
        element: (
          <PrivateRoute
            WrappedComponent={HolidayDetails}
            classes={classes}
            module_name="Holidays"
          />
        ),
      },
      {
        path: "/human-resources/holidays/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={HolidayDetails}
            classes={classes}
            module_name="Holidays"
          />
        ),
      },
      {
        path: "/human-resources/weekly-schedule/details",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeRoster}
            classes={classes}
            module_name="Weekly Schedule"
          />
        ),
      },
      {
        path: "/human-resources/weekly-schedule/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeRoster}
            classes={classes}
            module_name="Weekly Schedule"
          />
        ),
      },
      {
        path: "/human-resources/weekly-schedule",
        element: (
          <PrivateRoute
            WrappedComponent={RosterRegister}
            classes={classes}
            module_name="Weekly Schedule"
          />
        ),
      },
      {
        path: "/human-resources/attendance/detail/:id",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeTimesheetDetail}
            classes={classes}
            module_name="Attendance"
          />
        ),
      },
      {
        path: "/human-resources/timesheet",
        element: (
          <PrivateRoute
            WrappedComponent={EmployeeMonthlyTimesheet}
            classes={classes}
            module_name="Timesheet"
          />
        ),
      },
      {
        path: "/human-resources/vacations",
        element: (
          <PrivateRoute
            WrappedComponent={VacationsRegister}
            classes={classes}
            module_name="Vacations"
          />
        ),
      },
      {
        path: "/human-resources/vacations/details",
        element: (
          <PrivateRoute
            WrappedComponent={VacationDetails}
            classes={classes}
            module_name="Vacations"
          />
        ),
      },
      {
        path: "/human-resources/vacations/details/:id",
        element: (
          <PrivateRoute
            WrappedComponent={VacationDetails}
            classes={classes}
            module_name="Vacations"
          />
        ),
      },
      {
        path: "/human-resources/request-leave",
        element: <RequestVacation classes={classes} />,
      },
      {
        path: "/human-resources/leaves/details/:id",
        element: <RequestVacation classes={classes} />,
      },
      {
        path: "/human-resources/leaves",
        element: (
          <PrivateRoute
            WrappedComponent={LeavesRegister}
            classes={classes}
            module_name="Leaves"
          />
        ),
      },
      {
        path: "/human-resources/leaves/:id",
        element: (
          <PrivateRoute
            WrappedComponent={RequestVacation}
            classes={classes}
            module_name="Leaves"
          />
        ),
      },
      {
        path: "/human-resources/payroll",
        element: (
          <PrivateRoute
            WrappedComponent={PayrollRegister}
            classes={classes}
            module_name="Payroll"
          />
        ),
      },
      {
        path: "/human-resources/vacations-report",
        element: <VacationsReport classes={classes} />,
      },
      {
        path: "/clock",
        element: <EmployeeTimeClock classes={classes} />,
      },
      {
        path: "/user-profile",
        element: <UserProfile classes={classes} />,
      },
      {
        path: "/login",
        element: <UserProfile classes={classes} />,
      },
      {
        path: "/forgot-password",
        element: <UserProfile classes={classes} />,
      },
      {
        path: "/*",
        element: <NotFound classes={classes} />,
      },
    ],
  };

  return MainRoutes;
}

const PrivateRoute = (props: any) => {
  const { WrappedComponent, classes, module_name } = props;
  if (can_perform_action(module_name)) {
    return <WrappedComponent classes={classes} module_name={module_name} />;
  } else {
    return <ErrorPage classes={classes} />;
  }
};

MainAppRoutes.propTypes = {
  classes: PropTypes.object,
};
