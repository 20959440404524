import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import _ from "lodash";
import moment from "moment";
import apiServices from "services/RequestHandler";

export interface MaterialRequestState {
  all_material_request: any;
  all_material_request_data: any;
  create_material_request_data: any;
  update_material_request_data: any;
  get_material_Request: any;
  activity: boolean;
}

interface APIParams {
  end_point: string;
  body?: any;
}

const initialState: MaterialRequestState = {
  all_material_request: [],
  all_material_request_data: [],
  create_material_request_data: {},
  update_material_request_data: {},
  get_material_Request: {},
  activity: true,
};

export const all_material_request = createAsyncThunk(
  "/request",
  async (data: any) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "request");
      return {
        ...response,
        employee_position: data.postions,
      };
    } catch (err) {
      console.log(err);
    }
  }
);

export const get_material_request = createAsyncThunk(
  "/request/:id",
  async (data: APIParams) => {
    try {
      let response = await apiServices.getFromApi(data.end_point, "request");
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const create_material_request = createAsyncThunk(
  "/request/create",
  async (data: APIParams) => {
    try {
      let response = await apiServices.postFromApi(
        data.end_point,
        data.body,
        "request"
      );
      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const update_material_request = createAsyncThunk(
  "/request/update",
  async (data: APIParams) => {
    try {
      let response = await apiServices.patchFromApi(
        data.end_point,
        data.body,
        "request"
      );

      return response;
    } catch (err) {
      console.log(err);
    }
  }
);

export const material_request_slicer = createSlice({
  name: "material_request",
  initialState,
  reducers: {
    handle_clear_form: (state: MaterialRequestState, action: any) => {
      state.get_material_Request = {};
      state.create_material_request_data = {};
      state.update_material_request_data = {};
      state.activity = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(all_material_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(all_material_request.fulfilled, (state, { payload }) => {
        try {
          let all_material_request = [] as any;

          let mater_request_data = payload?.data?.sort((a: any, b: any) =>
            new Date(a.created_at).getTime() > new Date(b.created_at).getTime()
              ? -1
              : 1
          );

          for (let index = 0; index < mater_request_data?.length; index++) {
            const item = mater_request_data[index];

            let emp_position_for_next_approval =
              payload?.employee_position?.all_employees_data?.filter(
                (x: any) => Number(x?._id) === Number(item?.next_action_id)
              )[0]?.designation_name;

            let emp_posotion_for_modified_by =
              payload?.employee_position?.all_employees_data?.filter(
                (k: any) =>
                  `${k?.firstname} ${k?.lastname}` === item?.modified_by
              )[0]?.designation_name;

            let emp_position_for_created_by =
              payload?.employee_position?.all_employees_data?.filter(
                (z: any) => Number(z?._id) === Number(item?.created_employee_id)
              )[0]?.designation_name;

            all_material_request.push([
              { name: item?._id || "Not Available", hidden: true },
              {
                name: item?._id ? "MR_" + item?._id : "Not Available",
                alignment: "left",
              },
              {
                value: item?.is_submitted,
                name: item?.is_rejected
                  ? "Declined"
                  : item?.is_submitted
                  ? "Approved"
                  : "Pending",
                is_bool: true,
                color: item?.is_submitted
                  ? "#6EC531"
                  : item?.is_rejected
                  ? "#bf0100"
                  : "yellow",
                alignment: "left",
              },
              {
                is_bool:
                  !item?.is_submitted && !item?.next_actioned
                    ? false
                    : item?.is_submitted
                    ? false
                    : false,
                color:
                  !item?.is_submitted && !item?.next_actioned
                    ? "red"
                    : item?.is_submitted
                    ? "#6EC531"
                    : " ",
                name: item?.is_submitted
                  ? "Not Required"
                  : !item?.is_submitted && !item?.next_actioned
                  ? "Not Required"
                  : `Waiting for approval of ${item?.next_actioned}`,
                alignment: "left",
                hover: item?.next_actioned
                  ? emp_position_for_next_approval
                  : "",
              },
              {
                name: item?.abc ?? "Material Issue",
                alignment: "center",
              },
              {
                name: item?.required_delivery_date
                  ? moment(item?.required_delivery_date).format("DD.MMM.YYYY")
                  : "Not Available",
                alignment: "center",
              },
              {
                name: _.startCase(item?.request_priority) || "Not Available",
                value: _.startCase(item?.request_priority),
                is_priority:
                  item?.request_priority?.toLowerCase() === "high"
                    ? "high"
                    : item?.request_priority?.toLowerCase() === "low"
                    ? "low"
                    : "normal",
                color:
                  item?.request_priority?.toLowerCase() === "low"
                    ? "#228b22"
                    : item?.request_priority?.toLowerCase() === "normal"
                    ? "#ffc300"
                    : "#bf0100",
                alignment: "left",
              },
              {
                name: item?.delivery_to ?? "Not Available",
                alignment: "left",
              },
              {
                name: item?.added_by ?? "Admin",
                hover: item?.added_by ? emp_position_for_created_by : "",
              },
              {
                name: moment(item?.created_at).format("DD.MMM.YYYY HH:mm:ss"),
                alignment: "center",
              },
              {
                name: item?.modified_by || "Not Available",
                hover: item?.modified_by ? emp_posotion_for_modified_by : "",
              },
              {
                name: item?.modified_by
                  ? moment(item?.updated_at).format("DD.MMM.YYYY HH:mm:ss")
                  : "Not Available",
                alignment: "center",
              },
            ]);
          }

          state.all_material_request_data = payload?.data;
          state.all_material_request = all_material_request;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(get_material_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(get_material_request.fulfilled, (state, { payload }) => {
        try {
          state.get_material_Request = payload?.data;
          state.activity = false;
        } catch (error) {
          console.log(error);
        }
      })

      .addCase(create_material_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(create_material_request.fulfilled, (state, { payload }) => {
        try {
          state.create_material_request_data = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      })

      .addCase(update_material_request.pending, (state, { payload }) => {
        state.activity = true;
      })

      .addCase(update_material_request.fulfilled, (state, { payload }) => {
        try {
          state.update_material_request_data = payload;
          state.activity = false;
        } catch (error) {
          state.activity = false;
        }
      });
  },
});

export const { handle_clear_form } = material_request_slicer.actions;

export default material_request_slicer.reducer;
